import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';

const MyTable = ({ columns, data, search, showTotal = false ,setstartdate ,setenddate ,startdate ,enddate ,fetch ,setLottoselect,lottoselect}) => {
  // console.log(data)
  const [currentRow, setCurrentRow] = useState(0);
  const [selectedSortOption, setSelectedSortOption] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [searchQuery3, setSearchQuery3] = useState([]);  

  const [SearchDateValue, setSearchDateValue] = useState('');
  const [SearchDatetoValue, setSearchDatetoValue] = useState('');

  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState(data);
  const itemsPerPage = 1; // Display one row at a time

  //ช่องค้นหาตารางรายละเอียดรายได้ 
  const lotto_type = [
    { key: "หวยไทย", value: "th_gov" },
    { key: "หวยลาว", value: "laos_gov" },
    { key: "ยี่กี่ 5 นาที", value: "Yeekee_5" },
    { key: "ยี่กี่ 10 นาที", value: "Yeekee_10" },
    { key: "ยี่กี่ 15 นาที", value: "Yeekee_15" },
  ];
  const status_type = [
    { key: "จ่ายแล้ว", value: "clear" },
    { key: "ยังไม่จ่าย", value: "not_clear" },
  ];
  const bet_type = [
    { value: 'first', key: 'รางวัลที่หนึ่ง' },
    { value: 'two_bot', key: 'สองตัวล่าง' },
    { value: 'run_top', key: 'วิ่งบน' },
    { value: 'two_top', key: 'สองตัวบน' },
    { value: 'three_front', key: 'สามตัวหน้า' },
    { value: 'run_bot', key: 'วิ่งล่าง' },
    { value: 'three_tod', key: 'สามตัวโต๊ด' },
    { value: 'three_top', key: 'สามตัวบน' }
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filteredTableData, // Use filteredTableData here
      initialState: {
        sortBy: selectedSortOption
          ? [{ id: selectedSortOption, desc: true }]
          : [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const RowCount = Math.ceil(rows.length / itemsPerPage);
  const displayRows = rows.slice(0, (currentRow + 1) * itemsPerPage);
  useEffect(() => {
    // Initialize the table with all data when the component mounts
    setFilteredTableData(data);
  }, [data]);

  useEffect(() => {
    if (currentRow < RowCount - 1) {
      const timer = setTimeout(() => {
        setCurrentRow(currentRow + 1);
      }, 40); // Delay in milliseconds between rendering each row

      return () => clearTimeout(timer);
    }
  }, [currentRow, RowCount]);


  const handleSearch = () => {
    // Filter the table data based on searchQuery1 and searchQuery2
    const filteredData = data.filter((item) => {
      const match1 =
        searchQuery1.length === 0 ||
        searchQuery1.some((value) => item.lotto_type.includes(value));

      const match2 =
        searchQuery2.length === 0 ||
        searchQuery2.some((value) => item.bet_type.includes(value));

      return match1 && match2;
    });

    // Update the filteredTableData state with the filtered data
    setFilteredTableData(filteredData);

    // Trigger the search effect
    setSearchTrigger(true);
  };
  const handleSearchMember = () => {
    // Filter the table data based on searchQuery1 and searchQuery2
    const filteredData = data.filter((item) => {
      const match1 =
        searchQuery1 === '' || item.Username.includes(searchQuery1);
      const match2 =
        searchQuery2 === '' || item.phone.includes(searchQuery2);
      const match3 =
        searchQuery3 === '' || item.bankaccoutnumber.includes(searchQuery3);
      return match1 && match2 && match3;
    });

    // Update the filteredTableData state with the filtered data
    setFilteredTableData(filteredData);

    // Trigger the search effect
    setSearchTrigger(true);
  };

  const handleSearchAgent = () => {
    // Filter the table data based on searchQuery1 and searchQuery2
    const filteredData = data.filter((item) => {
      const match1 =
        searchQuery1 === '' || item.username.includes(searchQuery1);
      const match2 =
        searchQuery2 === '' || item.upline_username.includes(searchQuery2);
      return match1 && match2;
    });

    // Update the filteredTableData state with the filtered data
    setFilteredTableData(filteredData);
    // Trigger the search effect
    setSearchTrigger(true);
  };

  const handleSearchPaidHistory1 = () => {
    // Make a copy of the original data
    let dataCopy = [...data];

    // Convert searchQuery2 to an integer
    const searchQuery2Int = parseInt(searchQuery2);

    // Filter the dataCopy based on the single search input
    let filteredData = dataCopy.filter((item) => {
      // console.log(item.id, searchQuery2Int);
      const match1 =
        searchQuery1 === '' || item.agent.includes(searchQuery1);
      const match2 =
        isNaN(searchQuery2Int) || item.id === searchQuery2Int;

      // Use logical operators (e.g., OR) to define the relationship between search fields
      // If either search field matches, it includes the item
      return match1 || match2;
    });

    // Sort the filteredData by the 'id' field if searchQuery2 is not empty
    if (searchQuery1 !== '') {
      filteredData = filteredData.filter((item) => item.agent === searchQuery1);
    }

    if (searchQuery2 !== '') {
      filteredData = filteredData.filter((item) => item.id === searchQuery2Int);
    }
    // If searchQuery3 is an array and not empty, filter the data based on its values
    if (Array.isArray(searchQuery3) && searchQuery3.length > 0) {
      filteredData = filteredData
        .filter((item) => searchQuery3.includes(item.status));
    }
    // If SearchDateValue and SearchDatetoValue are not empty and valid date strings, filter data within the date range
    if (SearchDateValue && SearchDatetoValue) {
      const startDate = new Date(SearchDateValue);
      const endDate = new Date(SearchDatetoValue);

      filteredData = filteredData.filter((item) => {
        const buyDate = new Date(item.billcutdate);
        return buyDate >= startDate && buyDate <= endDate;
      });
    }

    // Update the filteredTableData state with the filtered and sorted data
    setFilteredTableData(filteredData);

    // Trigger the search effect
    setSearchTrigger(true);
  };









  const handleSearchQuery1Change = (value) => {
    if (searchQuery1.includes(value)) {
      setSearchQuery1(searchQuery1.filter((item) => item !== value));
    } else {
      setSearchQuery1([...searchQuery1, value]);
    }
  };

  const handleSearchQuery2Change = (value) => {
    if (searchQuery2.includes(value)) {
      setSearchQuery2(searchQuery2.filter((item) => item !== value));
    } else {
      setSearchQuery2([...searchQuery2, value]);
    }
  };

  const handleSearchQuery3Change = (value) => {
    if (lottoselect.includes(value)) {
      setLottoselect(lottoselect.filter((item) => item !== value));
    } else {
      setLottoselect([...lottoselect, value]);
    }
  };



  const getTotalAmount = () => {
    const filteredData = searchQuery1 || searchQuery2 ? filteredTableData : data; // Use filteredData if a search is applied, otherwise use original data

    if (filteredData.length === 0) return { totalAmount: 0, totalCommission: 0 };

    let totalAmount = [];
    // Calculate the total amount for filtered data
    const totalSaleAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_sale), 0).toFixed(2)}</span>
    );
    const totalCommission = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.commission), 0).toFixed(2)}</span>
    );
    const totalTotal_profit = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_profit), 0).toFixed(2)}</span>
    );
    const totalTotal_lose = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_lose), 0).toFixed(2)}</span>
    );
    const totalAgent_take = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.agent_take), 0).toFixed(2)}</span>
    );
    const totalupline_take = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.upline_take), 0).toFixed(2)}</span>
    );

    totalAmount.push(totalSaleAmount, totalCommission, totalTotal_profit, totalTotal_lose, totalAgent_take, totalupline_take);

    return totalAmount;
  };
  const getTotalPaidhistory1 = () => {
    const filteredData = searchQuery1 || searchQuery2 ? filteredTableData : data; // Use filteredData if a search is applied, otherwise use original data

    if (filteredData.length === 0) return { totalAmount: 0, totalCommission: 0 };

    let totalAmount = [];
    // Calculate the total amount for filtered data
    const totalSaleAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_sale), 0).toFixed(2)}</span>
    );
    const totalLose = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_lose), 0).toFixed(2)}</span>
    );
    const totalCommission = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_commission), 0).toFixed(2)}</span>
    );
    const totalTotal_sale = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.total_sale - item.total_lose), 0).toFixed(2)}</span>
    );
    const totalAgent_take = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.agent_take), 0).toFixed(2)}</span>
    );
    const totalupline_take = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.upline_take), 0).toFixed(2)}</span>
    );

    totalAmount.push(totalSaleAmount, totalLose, totalCommission, totalTotal_sale, totalAgent_take, totalupline_take);

    return totalAmount;
  };
  const totalAmountDataPaidHistory1 = getTotalPaidhistory1()


  const totalAmountData = getTotalAmount();

  const getTotalAmountWinlose = () => {
    const filteredDatas = searchQuery1 || searchQuery2 || searchQuery3 || SearchDateValue || SearchDatetoValue ? filteredTableData : data; // Use filteredData if a search is applied, otherwise use original data
    const filteredData = filteredDatas
    if (filteredData.length === 0) return { totalAmount: 0, totalCommission: 0 };
    let totalAmount = [];
    // Calculate the total amount for filtered data
    const sumBetAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item['sum(bet)']), 0).toFixed(2)}</span>
    );
    const commissionAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.commission), 0).toFixed(2)}</span>
    );
    const win_loseAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.win_lose), 0).toFixed(2)}</span>
    );
    const agent_takeAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.agent_take), 0).toFixed(2)}</span>
    );
    const pay_outAmount = (
      <span className="p-2 font-bold text-purple-600">{filteredData.reduce((total, item) => total + parseInt(item.pay_out), 0).toFixed(2)}</span>
    );
    totalAmount.push(sumBetAmount,commissionAmount,win_loseAmount,agent_takeAmount,pay_outAmount);
    return totalAmount;
  };

  const totalAmountDataWinlose = getTotalAmountWinlose();

  return (
    <div className='p-2'>
      {search === 'IncomeData' && (
        <div className='p-2 bg-amber-50 border-[1px] border-slate-400 rounded-lg'>
          <div className="mb-4 p-2 flex items-center">
            <div className="flex flex-row items-center mr-4">
              <p className="font-bold mr-2">ตามประเภทหวย</p>
              {lotto_type.map((item) => (
                <label key={item.key} className="block mr-2 p-1">
                  <input
                    className="p-1"
                    type="checkbox"
                    name="field1"
                    value={item.value}
                    checked={searchQuery1.includes(item.value)} // Use Array.includes to handle multiple selections
                    onChange={(e) => handleSearchQuery1Change(e.target.value)}
                  />
                  {item.key}
                </label>
              ))}
            </div>
            <div className="flex flex-row items-center">
              <p className="font-bold mr-2">ประเภทเลข</p>
              {bet_type.map((item) => (
                <label key={item.key} className="block mr-2 p-1">
                  <input
                    type="checkbox"
                    name="field2"
                    value={item.value}
                    checked={searchQuery2.includes(item.value)} // Use Array.includes to handle multiple selections
                    onChange={(e) => handleSearchQuery2Change(e.target.value)}
                  />
                  {item.key}
                </label>
              ))}
            </div>
            <button onClick={handleSearch} className="py-1 px-2 bg-blue-500 text-white rounded">
              เรียกดู
            </button>
          </div>
        </div>
      )}
      {search === 'Winlose' && (
        <div className='p-2 bg-amber-50 border-[1px] border-slate-400 rounded-lg'>
          <div className="mb-4 p-2 flex items-center">
            <div className="flex flex-col items-center mr-4">
              {/* <div className="flex flex-col mr-4">
                <label className="font-bold mb-2">ค้นหาจาก Username</label>
                <input
                  type="text"
                  className="p-1 border border-gray-300 rounded"
                  value={searchQuery1}
                  onChange={(e) => setSearchQuery1(e.target.value)}
                />
              </div>
              <div className="flex flex-col mr-4">
                <label className="font-bold mb-2">ค้นหาจากเลข Order</label>
                <input
                  type="text"
                  className="p-1 border border-gray-300 rounded"
                  value={searchQuery2}
                  onChange={(e) => setSearchQuery2(e.target.value)}
                />
              </div> */}
            </div>
            <div className="flex flex-col md:flex flex-row items-center">
              <div className="flex flex-row mr-4">
                <label className="font-bold mb-2">ตามประเภทหวย</label>
                {lotto_type.map((item) => (
                  <label key={item.key} className="block mr-2 p-1">
                    <input
                      className="p-1"
                      type="checkbox"
                      name="field1"
                      value={item.value}
                      checked={lottoselect.includes(item.value)}
                      onChange={(e) => handleSearchQuery3Change(e.target.value)}
                    />
                    {item.key}
                  </label>
                ))}
              </div>
              <div className='p-2 border-[1px] border-slate-300 rounded-lg'>
                <div className="flex flex-col mr-4">
                  <label className="font-bold mb-2">จากวันที่</label>
                  <div className='flex flex-row space-x-2'>
                    <input
                      type="date" // Use type "date" for date input
                      className="p-1 border border-gray-300 rounded"
                      value={startdate} // Set the date input value from state
                      onChange={(e) => setstartdate(e.target.value)} // Update the date input value to state
                    />
                    <label className="font-bold mb-2">-</label>
                    <input
                      type="date" // Use type "date" for date input
                      className="p-1 border border-gray-300 rounded"
                      value={enddate} // Set the date input value from state
                      onChange={(e) => setenddate(e.target.value)} // Update the date input value to state
                    />
                  </div>
                </div>
              </div>
              <button onClick={() => fetch()} className="py-1 px-2 mt-2 ml-2 bg-blue-500 text-white rounded">
                เรียกดู
              </button>
            </div>
          </div>
        </div>
      )}
      {search === 'PaidHistory1' && (
        <div className='p-2 bg-amber-50 border-[1px] border-slate-400 rounded-lg'>
          <div className="mb-4 p-2 flex items-center">
            <div className="flex flex-col items-center mr-4">
              <div className="flex flex-col mr-4">
                <label className="font-bold mb-2">ค้นหาจาก Username</label>
                <input
                  type="text"
                  className="p-1 border border-gray-300 rounded"
                  value={searchQuery1}
                  onChange={(e) => setSearchQuery1(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex flex-row items-center">
              <div className="flex flex-row mr-4">
                <label className="font-bold mb-2 mr-2">ตามสถานะการจ่าย</label>
                {status_type.map((item) => (
                  <label key={item.key} className="block mr-2 p-1">
                    <input
                      className="p-1"
                      type="checkbox"
                      name="field1"
                      value={item.value}
                      checked={searchQuery3.includes(item.value)}
                      onChange={(e) => handleSearchQuery3Change(e.target.value)}
                    />
                    {item.key}
                  </label>
                ))}
              </div>
              <div className='p-2 border-[1px] border-slate-300 rounded-lg'>
                <div className="flex flex-col mr-4">
                  <label className="font-bold mb-2">จากวันที่</label>
                  <div className='flex flex-row space-x-2'>
                    <input
                      type="date" // Use type "date" for date input
                      className="p-1 border border-gray-300 rounded"
                      value={SearchDateValue} // Set the date input value from state
                      onChange={(e) => setSearchDateValue(e.target.value)} // Update the date input value to state
                    />
                    <label className="font-bold mb-2">-</label>
                    <input
                      type="date" // Use type "date" for date input
                      className="p-1 border border-gray-300 rounded"
                      value={SearchDatetoValue} // Set the date input value from state
                      onChange={(e) => setSearchDatetoValue(e.target.value)} // Update the date input value to state
                    />
                  </div>
                </div>
              </div>
              <button onClick={handleSearchPaidHistory1} className="py-1 px-2 mt-2 ml-2 bg-blue-500 text-white rounded">
                เรียกดู
              </button>
            </div>
          </div>
        </div>
      )}
      {search === 'MemberData' && (
        <div className='p-2 bg-amber-50 border-[1px] border-slate-400 rounded-lg'>
          <div className="mb-4 p-2 flex items-center">
            <div className="flex flex-row items-center mr-4">
              <p className="font-bold mr-2">ค้นหาจาก Username</p>
              <input
                type="text"
                className="p-1 border border-gray-300 rounded"
                value={searchQuery1}
                onChange={(e) => setSearchQuery1(e.target.value)}
              />
            </div>
            <div className="flex flex-row items-center">
              <p className="font-bold mr-2">ค้นหาจากเบอร์โทรศัพท์</p>
              <input
                type="text"
                className="p-1 border border-gray-300 rounded"
                value={searchQuery2}
                onChange={(e) => setSearchQuery2(e.target.value)}
              />
            </div>
            <div className="flex flex-row items-center">
              <p className="font-bold mr-2">ค้นหาจากเลขบัญชี</p>
              <input
                type="text"
                className="p-1 border border-gray-300 rounded"
                value={searchQuery3}
                onChange={(e) => setSearchQuery3(e.target.value)}
              />
            </div>
            <button onClick={handleSearchMember} className="py-1 px-2 bg-blue-500 text-white rounded">
              เรียกดู
            </button>
          </div>
        </div>
      )}
      {search === 'AgentData' && (
        <div className='p-2 bg-amber-50 border-[1px] border-slate-400 rounded-lg'>
          <div className="mb-4 p-2 flex items-center">
            <div className="flex flex-row items-center mr-4">
              <p className="font-bold mr-2">ค้นหาชื่อ Agent</p>
              <input
                type="text"
                className="p-1 border border-gray-300 rounded"
                value={searchQuery1}
                onChange={(e) => setSearchQuery1(e.target.value)}
              />
            </div>
            <div className="flex flex-row items-center">
              <p className="font-bold mr-2">ค้นหาชื่อ Upline</p>
              <input
                type="text"
                className="p-1 border border-gray-300 rounded"
                value={searchQuery2}
                onChange={(e) => setSearchQuery2(e.target.value)}
              />
            </div>
            <button onClick={handleSearchAgent} className="py-1 px-2 bg-blue-500 text-white rounded">
              เรียกดู
            </button>
          </div>
        </div>
      )}
      <div className="rounded overflow-hidden mt-1">
        <Table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-1 px-2 text-sm ${column.widthClass}`}
                    align={column.align}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {displayRows.map((row, index) => {
              prepareRow(row);
              const rowClassName = index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200';
              return (
                <tr
                  {...row.getRowProps()}
                  className={`transition-colors ${rowClassName} hover:bg-gray-300`}
                >
                  {row.cells.map((cell, columnIndex) => (
                    <td
                      {...cell.getCellProps()}
                      className={`p-2 ${cell.column.widthClass}`}
                      align={cell.column.align}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
            {showTotal === 'Dashboard' && (
              <tr>
                <td colSpan={columns.length - 6} align="right">
                  <span className="p-2 font-bold text-purple-600">รวม </span>
                </td>
                {Object.values(totalAmountData).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            )}
            {showTotal === 'Winlose' && (
              <tr>
                <td colSpan={columns.length - 6} align="right">
                  <span className="p-2 font-bold text-purple-600">รวม </span>
                </td>
                {Object.values(totalAmountDataWinlose).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            )}
            {showTotal === 'Paidhistory1' && (
              <tr>
                <td colSpan={columns.length - 7} align="right">
                  <span className="p-2 font-bold text-purple-600">รวม </span>
                </td>
                {Object.values(totalAmountDataPaidHistory1).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            )}
            {showTotal === 'Ordertable' && (
              <tr>
                <td colSpan={columns.length - 2} align="right">
                  <span className="p-2 font-bold text-purple-600">รวม </span>
                </td>
                {Object.values(totalAmountDataWinlose).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            )}
          </tbody>

        </Table>
      </div>
    </div>
  );
};

export default MyTable;
