import React, { useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Member.css'
import Navbar from './Navbar'
import SubAccouttable from './SubAccouttable'
import AddBoxIcon from '@mui/icons-material/AddBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './header'

function Subaccount() {

  const [open, setOpen] = React.useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [vusername, setvUsername] = useState(false);
  const [vpassword, setvPassword] = useState(false);

  const [status, setStatus] = useState(1);
  const [permission,setPermission] = useState([1,1,1,1,1,1,1,1,1,1,1,1])

  const user = localStorage.getItem("user")

  const handleClose = () => {
    setOpen(false);
    setUsername('')
    setPassword('')
    setStatus(1)
    setPermission([1,1,1,1,1,1,1,1,1,1,1,1])
    setvUsername(false)
    setvPassword(false)
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    // setOpen(false)
    e.preventDefault();

    if (!username || !password || !user) {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน !', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (!username) {
        setvUsername(true)
      } else (
        setvUsername(false)
      )
  
      if (!password) {
        setvPassword(true)
      } else (
        setvPassword(false)
      )
  
      return;
    }

    const json = {
      Username: username,
      Password: password,
      position:'sub_account',
      agent: user,
      status: status,
      permission: permission,
    };


    fetch(`${process.env.REACT_APP_API_KEY}/subadregister`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result)
        if (result === 'User already exists') {
          toast.error('มีสมาชิกชื่อนี้ในระบบแล้ว', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setUsername('');
          setPassword('');
          setOpen(false)
          toast.success('เพิ่มสมาชิกใหม่เรียบร้อย!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          .then(window.location.reload(false))
        }
      })
  };

  const pos = localStorage.getItem('position')

  const handlepermission = (index) =>{
    let temppermission = [...permission]
    if(temppermission[index] === 0)
    {
      temppermission[index] = 1
    } else {
      temppermission[index] = 0
    }

    setPermission(temppermission)
  }


  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos}/>
        <div className="inner flex-1">
          <div className='content'>
            <Header title='Sub Account' />
            <div className='p-1 mb-1 bg-purple-800 h-[2em] w-[7%] text-center text-white rounded border-solid hover:bg-sky-800 '>
              <GroupAddIcon className='ml-1 text-[5px] md:text-[8px]' />
              <span className='ml-1 text-[5px] md:text-[8px]' onClick={handleClickOpen}>เพิ่มบัญชีร่วม</span>
            </div>
            <SubAccouttable />
            {open &&
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div className="App">
                    <form onSubmit={handleSubmit}>

                      <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
                        {"เพิ่มบัญชีผู้ใช้ร่วม"}
                      </DialogTitle>
                      <DialogContent>
                        <div className="grid grid-cols-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                          <div>
                            <label htmlFor="username" className="block mb-1 font-bold">
                              Username
                            </label>
                            <input
                              type="text"
                              id="username"
                              value={username}
                              placeholder="Username"
                              onChange={(e) => setUsername(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vusername ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="password" className="block mb-1 font-bold">
                              Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              placeholder="Password"
                              onChange={(e) => setPassword(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vpassword ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>

                          <div>
                            <label className="block mb-1 font-bold">
                              สถานะ
                            </label>
                            <select                               
                            onChange={(e) => setStatus(e.target.value)} name="status" id="status">
                              <option value={1}>ทำงาน</option>
                              <option value={0}>หยุดทำงาน</option>
                            </select>
                          </div>
                        </div>

                        <div className="grid grid-rows p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="mb-1 font-bold">
                              สิทธิรายการ
                            </label>

                            <div className="grid grid-rows-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              บัญชี
                            </label>

                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[0] === 1} value={permission[0]} onChange={(e) => handlepermission(0)}/> 
                              เพิ่มสมาชิก 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[1] === 1} value={permission[1]} onChange={(e) => handlepermission(1)}/> 
                              เพิ่มเอเจน 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[2] === 1} value={permission[2]} onChange={(e) => handlepermission(2)}/> 
                              จัดการเครดิตเอเจน 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[3] === 1} value={permission[3]} onChange={(e) => handlepermission(3)}/> 
                              จัดดารเครดิตสมาชิก 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[4] === 1} value={permission[4]} onChange={(e) => handlepermission(4)}/> 
                              จัดการเอเจน 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[5] === 1} value={permission[5]} onChange={(e) => handlepermission(5)}/> 
                              จัดการสมาชิก 
                            </label>
                            </div>

                            <div className="grid grid-rows-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              ตั้งค่าจำกัด
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[6] === 1} value={permission[6]} onChange={(e) => handlepermission(6)}/> 
                              จัดการสถานะหวย 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[7] === 1} value={permission[7]} onChange={(e) => handlepermission(7)}/> 
                              จัดการซื้อสูงสุด 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[8] === 1} value={permission[8]} onChange={(e) => handlepermission(8)}/> 
                              จัดการสู้สูงสุด 
                            </label>
                            </div>

                            <div className="grid grid-rows-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              รายงาน
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[9] === 1} value={permission[9]} onChange={(e) => handlepermission(9)}/> 
                              แพ้ชนะรวม 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[10] === 1} value={permission[10]} onChange={(e) => handlepermission(10)}/> 
                              ผลหวย 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[11] === 1} value={permission[11]} onChange={(e) => handlepermission(11)}/> 
                              ยอดคงค้าง 
                            </label>
                            </div>

                        </div>
                      </DialogContent>
                      <DialogActions>
                        <button type="submit" className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                          <AddBoxIcon /> เพิ่มบัญชีผู้ใช้ร่วม
                        </button>
                        <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          ยกเลิก
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </Dialog>
              </div>
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export default Subaccount