import React, { useEffect, useState } from "react";
import TableContainer from '@mui/material/TableContainer';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MyTable from './Table'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordIcon from '@mui/icons-material/Password';
import moment from "moment";


export default function CustomizedTables() {

  const [member, setMember] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [userid, setUserid] = useState([]);
  const [username, setUsername] = useState();

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState('');
  const [bank, setBank] = useState('');
  const [banknumber, setBanknumber] = useState('');
  const [reload, setReload] = useState(0);

  const pos = localStorage.getItem('position')
  const user = localStorage.getItem('user')


  const MemberGet = async () => {
    const json = {
      position: pos,
      user: user
    }
    await fetch(`${process.env.REACT_APP_API_KEY}/member`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
      .then(res => res.json())
      .then((result) => { setMember(result) })
  }

  const subuserid = localStorage.getItem('subuserid')
  const [permission,setPermission] = useState({'member_credit_update' : 1})
  const permissionGet = () => {
    const jsonid = {
      id: subuserid,
    };

    fetch(`${process.env.REACT_APP_API_KEY}/getpermissionid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonid),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (pos === 'sub_account') 
          {
            setPermission(result[0])
          }
        }
      )

  };


  useEffect(() => {
    MemberGet()
    permissionGet()
  }, [reload])


  const handleDeletemember = (user) => {


    if(pos === 'sub_account')
      {
        if(permission.edit_member === 1)
          {
            Swal.fire({
              title: 'ต้องการลบสมาชิก?',
              text: `กดยืนยันเพื่อลบสมาชิก ${user} แบบถาวร`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#3085d6',
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ยกเลิก',
            }).then((result) => {
              if (result.isConfirmed) {
                const jsonid = {
                  username: user,
                };
                fetch(`${process.env.REACT_APP_API_KEY}/deletemember`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(jsonid),
                })
                  .then((res) => res.json())
                  .then(() => {
                    toast.success('User deleted successfully!', {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setReload(reload + 1);
                  })
                  .catch((error) => {
                    toast.error('An error occurred while deleting the user.', {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
              }
            });
        
          }
          else
          {
            alert('No permission')
          }
      } else
      {
        Swal.fire({
          title: 'ต้องการลบสมาชิก?',
          text: `กดยืนยันเพื่อลบสมาชิก ${user} แบบถาวร`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
        }).then((result) => {
          if (result.isConfirmed) {
            const jsonid = {
              username: user,
            };
            fetch(`${process.env.REACT_APP_API_KEY}/deletemember`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(jsonid),
            })
              .then((res) => res.json())
              .then(() => {
                toast.success('User deleted successfully!', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setReload(reload + 1);
              })
              .catch((error) => {
                toast.error('An error occurred while deleting the user.', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }
        });
    
          }



  };

  const handleClickOpen = (user) => {
    const jsonid = {
      user: user
    }
    if(pos === 'sub_account')
      {
        if(permission.edit_member === 1)
          {
            setOpen(true);
            fetch(`${process.env.REACT_APP_API_KEY}/profile`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(jsonid),
            })
              .then(res => res.json())
              .then(
                (result) => {
                  setUserid(result)
                  setFname(result[0].f_name)
                  setLname(result[0].l_name)
                  setPhone(result[0].phone)
                  setBank(result[0].bank)
                  setBanknumber(result[0].bankaccoutnumber)
                }
              )
        
          }
          else
          {
            alert('No permission')
          }
      } else
      {
        setOpen(true);
            fetch(`${process.env.REACT_APP_API_KEY}/profile`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(jsonid),
            })
              .then(res => res.json())
              .then(
                (result) => {
                  setUserid(result)
                  setFname(result[0].f_name)
                  setLname(result[0].l_name)
                  setPhone(result[0].phone)
                  setBank(result[0].bank)
                  setBanknumber(result[0].bankaccoutnumber)
                }
              )
      }
  
  };

  const handleUpdate = (username) => {
    setOpen(false)
    Swal.fire({
      title: 'ต้องการแก้ไขรายละเอียดสมาชิก?',
      text: `กดยืนยันเพื่อบันทึกข้อมูลสมาชิก : ${username}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonid = {
          username: username,
          fname: fname,
          lname: lname,
          phone: phone,
          bank: bank,
          bankacc: banknumber,
        };
        fetch(`${process.env.REACT_APP_API_KEY}/updatemember`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((res) => res.json())
          .then(() => {
            toast.success('User updated successfully!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setReload(reload + 1);
            handleClose();
          })
          .catch((error) => {
            toast.error('An error occurred while updating the user.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setUserid('')
    setFname('')
    setLname('')
    setPhone('')
    setBank('')
    setBanknumber('')

  };

  const handlecreditup = async (memberuser, rowid, action) => {
      if
    (permission.member_credit_update === 1)
    {
        let value = await document.getElementById(rowid).value;
    if (action === 'minus') {
      var inputValue = value * -1
    } else {
      var inputValue = value
    }

    // Check if the input value is empty
    if (!inputValue) {
      toast.error('Please enter a credit value!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let update = await parseInt(inputValue);
    let useradmin = localStorage.getItem('user');

    Swal.fire({
      title: `แก้ไขเครดิตให้กับสมาชิก?`,
      text: `กดยืนยันเพื่่อแก้ไขเครดิตจำนวน ${update} ให้กับสมาชิก : ${memberuser}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonid = {
          adminusername: useradmin,
          updateamout: update,
          username: memberuser,
        };

        fetch(`${process.env.REACT_APP_API_KEY}/updatemembercredit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result === 'insufficient_Credit') {
              Swal.fire('Error!', 'Insufficient credit!', 'error');
            } else {
              toast.success(`แก้ไขเครดิตจำนวน ${update} ให้กับสมาชิก : ${memberuser} สำเร็จ`, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setReload(reload + 1);
            }
          })
          .catch((error) => {
            toast.error('An error occurred while updating the credit.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    });
    } else 
    {
      alert('No permission')
    }
  };


  // #region password edit

  const [openeditpassword, setOpeneditpassword] = React.useState(false);
  const [password, setPassword] = useState();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickOpenpassword = (username) => {
    if(pos === 'sub_account')
      {
        if(permission.edit_member === 1)
          {
            setUsername(username)
            setOpeneditpassword(true)
          }
          else
          {
            alert('No permission')
          }
      } else
      {
        setUsername(username)
        setOpeneditpassword(true)
          }

  };

  const handleClosepassword = () => {
    setOpeneditpassword(false);
    setUsername()
  };

  const handleChangepassword = (event) => {
    setPassword(event.target.value)
  };

  const handleUpdatepassword = (password) => {
    const jsonid = {
      Username: username,
      Password: password,
    };

    Swal.fire({
      title: 'เปลี่ยนรหัสให้กับสมาชิก?',
      text: 'กดยืนยันเพื่อแก้ไขรหัสให้กับสมาชิก?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action, proceed with the fetch
        fetch(`${process.env.REACT_APP_API_KEY}/memberpasswordchange`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((response) => {
            if (response.ok) {
              // Password update was successful, show a success notification
              toast.success('เปลี่ยนรหัสให้กับสมาชิก สำเร็จ', {
                position: 'top-right',
                autoClose: 3000, // You can adjust the duration
              });
            } else {
              // Password update failed, show an error notification
              toast.error('Password update failed', {
                position: 'top-right',
                autoClose: 3000, // You can adjust the duration
              });
            }
          })
          .catch((error) => {
            console.error('Error updating password:', error);
            // Show an error notification for any fetch error
            toast.error('An error occurred while updating the password', {
              position: 'top-right',
              autoClose: 3000, // You can adjust the duration
            });
          });
      }
    });

    handleClosepassword(); // Close the password dialog regardless of the user's choice
  };

  // #endregion

  const memberColumns = [
    {
      Header: 'Username',
      accessor: 'Username',
      align: 'center',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
      widthClass: 'w-1/6', // Adjust the width class as needed
    },
    {
      Header: 'ชื่อ นามสกุล',
      accessor: (row) => `${row.f_name} ${row.l_name}`,
      align: 'right',
      widthClass: 'w-1/6', // Adjust the width class as needed
    },
    {
      Header: 'เบอร์โทร',
      accessor: 'phone',
      align: 'right',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/6',
    },
    {
      Header: 'Bank/AC',
      accessor: 'bankaccoutnumber',
      align: 'right',
      Cell: ({ value }) => <span className="text-xs">{value}</span>,
      widthClass: 'w-1/6',
    },
    {
      Header: 'Bank',
      accessor: 'bank',
      align: 'right',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/6',
    },
    {
      Header: 'เครดิต',
      accessor: 'credit',
      align: 'right',
      widthClass: 'w-1/4',
      Cell: ({ row,value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-sky-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <>
            <span className={`font-bold text-[15px] ${textColorClass}`}>
              {floatValue.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
            </span>
            <button onClick={() => gettransaction(row.original.Username)} lassName="text-slate-600 no-underline hover:text-slate-800">
              <SearchIcon />
            </button>
          </>

        );
      },
    },
    {
      Header: 'เพิ่มลบเครดิต',
      align: 'center',
      widthClass: 'w-1/4',
      Cell: ({ row }) => (
        <div className="flex flex-row px-2 h-[50%]">
          <input
            type="number"
            min={0}
            step={100}
            className="border border-gray-400 rounded-l-lg p-1 text-gray-700 leading-5 text-xs focus:outline-none focus:border-blue-500"
            id={row.id}
          />
          <div
            onClick={() => handlecreditup(row.original.Username, row.id, "plus")}
            className="bg-green-500 p-1 w-full text-center text-white justify-items-center items-center border-solid hover:bg-green-800 cursor-pointer"
          >
            <span className="text-xl font-bold md:text-[12px]">
              +
            </span>
          </div>
          <div
              onClick={() => handlecreditup(row.original.Username, row.id, "minus")}
              className="bg-red-500 p-1 w-full text-center rounded-r-lg text-white border-solid hover:bg-red-800 cursor-pointer"
            >
              <span className="text-xl font-bold md:text-[12px]">
                -
              </span>
            </div>
        </div>
      ),
    },
    {
      Header: 'จัดการ',
      align: 'center',
      Cell: ({ row }) => (
        <div className="flex flex-row space-x-1">
          <button
            onClick={() => handleClickOpen(row.original.Username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <SettingsIcon className="text-xs md:text-base" />{' '}
          </button>
          <button onClick={() => handleClickOpenpassword(row.original.Username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs">
            <PasswordIcon />
          </button>
          <button
            onClick={() => handleDeletemember(row.original.Username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <DeleteForeverIcon className="text-xs md:text-base" />
          </button>
        </div>
      ),
    },
  ];
  const [transactiom,setTransaction] = useState([]);
  const [opentransaction, setOpentransaction] = React.useState(false);

  const handleClosetransaction = () => {
    setOpentransaction(false)
  }
  const gettransaction = (agent) => {
    const positionget = {
      user: agent
    }
    fetch(`${process.env.REACT_APP_API_KEY}/gettransaction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(positionget),
      })
        .then(res => res.json())
        .then((result) => {
          setTransaction(result)
          setOpentransaction(true)
        })
  
  }
  const transactioncolumns = [
    {
      Header: 'ส่ง',
      accessor: 'Sender',
      widthClass: 'w-1/6',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'รับ',
      accessor: 'Reciever',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        let bgcolor = 'bg-slate-700';
        if (value === 'agent') {
          bgcolor = "bg-sky-500"
        }
        if (value === 'senior') {
          bgcolor = "bg-green-600"
        }
        if (value === 'master') {
          bgcolor = "bg-yellow-500"
        }
        if (value === 'company') {
          bgcolor = "bg-purple-600"
        }
        return (
          <div className={`p-1 w-auto drop-shadow-xl  rounded-lg text-center ${bgcolor}`}>
            <span
              className={`font-bold text-[8px] md:text-[15px] text-white`}>{value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'จำนวน',
      accessor: 'Amout',
      align: 'center',
      Cell: ({ value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-green-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <span className={`font-bold ${textColorClass}`}>
            {floatValue.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
          </span>
        );
      },
    },
{
      Header: 'วันที่',
      accessor: 'date_time',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value, row }) => {
        let textColorClass = 'text-slate-400'; // Default to black
        let bgColorClass = 'bg-amber-200';
        
        return (
          <div className="flex space-x-2 flex-row">
            <div
              className={`font-bold text-[8px] md:text-[15px] ${bgColorClass} ${textColorClass} rounded-lg border-1 border-black px-2 pt-1`} // Add rounded-md for a rounded border
            >
              {moment(value).format('DD/MM/YYYY HH:MM')}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <MyTable columns={memberColumns} data={member} search={"MemberData"} />
      </TableContainer>
      {open && userid.length > 0 &&
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              แก้ไขข้อมูล {userid && userid[0].Username}
            </DialogTitle>
            <DialogContent>
              <form className="bg-amber-100 border-[1px] border-slate-200 rounded-lg shadow-md p-6 grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2" htmlFor="firstname">
                    Firstname
                  </label>
                  <input
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    type="text"
                    id="firstname"
                    onChange={e => setFname(e.target.value)}
                    value={fname}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2" htmlFor="lastname">
                    Lastname
                  </label>
                  <input
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    type="text"
                    id="lastname"
                    onChange={e => setLname(e.target.value)}
                    value={lname}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2" htmlFor="phone">
                    Phone
                  </label>
                  <input
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    type="text"
                    id="phone"
                    onChange={e => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2" htmlFor="bank">
                    Bank
                  </label>
                  <input
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    type="text"
                    id="bank"
                    onChange={e => setBank(e.target.value)}
                    value={bank}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2" htmlFor="banknumber">
                    Bank Number
                  </label>
                  <input
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    type="text"
                    id="banknumber"
                    onChange={e => setBanknumber(e.target.value)}
                    value={banknumber}
                  />
                </div>
                <div className="col-span-2 flex justify-end">

                </div>
              </form>
            </DialogContent>

            <DialogActions>
              <button onClick={() => handleUpdate(userid[0].Username)} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                <SaveAltIcon /> บันทึก
              </button>
              <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}

      {openeditpassword &&
        <div>
          <Dialog
            open={openeditpassword}
            onClose={handleClosepassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              แก้ไขรหัสผ่าน สำหรับ Username : {username}
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col p-2 space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <span>กรอกรหัสผ่านใหม่</span>

                  <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      onChange={(e) => handleChangepassword(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <button onClick={() => handleUpdatepassword(password)} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                <SaveAltIcon /> บันทึก
              </button>
              <button onClick={handleClosepassword} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}

        {opentransaction &&
        <div>
          <Dialog
            open={opentransaction}
            onClose={handleClosetransaction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              <div className="p-1 flex justify-items-center items-center flex-row space-x-2">
                <span className="font-bold">ประวัติเครดิต</span>
                <div className="p-1 border-1 bg-yellow-400 rounded-lg drop-shadow-xl">
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <MyTable columns={transactioncolumns} data={transactiom} />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button onClick={handleClosetransaction} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}

    </div>
  );
}
