import React from 'react';
import mySvg from '../../image/meesok.png';
import { useState, useRef } from 'react';
import './Navbar.css';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

function Navbar() {
  const [text, setText] = useState('This is a running text animation.');
  const textRef = useRef(null);

  const onAnimationIteration = () => {};

  const user = localStorage.getItem('user');
  const pos = localStorage.getItem('position')
  const subuser = localStorage.getItem('subuser')


  const handleLogout = () => {
    Swal.fire({
      title: 'คุณต้องการออกจากระบบ ?',
      showCancelButton: true,
      confirmButtonText: 'ใช่ , ออกจากระบบ',
      cancelButtonText: 'ไม่'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.reload();
        // Perform any additional logout actions here
      }
    });
  };

  return (
    <div className='flex flex-col'>
      <div className='nav w-[1080px] md:w-full'>
        <div className='nav-logo z-40 w-[10%] h-[10%]'>
            <img
              src={mySvg}
              alt=''
              className='md:w-[80px] ml-[10px]'
            />
        </div>
        <div className='nav-inner'>
          <div className='nav-top'>
            <div className='flex flex-row mt-3'>
              <AccountBoxIcon className='text-zinc-400 mr-1' />
              <p className='text-white text-[10px] font-bold underline-offset-auto mr-4 hover:text-purple-200 md:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200 sm:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200'>
                MESOK LOTERRY - Welcome {pos === 'sub_account' ? subuser : user}
              </p>
            </div>
            <div className='nav-top-right'>
              <div className='px-2 py-1'>
                <Link to='/'>
                  <a className='text-white text-[10px] font-bold underline-offset-auto mr-4 hover:text-purple-200 md:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200 sm:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200'>
                    Home
                  </a>
                </Link>
                {/* <Link to='/agent'>
                  <a className='text-white text-[10px] font-bold underline-offset-auto mr-4 hover:text-purple-200 md:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200 sm:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200'>
                    Transfer
                  </a>
                </Link> */}
                {/* <Link to='/'>
                  <a className='text-white text-[10px] font-bold underline-offset-auto mr-4 hover:text-purple-200 md:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200 sm:text-white text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200'>
                    Password
                  </a>
                </Link> */}
                <Link to='/'>
                  <a onClick={handleLogout} className='text-red-500 text-[10px] font-bold underline-offset-auto mr-4 hover:text-purple-200 md:text-red-500 text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200 sm:text-red-500 text-sm font-bold underline-offset-auto mr-4 hover:text-purple-200'>
                    logout
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div class="nav-bot w-full mt-5">
  <div class="nav-bot-left w-full">
    <div class="notice-head md:z-30 z-30">
      <h5 class="text-[8px] md:text-[13px] font-semibold text-white ml-1 break-words py-2 md:py-0">NOTICE</h5>
    </div>
    <div class="w-full md:w-full h-5 bg-sky-200 rounded-r-lg z-20 md:mt-0 mt-2">
      <h5 class="text-[8px] md:text-[12px] font-semibold text-stone-700 ml-1 break-words running-text py-2 md:py-0">
        ประกาศจากระบบ : ขณะนี้อยู่ในช่วงกำลังพัฒนาระบบ  ประกาศ 07/04/2023
      </h5>
    </div>
  </div>
</div>

        </div>
      </div>
      <div className='w-[1080px] md:w-full h-1 bg-amber-400 border-solid'></div>
    </div>
  );
}

export default Navbar;
