import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Member.css'
import Navbar from './Navbar'
import Agenttable from './Agenttable.jsx'
import Header from './header'
import { useLocation } from 'react-router-dom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

function Agent() {

  const [level, Setlevel] = useState("Loading");
  const location = useLocation();
  const pos = localStorage.getItem('position')

  const searchParams = new URLSearchParams(location.search);
  const levelurl = searchParams.get('level');

  useEffect(() => {
    Setlevel(levelurl)
  }, [location]);

  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos}/>
        <div className="inner">
          <div className='content'>
            <Header title='Agent' />
            <a href={`/agentadd`} className="text-white no-underline">
            <div className='p-1 mb-1 bg-purple-800 h-[2em] w-[10%] text-center text-white rounded border-solid hover:bg-sky-800'>
              <GroupAddIcon className='ml-1 text-[8px] md:text-[16px]' />
              <span className='ml-1 text-[8px] md:text-[16px]' >
                {level === 'master' ? 'เพิ่ม Master' : level === 'senior' ? 'เพิ่ม Senior' : 'เพิ่ม Agent'}
              </span>
            </div>
          </a>
            <Agenttable poslevel={level} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Agent