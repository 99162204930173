import React, { useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import Header from './header'
import Swal from 'sweetalert2';
import 'moment/locale/th';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';




function Limitnumber() {
  const [currentComponent, setCurrentComponent] = useState(1);
  const pos = localStorage.getItem('position');
  const user = localStorage.getItem('user')
  const [position, setPosition] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isThaiTableVisible, setThaiTableVisible] = useState(true);
  const location = useNavigate()
  // #region all state and function for slider
  const [Thaicommission, setThaicommission] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Thaimaxfight, setThaimaxfight] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoicommission, setHanoicommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Hanoimaxfight, setHanoimaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoscommission, setLaoscommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosmaxfight, setLaosmaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeecommission, setYeekeecommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeemaxfight, setYeekeemaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipcommission, setYeekeevipcommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipmaxfight, setYeekeevipmaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipcommission, setYeekeevvipcommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipmaxfight, setYeekeevvipmaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Thaipayrate, setThaipayrate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipayrate, setHanoipayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospayrate, setLaospayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepayrate, setYeekeepayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippayrate, setYeekeevippayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippayrate, setYeekeevvippayrate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaipayrateupdate, setThaipayrateupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipayrateupdate, setHanoipayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospayrateupdate, setLaospayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepayrateupdate, setYeekeepayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippayrateupdate, setYeekeevippayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippayrateupdate, setYeekeevvippayrateupdate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thailimitbet, setThailimitbet] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoilimitbet, setHanoilimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoslimitbet, setLaoslimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeelimitbet, setYeekeelimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeviplimitbet, setYeekeeviplimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevviplimitbet, setYeekeevviplimitbet] = useState([0, 0, 0, 0, 0, 0]);

  const [Thailimitbetupdate, setThailimitbetupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoilimitbetupdate, setHanoilimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoslimitbetupdate, setLaoslimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeelimitbetupdate, setYeekeelimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaiminbet, setThaiminbet] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoiminbet, setHanoiminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosminbet, setLaosminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeminbet, setYeekeeminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipminbet, setYeekeevipminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipminbet, setYeekeevvipminbet] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaiminbetupdate, setThaiminbetupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoiminbetupdate, setHanoiminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosminbetupdate, setLaosminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeminbetupdate, setYeekeeminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipminbetupdate, setYeekeevipminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipminbetupdate, setYeekeevvipminbetupdate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaipermatch, setThaipermatch] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipermatch, setHanoipermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospermatch, setLaospermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepermatch, setYeekeepermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippermatch, setYeekeevippermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippermatch, setYeekeevvippermatch] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaipermatchupdate, setThaipermatchupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipermatchupdate, setHanoipermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospermatchupdate, setLaospermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepermatchupdate, setYeekeepermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippermatchupdate, setYeekeevippermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippermatchupdate, setYeekeevvippermatchupdate] = useState([0, 0, 0, 0, 0, 0]);


  const checkrewardandcommission = (reward, commission, maxreward, type) => {
    if (reward + ((commission * maxreward) / 100) <= maxreward) {
      // console.log("type:",type,reward ,"+",((commission*maxreward)/100),"percent", reward + ((commission*maxreward)/100))
      return true
    } else {
      Swal.fire({
        title: 'ตรวจสอบข้อมูลให้ถูกต้อง',
        text: `รางวัลรวมกับค่าคอมมิชชั่นต้องน้อยกว่า 100 | หวย ${type}`,
        icon: 'error',
      })
      return false
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the required fields is empty
    if (!username) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill Username!',
      });
      return;
    }
    else if (!password) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill Password!',
      });
      return;
    }
    else if (!position) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill Position!',
      });
      return;
    }
    else if (!user) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something wrong please refresh and try again!',
      });
      return;
    }
    
  

    // Show confirmation dialog
    const confirmResult = await Swal.fire({
      icon: 'warning',
      title: 'ต้องการเพิ่ม Agent',
      text: `เพิ่ม Agent "${username}" ระดับ "${position}" ใช่หรือไม่ ?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ปิด',
    });

    if (confirmResult.isConfirmed) {
      // Submit the form
      if (
        checkrewardandcommission(Thaipayrateupdate[0], Thaicommission[0], 1000000, 'หวยรัฐบาลไทย รางวัลที่หนึ่ง') &&
        checkrewardandcommission(Thaipayrateupdate[1], Thaicommission[1], 10000, 'หวยรัฐบาลไทย สี่ตัวบน') &&
        checkrewardandcommission(Thaipayrateupdate[2], Thaicommission[2], 10000, 'หวยรัฐบาลไทย สี่ตัวโต๊ด') &&
        checkrewardandcommission(Thaipayrateupdate[3], Thaicommission[3], 1000, 'หวยรัฐบาลไทย สามตัวบน') &&
        checkrewardandcommission(Thaipayrateupdate[4], Thaicommission[4], 500, 'หวยรัฐบาลไทย สามาตัวหน้า') &&
        checkrewardandcommission(Thaipayrateupdate[5], Thaicommission[5], 500, 'หวยรัฐบาลไทย สามตัวหลัง') &&
        checkrewardandcommission(Thaipayrateupdate[6], Thaicommission[6], 166, 'หวยรัฐบาลไทย สามตัวโต๊ด') &&
        checkrewardandcommission(Thaipayrateupdate[7], Thaicommission[7], 100, 'หวยรัฐบาลไทย สองตัวบน') &&
        checkrewardandcommission(Thaipayrateupdate[8], Thaicommission[8], 100, 'หวยรัฐบาลไทย สองตัวลา่ง') &&
        checkrewardandcommission(Thaipayrateupdate[9], Thaicommission[9], 10, 'หวยรัฐบาลไทย วิ่งบน') &&
        checkrewardandcommission(Thaipayrateupdate[10], Thaicommission[10], 10, 'หวยรัฐบาลไทย วิ่งล่าง') &&

        checkrewardandcommission(Hanoipayrateupdate[0], Hanoicommission[0], 1000, 'หวยฮานอย สามตัวบน') &&
        checkrewardandcommission(Hanoipayrateupdate[1], Hanoicommission[1], 166, 'หวยฮานอย สามตัวโต๊ด') &&
        checkrewardandcommission(Hanoipayrateupdate[2], Hanoicommission[2], 100, 'หวยฮานอย สองตัวบน') &&
        checkrewardandcommission(Hanoipayrateupdate[3], Hanoicommission[3], 100, 'หวยฮานอย สองตัวลา่ง') &&
        checkrewardandcommission(Hanoipayrateupdate[4], Hanoicommission[4], 10, 'หวยฮานอย วิ่งบน') &&
        checkrewardandcommission(Hanoipayrateupdate[5], Hanoicommission[5], 10, 'หวยฮานอย วิ่งล่าง') &&

        checkrewardandcommission(Laospayrateupdate[0], Laoscommission[0], 1000, 'หวยลาว สามตัวบน') &&
        checkrewardandcommission(Laospayrateupdate[1], Laoscommission[1], 166, 'หวยลาว สามตัวโต๊ด') &&
        checkrewardandcommission(Laospayrateupdate[2], Laoscommission[2], 100, 'หวยลาว สองตัวบน') &&
        checkrewardandcommission(Laospayrateupdate[3], Laoscommission[3], 100, 'หวยลาว สองตัวลา่ง') &&
        checkrewardandcommission(Laospayrateupdate[4], Laoscommission[4], 10, 'หวยลาว วิ่งบน') &&
        checkrewardandcommission(Laospayrateupdate[5], Laoscommission[5], 10, 'หวยลาว วิ่งล่าง') &&

        checkrewardandcommission(Yeekeepayrateupdate[0], Yeekeecommission[0], 1000, 'ยี่กี่ สามตัวบน') &&
        checkrewardandcommission(Yeekeepayrateupdate[1], Yeekeecommission[1], 166, 'ยี่กี่ สามตัวโต๊ด') &&
        checkrewardandcommission(Yeekeepayrateupdate[2], Yeekeecommission[2], 100, 'ยี่กี่ สองตัวบน') &&
        checkrewardandcommission(Yeekeepayrateupdate[3], Yeekeecommission[3], 100, 'ยี่กี่ สองตัวลา่ง') &&
        checkrewardandcommission(Yeekeepayrateupdate[4], Yeekeecommission[4], 10, 'ยี่กี่ วิ่งบน') &&
        checkrewardandcommission(Yeekeepayrateupdate[5], Yeekeecommission[5], 10, 'ยี่กี่ วิ่งล่าง') &&

        checkrewardandcommission(Yeekeevippayrateupdate[0], Yeekeevipcommission[0], 1000, 'ยี่กี่ vip สามตัวบน') &&
        checkrewardandcommission(Yeekeevippayrateupdate[1], Yeekeevipcommission[1], 166, 'ยี่กี่ vip สามตัวโต๊ด') &&
        checkrewardandcommission(Yeekeevippayrateupdate[2], Yeekeevipcommission[2], 100, 'Yยี่กี่ vip สองตัวบน') &&
        checkrewardandcommission(Yeekeevippayrateupdate[3], Yeekeevipcommission[3], 100, 'ยี่กี่ vip สองตัวลา่ง') &&
        checkrewardandcommission(Yeekeevippayrateupdate[4], Yeekeevipcommission[4], 10, 'ยี่กี่ vip วิ่งบน') &&
        checkrewardandcommission(Yeekeevippayrateupdate[5], Yeekeevipcommission[5], 10, 'ยี่กี่ vip วิ่งล่าง') &&

        checkrewardandcommission(Yeekeevvippayrateupdate[0], Yeekeevvipcommission[0], 1000, 'ยี่กี่vvip สามตัวบน') &&
        checkrewardandcommission(Yeekeevvippayrateupdate[1], Yeekeevvipcommission[1], 166, 'ยี่กี่vvip สามตัวโต๊ด') &&
        checkrewardandcommission(Yeekeevvippayrateupdate[2], Yeekeevvipcommission[2], 100, 'ยี่กี่vvip สองตัวบน') &&
        checkrewardandcommission(Yeekeevvippayrateupdate[3], Yeekeevvipcommission[3], 100, 'ยี่กี่vvip สองตัวลา่ง') &&
        checkrewardandcommission(Yeekeevvippayrateupdate[4], Yeekeevvipcommission[4], 10, 'ยี่กี่vvip วิ่งบน') &&
        checkrewardandcommission(Yeekeevvippayrateupdate[5], Yeekeevvipcommission[5], 10, 'ยี่กี่vvip วิ่งล่าง')

      ) 
      {
        const json = {
          Username: username,
          Password: password,
          credit: 0,
          position: position,
          UplineUsername: user,

          th_commission: Thaicommission,
          th_maxpercent: Thaimaxfight,
          hanoi_commission: Hanoicommission,
          hanoi_maxpercent: Hanoimaxfight,
          laos_commission: Laoscommission,
          laos_maxpercent: Laosmaxfight,
          yeekee5_commission: Yeekeecommission,
          yeekee5_maxpercent: Yeekeemaxfight,
          yeekee10_commission: Yeekeevipcommission,
          yeekee10_maxpercent: Yeekeevipmaxfight,
          yeekee15_commission: Yeekeevvipcommission,
          yeekee15_maxpercent: Yeekeevvipmaxfight,

          th_payrate: Thaipayrateupdate,
          hanoi_payrate: Hanoipayrateupdate,
          laos_payrate: Laospayrateupdate,
          yeekee5_payrate: Yeekeepayrateupdate,
          yeekee10_payrate: Yeekeevippayrateupdate,
          yeekee15_payrate: Yeekeevvippayrateupdate,

          th_maxbet: Thailimitbetupdate,
          hanoi_maxbet: Hanoilimitbetupdate,
          laos_maxbet: Laoslimitbetupdate,
          yeekee5_maxbet: Yeekeelimitbetupdate,
          yeekee10_maxbet: Yeekeeviplimitbetupdate,
          yeekee15_maxbet: Yeekeevviplimitbetupdate,

          th_minbet: Thaiminbetupdate,
          hanoi_minbet: Hanoiminbetupdate,
          laos_minbet: Laosminbetupdate,
          yeekee5_minbet: Yeekeeminbetupdate,
          yeekee10_minbet: Yeekeevipminbetupdate,
          yeekee15_minbet: Yeekeevvipminbetupdate,

          th_maxbetpermatch: Thaipermatchupdate,
          hanoi_maxbetpermatch: Hanoipermatchupdate,
          laos_maxbetpermatch: Laospermatchupdate,
          yeekee5_maxbetpermatch: Yeekeepermatchupdate,
          yeekee10_maxbetpermatch: Yeekeevippermatchupdate,
          yeekee15_maxbetpermatch: Yeekeevvippermatchupdate,

        };
        fetch(`${process.env.REACT_APP_API_KEY}/adregister`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(json),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result === 'User already exists') {
              toast.error('User already exists.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.success(`เพิ่มสมาชิก ${username} ระดับ ${position} เรียบร้อย`, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })

              setTimeout(() => {
                location(`/agent?level=${position}`);
                setUsername('');
                setPassword('');
                setPosition('');
                document.documentElement.lang = 'th';
              }, 1000); // Match the autoClose duration of the toast
              
            }
          })

      } else {

      }
    }
  };



  const handleNext = () => {
    // Logic for going to the next component
    if (currentComponent === 1) setCurrentComponent(2);
    else if (currentComponent === 2) setCurrentComponent(3);
    else if (currentComponent === 3) setCurrentComponent(4);
    // Add more logic for handling additional components
    else if (currentComponent === 4) setCurrentComponent(5);
  };

  const handlePrevious = () => {
    // Logic for going to the previous component
    if (currentComponent === 5) setCurrentComponent(4);
    else if (currentComponent === 4) setCurrentComponent(3);
    else if (currentComponent === 3) setCurrentComponent(2);
    // Add more logic for handling additional components
    else if (currentComponent === 2) setCurrentComponent(1);
  };

  const handleChange = (event, array, setfunction) => {
    let maxvalue = event.target.value
    if (maxvalue > 100) {
      maxvalue = 100
    }
    if (event.target.name === 'all') {
      setfunction([
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue)
      ])
    }
    else {
      const updatevalue = Array.from(array)
      updatevalue[Number(event.target.name)] = Number(maxvalue)
      setfunction(updatevalue)
    }
  };

  const handleChangepayrate = (event, payrate, setfunction) => {
    const updatevalue = Array.from(payrate)
    // console.log("test", updatevalue)
    updatevalue[Number(event.target.name)] = Number(event.target.value)
    setfunction(updatevalue)
  };

  const handleBack = () => {
    // Go back one page in the browser's history
    window.history.back();
  };

  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar />
        <div className="inner">
          <div className="content">
            <Header title={`Agent => Add new agent`} />
            <div className='flex flex-row space-x-2 mb-1'>
                {currentComponent < 4 && <button onClick={handleNext} className="w-[100%] h-[2rem] bg-purple-600 mr-1 hover:bg-purple-700 text-white text-[1rem] font-bold py-1 px-2 mr-1 rounded">
                  next
                </button>}
                {currentComponent !== 1 && <button onClick={handlePrevious} className="w-[100%] h-[2rem] bg-purple-600 mr-1 hover:bg-purple-700 text-white text-[1rem] font-bold py-1 px-2 mr-1 rounded">
                  prevous
                </button>}
                <button onClick={handleBack} className="w-[100%] h-[2rem] bg-slate-400 hover:bg-slate-500 text-white text-[1rem] font-bold p py-1 px-2 rounded">
                  ยกเลิก
                </button>
              </div>
            <form onSubmit={handleSubmit}>
              <div className='flex flex-row space-x-2 mb-1'>
                <button type="submit" className="w-[100%] h-[2rem] bg-green-600 mr-1 hover:bg-green-700 text-white text-[1rem] font-bold py-1 px-2 mr-1 rounded">
                  บันทึกข้อมูล
                </button>
              </div>
              <div className='md:w-auto bg-amber-100 p-3 h-auto flex flex-row border-[1px] p-1 border-slate-300 rounded-lg'>
                <div className='flex flex-row space-x-5'>
                  <div className='flex flex-col'>
                    <span className='text-[15px] font-bold'>username</span>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-50 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    />
                  </div>
                  <div className='flex flex-col'>
                    <span className='text-[15px] font-bold'>password</span>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-50 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    />
                  </div>
                  <div className='flex flex-col'>
                    <span className='text-[15px] font-bold'>Position</span>
                    <select id="employee"
                      className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-50 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                      name="employee" onChange={e => setPosition(e.target.value)}>
                      <option value=""></option>
                      {pos === 'owner' && <option value="company">Company</option>}
                      {pos === 'owner' || pos === 'company' ? <option value="senior">Senior</option> : ''}
                      {pos === 'owner' || pos === 'company' || pos === 'senior' ? <option value="master">Master</option> : ''}
                      {pos === 'owner' || pos === 'company' || pos === 'senior' || pos === 'master' ? <option value="agent">Agent</option> : ''}
                    </select>
                  </div>
                  <div className='flex flex-col'>
                    <span className='text-[15px] font-bold'>Upline user</span>
                    <input
                      type="text"
                      value={user}
                      disabled
                      className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-100 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                    />
                  </div>

                </div>
              </div>
              {currentComponent === 1 &&
                <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                  <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                    <div>
                      <span className='text-white'>ค่าคอมมิชชั่น และ รางวัล หวยไทย</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr>
                              <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-sky-100'>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[0]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[1]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[2]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[3]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[4]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[5]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[6]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={6} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[7]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={7} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[8]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={8} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[9]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={9} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaipayrate[10]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={10} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                    value={Thaipayrateupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[0]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[1]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[2]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[3]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[4]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[5]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[6]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={6} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[7]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={7} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[8]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={8} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[9]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={9} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaicommission[10]}
                                  </span>
                                  <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={10} onChange={(e) => handleChange(e, Thaicommission, setThaicommission)}
                                    value={Thaicommission[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                    <div>
                      <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล หวยฮานอย</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-sky-100'>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoipayrate[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                    value={Hanoipayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoipayrate[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                    value={Hanoipayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoipayrate[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                    value={Hanoipayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoipayrate[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                    value={Hanoipayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoipayrate[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                    value={Hanoipayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoipayrate[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                    value={Hanoipayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoicommission[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Hanoicommission, setHanoicommission)}
                                    value={Hanoicommission[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoicommission[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Hanoicommission, setHanoicommission)}
                                    value={Hanoicommission[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoicommission[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Hanoicommission, setHanoicommission)}
                                    value={Hanoicommission[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoicommission[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Hanoicommission, setHanoicommission)}
                                    value={Hanoicommission[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoicommission[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Hanoicommission, setHanoicommission)}
                                    value={Hanoicommission[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoicommission[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Hanoicommission, setHanoicommission)}
                                    value={Hanoicommission[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                    <div>
                      <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล หวยลาว</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-sky-100'>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laospayrate[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                    value={Laospayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laospayrate[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                    value={Laospayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laospayrate[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                    value={Laospayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laospayrate[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                    value={Laospayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laospayrate[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                    value={Laospayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laospayrate[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                    value={Laospayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laoscommission[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Laoscommission, setLaoscommission)}
                                    value={Laoscommission[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laoscommission[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Laoscommission, setLaoscommission)}
                                    value={Laoscommission[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laoscommission[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Laoscommission, setLaoscommission)}
                                    value={Laoscommission[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laoscommission[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Laoscommission, setLaoscommission)}
                                    value={Laoscommission[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laoscommission[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Laoscommission, setLaoscommission)}
                                    value={Laoscommission[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laoscommission[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Laoscommission, setLaoscommission)}
                                    value={Laoscommission[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                    <div>
                      <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล จับยี่กี</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-sky-100'>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeepayrate[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                    value={Yeekeepayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeepayrate[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                    value={Yeekeepayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeepayrate[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                    value={Yeekeepayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeepayrate[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                    value={Yeekeepayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeepayrate[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                    value={Yeekeepayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeepayrate[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                    value={Yeekeepayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeecommission[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Yeekeecommission, setYeekeecommission)}
                                    value={Yeekeecommission[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeecommission[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Yeekeecommission, setYeekeecommission)}
                                    value={Yeekeecommission[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeecommission[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Yeekeecommission, setYeekeecommission)}
                                    value={Yeekeecommission[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeecommission[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Yeekeecommission, setYeekeecommission)}
                                    value={Yeekeecommission[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeecommission[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Yeekeecommission, setYeekeecommission)}
                                    value={Yeekeecommission[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeecommission[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Yeekeecommission, setYeekeecommission)}
                                    value={Yeekeecommission[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                    <div>
                      <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล จับยี่กีVIP</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-sky-100'>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevippayrate[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                    value={Yeekeevippayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevippayrate[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                    value={Yeekeevippayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevippayrate[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                    value={Yeekeevippayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevippayrate[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                    value={Yeekeevippayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevippayrate[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                    value={Yeekeevippayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevippayrate[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                    value={Yeekeevippayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipcommission[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Yeekeevipcommission, setYeekeevipcommission)}
                                    value={Yeekeevipcommission[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipcommission[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Yeekeevipcommission, setYeekeevipcommission)}
                                    value={Yeekeevipcommission[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipcommission[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Yeekeevipcommission, setYeekeevipcommission)}
                                    value={Yeekeevipcommission[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipcommission[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Yeekeevipcommission, setYeekeevipcommission)}
                                    value={Yeekeevipcommission[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipcommission[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Yeekeevipcommission, setYeekeevipcommission)}
                                    value={Yeekeevipcommission[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipcommission[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Yeekeevipcommission, setYeekeevipcommission)}
                                    value={Yeekeevipcommission[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-800 hover:bg-orange-90'>
                    <div>
                      <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล จับยี่กี VVIP</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-sky-100'>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvippayrate[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                    value={Yeekeevvippayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvippayrate[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                    value={Yeekeevvippayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvippayrate[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                    value={Yeekeevvippayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvippayrate[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                    value={Yeekeevvippayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvippayrate[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                    value={Yeekeevvippayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvippayrate[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                    value={Yeekeevvippayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipcommission[0]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Yeekeevvipcommission, setYeekeevvipcommission)}
                                    value={Yeekeevvipcommission[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipcommission[1]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Yeekeevvipcommission, setYeekeevvipcommission)}
                                    value={Yeekeevvipcommission[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipcommission[2]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Yeekeevvipcommission, setYeekeevvipcommission)}
                                    value={Yeekeevvipcommission[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipcommission[3]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Yeekeevvipcommission, setYeekeevvipcommission)}
                                    value={Yeekeevvipcommission[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipcommission[4]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Yeekeevvipcommission, setYeekeevvipcommission)}
                                    value={Yeekeevvipcommission[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipcommission[5]}
                                  </span>
                                  <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Yeekeevvipcommission, setYeekeevvipcommission)}
                                    value={Yeekeevvipcommission[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>}
              {currentComponent === 2 &&
              <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
              <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                <div>
                  <span className='text-white font-bold'>Bet Limit หวยไทย</span></div>
                {isThaiTableVisible && (
                  <div className='rounded overflow-hidden mt-1'>
                    <table className="w-full" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr>
                          <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {/* maxbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[6]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={6} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[7]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={7} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[8]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={8} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[9]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={9} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thailimitbet[10]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={10} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                value={Thailimitbetupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* minbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[6]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={6} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[7]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={7} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[8]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={8} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[9]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={9} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaiminbet[10]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={10} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                value={Thaiminbetupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* maxpermatch */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[6]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={6} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[7]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={7} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[8]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={8} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[9]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={9} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Thaipermatch[10]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={10} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                value={Thaipermatchupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                <div>
                  <span className='text-white font-bold'>Bet Limit หวยฮานอย</span></div>
                {isThaiTableVisible && (
                  <div className='rounded overflow-hidden mt-1'>
                    <table className="w-full" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr className=''>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {/* maxbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoilimitbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                value={Hanoilimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoilimitbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                value={Hanoilimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoilimitbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                value={Hanoilimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoilimitbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                value={Hanoilimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoilimitbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                value={Hanoilimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoilimitbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                value={Hanoilimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* minbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoiminbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                value={Hanoiminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoiminbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                value={Hanoiminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoiminbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                value={Hanoiminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoiminbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                value={Hanoiminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoiminbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                value={Hanoiminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoiminbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                value={Hanoiminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* maxpermatch */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoipermatch[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                value={Hanoipermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoipermatch[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                value={Hanoipermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoipermatch[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                value={Hanoipermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoipermatch[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                value={Hanoipermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoipermatch[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                value={Hanoipermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Hanoipermatch[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                value={Hanoipermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                <div>
                  <span className='text-white font-bold'>Bet Limit หวยลาว</span></div>
                {isThaiTableVisible && (
                  <div className='rounded overflow-hidden mt-1'>
                    <table className="w-full" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr className=''>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laoslimitbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                value={Laoslimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laoslimitbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                value={Laoslimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laoslimitbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                value={Laoslimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laoslimitbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                value={Laoslimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laoslimitbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                value={Laoslimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laoslimitbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                value={Laoslimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* minbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laosminbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                value={Laosminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laosminbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                value={Laosminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laosminbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                value={Laosminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laosminbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                value={Laosminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laosminbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                value={Laosminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laosminbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                value={Laosminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* maxpermatch */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laospermatch[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                value={Laospermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laospermatch[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                value={Laospermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laospermatch[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                value={Laospermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laospermatch[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                value={Laospermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laospermatch[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                value={Laospermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Laospermatch[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                value={Laospermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                <div>
                  <span className='text-white font-bold'>Bet Limit จับยี่กี</span></div>
                {isThaiTableVisible && (
                  <div className='rounded overflow-hidden mt-1'>
                    <table className="w-full" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr className=''>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                          <div className='flex px-3 py-1 flex-col'>
                            <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                              {Yeekeelimitbet[0]}
                            </span>
                            <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                              name={0} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                              value={Yeekeelimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                            </input>
                          </div>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeelimitbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                value={Yeekeelimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeelimitbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                value={Yeekeelimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeelimitbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                value={Yeekeelimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeelimitbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                value={Yeekeelimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeelimitbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                value={Yeekeelimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* minbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeminbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                value={Yeekeeminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeminbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                value={Yeekeeminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeminbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                value={Yeekeeminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeminbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                value={Yeekeeminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeminbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                value={Yeekeeminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeminbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                value={Yeekeeminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* maxpermatch */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeepermatch[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                value={Yeekeepermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeepermatch[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                value={Yeekeepermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeepermatch[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                value={Yeekeepermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeepermatch[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                value={Yeekeepermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeepermatch[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                value={Yeekeepermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeepermatch[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                value={Yeekeepermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                <div>
                  <span className='text-white font-bold'>Bet Limit จับยี่กีVIP</span></div>
                {isThaiTableVisible && (
                  <div className='rounded overflow-hidden mt-1'>
                    <table className="w-full" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr className=''>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeviplimitbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                value={Yeekeeviplimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeviplimitbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                value={Yeekeeviplimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeviplimitbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                value={Yeekeeviplimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeviplimitbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                value={Yeekeeviplimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeviplimitbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                value={Yeekeeviplimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeeviplimitbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                value={Yeekeeviplimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* minbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevipminbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                value={Yeekeevipminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevipminbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                value={Yeekeevipminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevipminbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                value={Yeekeevipminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevipminbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                value={Yeekeevipminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevipminbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                value={Yeekeevipminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevipminbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                value={Yeekeevipminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* maxpermatch */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevippermatch[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                value={Yeekeevippermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevippermatch[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                value={Yeekeevippermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevippermatch[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                value={Yeekeevippermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevippermatch[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                value={Yeekeevippermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevippermatch[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                value={Yeekeevippermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevippermatch[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                value={Yeekeevippermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className='w-full text-center justify p-1 rounded-sm bg-orange-800 hover:bg-orange-90'>
                <div>
                  <span className='text-white font-bold'>Bet Limit จับยี่กี VVIP</span></div>
                {isThaiTableVisible && (
                  <div className='rounded overflow-hidden mt-1'>
                    <table className="w-full" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr className=''>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                          <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevviplimitbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                value={Yeekeevviplimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevviplimitbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                value={Yeekeevviplimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevviplimitbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                value={Yeekeevviplimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevviplimitbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                value={Yeekeevviplimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevviplimitbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                value={Yeekeevviplimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevviplimitbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                value={Yeekeevviplimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* minbet */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvipminbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                value={Yeekeevvipminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvipminbet[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                value={Yeekeevvipminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvipminbet[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                value={Yeekeevvipminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvipminbet[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                value={Yeekeevvipminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvipminbet[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                value={Yeekeevvipminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvipminbet[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                value={Yeekeevvipminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>
                        {/* maxpermatch */}
                        <tr className='bg-sky-100'>
                          <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvippermatch[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                value={Yeekeevvippermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvippermatch[1]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={1} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                value={Yeekeevvippermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvippermatch[2]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={2} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                value={Yeekeevvippermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvippermatch[3]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={3} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                value={Yeekeevvippermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvippermatch[4]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={4} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                value={Yeekeevvippermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                          <td style={{ width: '9%' }} className="text-center">
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeevvippermatch[5]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={5} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                value={Yeekeevvippermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>}
            {currentComponent === 3 &&
                <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                  <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                    <div>
                      <span className='text-white'>เปอร์เซ็นสู้สูงสุด หวยไทย</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr>
                              <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[0]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[1]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[3]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[4]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[5]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[6]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[6]}
                                    name={6}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[7]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[7]}
                                    name={7}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[8]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[8]}
                                    name={8}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[9]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[9]}
                                    name={9}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[10]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[10]}
                                    name={10}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                    <div>
                      <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด หวยฮานอย</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[0]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[1]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[3]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[4]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[5]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                    <div>
                      <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด หวยลาว</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[0]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[1]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[3]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[4]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[5]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                    <div>
                      <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด จับยี่กี</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[0]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[1]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[3]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[4]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[5]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                    <div>
                      <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด จับยี่กีVIP</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[0]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[1]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[3]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[4]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[5]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-800 hover:bg-orange-90'>
                    <div>
                      <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด จับยี่กี VVIP</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipmaxfight[0]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevvipmaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Yeekeevvipmaxfight, setYeekeevvipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipmaxfight[1]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevvipmaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Yeekeevvipmaxfight, setYeekeevvipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipmaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevvipmaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Yeekeevvipmaxfight, setYeekeevvipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipmaxfight[3]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevvipmaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Yeekeevvipmaxfight, setYeekeevvipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipmaxfight[4]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevvipmaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Yeekeevvipmaxfight, setYeekeevvipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevvipmaxfight[5]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevvipmaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Yeekeevvipmaxfight, setYeekeevvipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>}
              {currentComponent === 4 &&
                <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                  <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                    <div>
                      <span className='text-white'>Fight Limit หวยไทย</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr>
                              <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[0]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[1]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[2]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[3]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[4]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[5]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[6]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[6]}
                                    name={6}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[7]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[7]}
                                    name={7}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[8]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[8]}
                                    name={8}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[9]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[9]}
                                    name={9}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Thaimaxfight[10]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Thaimaxfight[10]}
                                    name={10}
                                    onChange={(e) => handleChange(e, Thaimaxfight, setThaimaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                    <div>
                      <span className='text-white font-bold'>Fight Limit หวยฮานอย</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[0]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[1]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[2]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[3]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[4]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Hanoimaxfight[5]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Hanoimaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Hanoimaxfight, setHanoimaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                    <div>
                      <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด หวยลาว</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[0]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[1]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[2]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[3]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[4]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Laosmaxfight[5]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Laosmaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Laosmaxfight, setLaosmaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                    <div>
                      <span className='text-white font-bold'>Fight Limit จับยี่กี</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[0]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[1]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[2]}%
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[3]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[4]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeemaxfight[5]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeemaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Yeekeemaxfight, setYeekeemaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                    <div>
                      <span className='text-white font-bold'>Fight Limit จับยี่กีVIP</span></div>
                    {isThaiTableVisible && (
                      <div className='rounded overflow-hidden mt-1'>
                        <table className="w-full" style={{ tableLayout: 'fixed' }}>
                          <thead>
                            <tr className=''>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                              <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>

                            <tr>
                              <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[0]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[0]}
                                    name={0}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[1]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[1]}
                                    name={1}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[2]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[2]}
                                    name={2}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[3]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[3]}
                                    name={3}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[4]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[4]}
                                    name={4}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                              <td style={{ width: '9%' }} className="text-center">
                                <div className='flex px-3 py-1 flex-col'>
                                  <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                    {Yeekeevipmaxfight[5]}
                                  </span>
                                  <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                    value={Yeekeevipmaxfight[5]}
                                    name={5}
                                    onChange={(e) => handleChange(e, Yeekeevipmaxfight, setYeekeevipmaxfight)}>
                                  </input>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>}

            </form>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Limitnumber