import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import MyTable from './Table';





const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const iconClass = 'inline-block align-middle';
const textClass = 'inline-block align-middle ml-1 font-semibold';



export default function CustomizedTables() {
  const user = localStorage.getItem('user')
  const [order, setOrder] = useState([]);
  const [orderid, setOrderid] = useState([]);
  const [open, setOpen] = React.useState(false);


  const OrderGet = () => {
    const json = {
      user: user
    }
    fetch(`${process.env.REACT_APP_API_KEY}/sumorder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setOrder(result)
        }
      )
  }
  useEffect(() => {
    OrderGet()
    // console.log(order)
  }, [])

  const handleClickOpen = (id) => {
    const jsonid = {
      orderid: id
    }
    setOpen(true);
    fetch(`${process.env.REACT_APP_API_KEY}/orderid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonid),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setOrderid(result)
          console.log(orderid)
        }
      )

  };

  const handleClose = () => {
    setOpen(false);
  };
  const SubOrdercolumns = [
    {
      Header: 'เลข',
      accessor: 'number',
    },
    {
      Header: 'เงินเดิมพัน (เครดิต)',
      accessor: 'bet_amout',
    },
    {
      Header: 'อัตราจ่าย',
      accessor: (row) => row.bet_amout * row.bet_payrate,
    },
    {
      Header: 'ประเภทหวย',
      accessor: 'bet_type',
      Cell: ({ value }) => {
        switch (value) {
          case 'first':
            return '6 ตัวตรง';
          case 'two_top':
            return 'สองตัวบน';
          case 'two_bot':
            return 'สองตัวล่าง';
          case 'run_top':
            return 'วิ่งบน';
          case 'run_bot':
            return 'วิ่งล่าง';
          case 'three_top':
            return 'สามตัวบน';
          case 'three_front':
            return 'สามหน้า';
          case 'three_bot':
            return 'สามตัวล่าง';
          case 'three_tod':
            return 'สามตัวโต๊ด';
          case 'four_top':
            return 'สี่ตัวบน';
          case 'four_tod':
            return 'สี่ตัวโต๊ด';
          default:
            return '';
        }
      },
    },
    {
      Header: 'สถานะ',
      accessor: 'result',
      align: 'right',
      Cell: ({ value }) => {
        const textClass = 'font-bold';
        switch (value) {
          case 'lose':
            return (
              <div className="flex items-center h-[100%] w-[100%] bg-red">
                <span className={textClass + ' text-red-500'}>ไม่ถูกรางวัล</span>
              </div>
            );
          case 'win':
            return (
              <div className="flex items-center">
                <span className={textClass + ' text-green-500'}>ถูกรางวัล</span>
              </div>
            );
          case 'wait_result':
            return (
              <div className="flex items-center">
                <span className={textClass + ' text-blue-500'}>กำลังรอผล</span>
              </div>
            );
          case 'cancel':
            return (
              <div className="flex items-center">
                <span className={textClass + ' text-red-500'}>ยกเลิก</span>
              </div>
            );
          default:
            return '';
        }
      },
    },
  ];
  const Ordercolumns = [
    { Header: 'ลำดับออเดอร์', accessor: 'id', width: '15%',widthClass: 'w-1/6', },
    {
      Header: 'เวลาที่ซื้อ',
      accessor: 'buydate',
      align: 'right',
      widthClass: 'w-1/4',
      Cell: ({ value }) => moment(value).format('DD MMM YYYY, เวลา hh:mm น'),
    },
    {
      Header: 'งวดที่ซื้อ',
      accessor: 'resultdate',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => moment(value).format('Do MMM YY'),
    },
    {
      Header: 'User', accessor: 'username', align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'ประเภทหวย',
      accessor: 'type',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        let lottoType = '';
        switch (value) {
          case 'th_gov':
            lottoType = 'หวยรัฐบาลไทย';
            break;
          case 'hanoy_PhuQua':
            lottoType = 'หวยฮานอย';
            break;
          case 'laos_gov':
            lottoType = 'หวยรัฐบาลลาว';
            break;
          case 'Yeekee_5':
            lottoType = 'ยี่กี่ ปกติ';
            break;
          case 'Yeekee_10':
            lottoType = 'ยี่กี่ VIP';
            break;
          case 'Yeekee_15':
            lottoType = 'ยี่กี่ VVIP';
            break;
          default:
            break;
        }
        return lottoType;
      },
    },
    {
      Header: 'สถานะโพย',
      accessor: 'status',
      align: 'right',
      Cell: ({ value }) => {
        let iconClass = '';
        let textClass = '';

        switch (value) {
          case 'cancel':
            iconClass = 'text-[10px]';
            textClass = 'text-[10px]';
            return (
              <div className="flex p-1 bg-amber-500 space-x-2 border-1 border-white rounded-lg items-center">
                <CancelIcon className={iconClass + ' text-white'} />
                <span className={textClass + ' text-white'}>ยกเลิกออเดอร์</span>
              </div>
            );
          case 'wait_result':
            iconClass = 'text-[10px]';
            textClass = 'text-[10px]';
            return (
              <div className="flex p-1 bg-blue-500 space-x-2 border-1 border-white rounded-lg items-center">
                <HourglassBottomIcon className={iconClass + ' text-white'} />
                <span className={textClass + ' text-white'}>กำลังรอผล</span>
              </div>
            );
          case 'lose':
            iconClass = 'text-[10px]';
            textClass = 'text-[10px]';
            return (
              <div className="flex p-1 bg-red-500 space-x-2 border-1 border-white rounded-lg items-center">
                <CancelIcon className={iconClass + ' text-white'} />
                <span className={textClass + ' text-white'}>ไม่ถูกรางวัล</span>
              </div>
            );
          case 'win':
            iconClass = 'text-[10px]';
            textClass = 'text-[10px]';
            return (
              <div className="flex p-1 bg-green-500 space-x-2 border-1 border-white rounded-lg items-center">
                <CheckCircleIcon className={iconClass + ' text-white'} />
                <span className={textClass + ' text-white'}>ถูกรางวัล</span>
              </div>
            );
          default:
            return null;
        }
      },
    },
    {
      Header: 'ยอดเดิมพัน', accessor: 'totalbet', align: 'right',
      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2)}</span>,
    },
    {
      Header: 'ดูโพย',
      align: 'center',
      Cell: ({ row }) => (
        <button
          onClick={() => handleClickOpen(row.original.id)}
          className="bg-slate-400 text-white mr-1 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
        >
          <FindInPageIcon />
        </button>
      ),
    },
  ];



  return (
    <>
      <TableContainer component={Paper}>
        <MyTable search={"Winlose"}
          showTotal={"Ordertable"}
          columns={Ordercolumns} data={order} />;
      </TableContainer>
      {open &&
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              {"โพยของคุณ"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="p-2" id="alert-dialog-description">
                <MyTable
                  columns={SubOrdercolumns}
                  data={orderid} />;
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ปิดหน้าต่าง
              </button>
            </DialogActions>
          </Dialog>
        </div>}
    </>
  );
}