import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar/Sidebar'
import './Dashboard.css'
import Navbar from './Innercontent/Navbar'
import Header from './Innercontent/header'

function Dashboard() {
  const [detail, setDetail] = useState();
  const [profitdetail, setProfitDetail] = useState();
  const user = localStorage.getItem('user')
  const pos = localStorage.getItem('position')
  const subuser = localStorage.getItem('subuser')




  const usedetail = () => {
    const json = {
      user: user
    }
    fetch(`${process.env.REACT_APP_API_KEY}/agentprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setDetail(result[0])
        }
      )
  }
  const useprofitdetail = () => {
    const json = {
      user: user
    }
    fetch(`${process.env.REACT_APP_API_KEY}/agentprofile_profit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setProfitDetail(result[0])
        }
      )
  }


  useEffect(() => {
    usedetail()
    useprofitdetail()
  },[] )
  return (
    <div>
      <Navbar />
      <div className="contain">
      <Sidebar pos={pos} />
        <div className="inner">


          <div className="content flex flex-col">
            <Header title='Account' />

            <table id="statistics" className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ชื่อไอดี Login</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">
                    {pos === 'sub_account' ? subuser : user}
                    {/* {detail && detail.username} */}
                    </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">เครดิตที่มีอยู่</th>
                  <td className="text-left px-3 py-2 font-semibold text-sky-500">
                    {detail && parseFloat(detail.credit).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
                  </td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">จำนวนลูกค้า</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{detail && detail.member_count}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">จำนวนดาวน์ไลน์</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{detail && detail.downline_count}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ยอดแทง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{profitdetail && parseFloat(profitdetail.win).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ยอดเสีย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{profitdetail && parseFloat(profitdetail.lose).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">กำไร</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{profitdetail && parseFloat(profitdetail.profit).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                </tr>


              </tbody>
            </table>
            {/* <div className='py-2 flex flex-col gap-y-2'>
            <Header title='Hot Number' />

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">รัฐบาลไทย</td>
                </tr>
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">รางวัลที่ 1</th>
                  <td className="text-left px-3 py-2 font-semibold text-sky-500"></td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">4 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">4 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวหน้า</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวหลัง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>

              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">รัฐบาลลาว</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">ฮานอย</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">ยี่กี่ปกติ</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">ยี่กี่ VIP</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">ยี่กี่ VVIP</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody className="bg-white rounded-lg shadow">
                <tr className="border-b border-gray-300">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">ประเภทหวย</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">รัฐบาลลาว</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">3 ตัวโต๊ด</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">2 ตัวล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งบน</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
                <tr className="border-b border-gray-300 rounded-bl-lg">
                  <th className="text-left px-3 py-2 font-medium text-gray-600">วิ่งล่าง</th>
                  <td className="text-left px-3 py-2 font-semibold text-gray-800">{}</td>
                </tr>
              </tbody>
            </table>

          </div> */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard