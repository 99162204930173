import React from 'react';
import './Sidebar.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TuneIcon from '@mui/icons-material/Tune';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { CSSTransition } from 'react-transition-group';
import Groups2Icon from '@mui/icons-material/Groups2';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function Sidebar({ pos }) {
  // console.log(pos)
  const [isOpen, setIsOpen] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [position, setPosition] = useState(pos);
  const user = localStorage.getItem('user')


  const useprofitdetail = () => {
    const json = {
      user: user
    }
    fetch(`${process.env.REACT_APP_API_KEY}/agentprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setPosition(result[0].position)
        }
      )
  }



  useEffect(() => {
    useprofitdetail()
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const menuItems = [
    {
      category: '1 Menu',
      items: [
        { icon: <DashboardIcon />, label: '1.1 Dashboard', href: '/' },
        { icon: <ManageAccountsIcon />, label: '1.2 จัดการสมาชิก', href: '/member' },
        { icon: <ManageAccountsIcon />, label: '1.3 จัดการบัญชีผู้ใช่ร่วม', href: '/subaccount' },
        {
          icon: <Groups2Icon />,
          label: '1.4 ระบบจัดการ Agent',
          submenu: [
            // Add submenu items based on user's roxale (pos)
            ...(position === 'owner'
              ? [
              { label: '1.4.1 จัดการ Company', href: '/agent?level=company' },
              { label: '1.4.2 จัดการ Senior', href: '/agent?level=senior' },
              { label: '1.4.3 จัดการ Master', href: '/agent?level=master' },
              { label: '1.4.4 จัดการ Agent', href: '/agent?level=agent' }]
              : []),
            ...(position === 'company'
              ? [
              { label: '1.4.1 จัดการ Senior', href: '/agent?level=senior' },
              { label: '1.4.2 จัดการ Master', href: '/agent?level=master' },
              { label: '1.4.3 จัดการ Agent', href: '/agent?level=agent' }]
              : []),
            ...(position === 'senior'
              ? [
                { label: '1.4.1 จัดการ Master', href: '/agent?level=master' },
                { label: '1.4.2 จัดการ Agent', href: '/agent?level=agent' },
                ]
              : []),
            ...(position === 'master'
              ? [
              { label: '1.4.1 จัดการ Agent', href: '/agent?level=agent' }]
              : []),
            ...(position === 'agent'
              ? []
              : []),
          ],
          isOpen: isSubMenuOpen,
          toggleSubMenu: toggleSubMenu,
        },
        { icon: <ManageAccountsIcon />, label: '1.5 จัดการคอมมิชชั่น', href: '/commission' },

      ].filter(Boolean),
    },
    {
      category: '2 Limit Setting',
      items: [
        // ...(pos === 'senior' || pos === 'agent'
        // ? [
          { icon: <TuneIcon />, label: '2.1 เปิดปิดหวย', href: '/payrate' },
        // ]
        // : []),
        { icon: <TuneIcon />, label: '2.2 เลขอั้น', href: '/limitnumber' },
        { icon: <TuneIcon />, label: '2.3 ตั้งค่ารับสู้สูงสุด', href: '/Fightlimitmax' },
      ],
    },
    {
      category: '3 Report',
      items: [
        { icon: <ReceiptIcon />, label: '3.1 แพ้ชนะรวม', href: '/winlose' },
        { icon: <ReceiptIcon />, label: '3.2 ผลหวย', href: '/lottoresult' },
        { icon: <ReceiptIcon />, label: '3.3 ยอดคงค้าง', href: '/lottowaitresult' },
      ],
    },
    {
      category: '4 System Logs',
      items: [
        { icon: <ReceiptIcon />, label: '4.1 ประวัติการเข้าระบบ', href: '/loginlogs' },
      ],
    },

  ];

  return (
    <div className="relative">
      <button
        className="fixed bottom-0 left-0 p-2 m-2 rounded-full bg-gray-600 text-white hover:bg-purple-800"
        onClick={toggleSidebar}
      >
        {isOpen ? 'ปิดเมนู' : 'เปิดเมนู'}
      </button>
      <CSSTransition in={isOpen} timeout={300} classNames="sidebar" unmountOnExit>
        <div className="w-auto h-full flex flex-col bg-white w-60">
          <div className="bg-gradient-to-l from-purple-800 text-center rounded-b-lg p-1S">
            <span className="text-zinc-600 text-sm font-bold">
              {currentTime.toLocaleString('en-US', {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })}
            </span>
          </div>
          <div className="menu p-1">
            {menuItems.map((category) => (
              <div key={category.category}>
                <div className="rounded-lg bg-black h-[1.5em] p-1 mt-1">
                  <h4 className="text-amber-400 text-sm font-extrabold ml-2">
                    {category.category}
                  </h4>
                </div>

                {category.items.map((menuItem) =>
                  menuItem.submenu ? (
                    <div
                      className="flex flex-col"
                      key={menuItem.label}
                    >
                      <div className="link mt-1 hover:bg-violet-300 rounded-t-lg" onClick={menuItem.toggleSubMenu}>
                        <span>{menuItem.icon}</span>
                        <span className="ml-2 mt-1 text-sm font-light text-black hover:text-amber-400">
                          {menuItem.label}
                        </span>
                        {menuItem.isOpen ? (
                          <ExpandLessIcon className="ml-2 mt-1 text-sm font-light text-black hover:text-amber-400" />
                        ) : (
                          <ExpandMoreIcon className="ml-2 mt-1 text-sm font-light text-black hover:text-amber-400" />
                        )}
                      </div>
                      <CSSTransition
                        in={menuItem.isOpen}
                        timeout={300}
                        classNames="submenu"
                        unmountOnExit
                      >
                        <div className="pl-4">
                          {menuItem.submenu.map((subMenuItem) => (
                            <div
                              className="link mt-1 hover:bg-violet-300 rounded-t-lg"
                              key={subMenuItem.href}
                            >
                              <Link to={subMenuItem.href}>
                                <span className="ml-2 mt-1 text-sm font-light text-black hover:text-amber-400">
                                  {subMenuItem.label}
                                </span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </CSSTransition>
                    </div>
                  ) : (
                    <div className="flex flex-col" key={menuItem.href}>
                      <div className="link mt-1 hover:bg-violet-300 rounded-t-lg">
                        <Link to={menuItem.href}>
                          <span>{menuItem.icon}</span>
                          <span className="ml-2 mt-1 text-sm font-light text-black hover:text-amber-400">
                            {menuItem.label}
                          </span>
                        </Link>
                      </div>
                      <div className="p-1">
                        <div className="w-full h-[1px] bg-zinc-400"></div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Sidebar;
