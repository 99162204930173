import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Header from './header'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/th';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MyTable from './Table'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';




function Limitnumber() {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [limitnum, setLimitnum] = useState([]);
  const user = localStorage.getItem('user');
  const [startDate, setStartDate] = useState(new Date());
  const [lottotype, setLottotype] = useState('th_gov');
  const [open, setOpen] = useState(false);
  const [openedit, setOpenedit] = useState(false);
  const [lottoid, setLottoid] = useState(false);


  const [number, setNumber] = useState('');
  const [numbertype, setNumbertype] = useState('first');
  const [limitpayrate, setLimitpayrate] = useState(0);
  const [roundofday, setRoundofday] = useState(1);



  const json = {
    agent: user,
    lotto_type: lottotype,
    res_date: moment(startDate).format('YYYY-MM-DD')
  }

  const limitnumget = async () => {
    await fetch(`${process.env.REACT_APP_API_KEY}/Getlimitnumber`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then((result) => { setLimitnum(result) })
  }
  useEffect(() => {
    limitnumget()
  }, [lottotype, startDate])

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseedit = () => {
    setNumber()
    setLottoid('')
    setLimitpayrate(0)
    setOpenedit(false);
  };
  const handleOpenedit = (number, id, rate) => {
    setLottoid(id)
    setNumber(number)
    setLimitpayrate(rate)
    setOpenedit(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    const jsonforinsert = {
      agent: user,
      lotto_type: lottotype,
      result_date: moment(startDate).format('YYYY-MM-DD'),
      number: number,
      number_type: numbertype,
      limit_payrate: parseInt(limitpayrate),
      round_of_day: roundofday,
    };
  
    // Check for empty or undefined fields
    const emptyFields = Object.keys(jsonforinsert).filter(
      (key) => jsonforinsert[key] === null || jsonforinsert[key] === undefined || jsonforinsert[key] === ''
    );
  
    if (emptyFields.length > 0) {
      Swal.fire({
        title: 'ข้อมูลไม่ครบถ้วน',
        text: 'กรอกข้อมูลให้ครบถ้วน',
        icon: 'error',
      });
      return;
    }
  
    const confirmationMessage = `คุณจะเพิ่มเลข ${jsonforinsert.number} หวย ${jsonforinsert.lotto_type} งวดวันที่ ${jsonforinsert.result_date} รางวัล ${jsonforinsert.limit_payrate} ใช่หรือไม่`;
  
    Swal.fire({
      title: 'แน่ใจหรือไม่?',
      text: confirmationMessage,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_KEY}/addlimitnum`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(jsonforinsert),
        })
          .then((res) => res.json())
          .then(() => {
            toast.success('เพิ่มข้อมูลสำเร็จ', { autoClose: 2000 });
          })
          .catch((error) => {
            toast.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล', { autoClose: 2000 });
            console.error(error);
          });
      } else {
        console.log('ยกเลิกการเพิ่มข้อมูล');
      }
    });
  };
  
  

  const createOptions = (listnum) => {
    const options = [];
    for (let i = 1; i <= listnum; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  }

  const Yeekee5 = createOptions(288)
  const Yeekee10 = createOptions(144)
  const Yeekee15 = createOptions(96)
  const jackpot = createOptions(6)

  const deletelimit = (id) => {
    const json = {
      limitid: id
    }
    fetch(`${process.env.REACT_APP_API_KEY}/dellimitnum`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
    limitnumget()
  };

  const updatelimit = () => {
    const json = {
      rate: limitpayrate,
      limitid: lottoid
    }
    fetch(`${process.env.REACT_APP_API_KEY}/updatelimitnum`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
    limitnumget()
  };

  const renderNumberTypeCell = (row) => {
    let numberType = '';

    switch (row.number_type) {
      case 'two_top':
        numberType = 'สองตัวบน';
        break;
      case 'two_bot':
        numberType = 'สองตัวล่าง';
        break;
      case 'three_top':
        numberType = 'สามตัวบน';
        break;
      case 'three_bot':
        numberType = 'สามตัวล่าง';
        break;
      case 'three_tod':
        numberType = 'สามตัวโต๊ด';
        break;
      case 'four_top':
        numberType = 'สี่ตัวบน';
        break;
      case 'four_tod':
        numberType = 'สี่ตัวโต๊ด';
        break;
      case 'first':
        numberType = 'หกตัวตรง';
        break;
      case 'run_top':
        numberType = 'วิ่งบน';
        break;
      case 'run_bot':
        numberType = 'วิ่งล่าง';
        break;
      default:
        break;
    }

    return numberType;
  };

  const renderLottoTypeCell = (row) => {
    let lottoType = '';

    switch (row.lotto_type) {
      case 'th_gov':
        lottoType = 'รัฐบาลไทย';
        break;
      case 'laos_gov':
        lottoType = 'ลาว';
        break;
      case 'jackpot':
        lottoType = 'หวยฮานอย';
        break;
      case 'Yeekee_5':
        lottoType = 'ยี่กี่ 5 นาที';
        break;
      case 'Yeekee_10':
        lottoType = 'ยี่กี่ 10 นาที';
        break;
      case 'Yeekee_15':
        lottoType = 'ยี่กี่ 15 นาที';
        break;
      default:
        break;
    }

    return lottoType;
  };

  const columns = [
    {
      Header: 'เลขที่อั้น',
      accessor: 'number',
      align: 'center',
    },
    {
      Header: 'ประเภทเลข',
      accessor: 'number_type',
      Cell: ({ value }) => renderNumberTypeCell(value),
      align: 'center',
    },
    {
      Header: 'รางวัล',
      accessor: 'limit_payrate',
      align: 'center',
    },
    {
      Header: 'ประเภทหวย',
      accessor: 'lotto_type',
      Cell: ({ value }) => renderLottoTypeCell(value),
      align: 'center',
    },
    {
      Header: 'งวด',
      accessor: 'result_date',
      Cell: ({ value }) => moment(value).format('ll'),
      align: 'center',
    },
    {
      Header: 'รอบที่ออกผล',
      accessor: 'round_of_day',
      align: 'center',
    },
    {
      Header: 'แก้ไข',
      Cell: ({ row }) => (
        <div>
          <Button onClick={() => handleOpenedit(row.original.number, row.original.id, row.original.limit_payrate)}>แก้ไข</Button>
          <Button onClick={() => deletelimit(row.original.id)}>ลบ</Button>
        </div>
      ),
      align: 'center',
    },
  ];

  const pos = localStorage.getItem('position')

  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos} />
        <div className="inner">
          <div className="content">
            <Header title='Limit Number' />
            <div className='w-[100%] md:w-[100%] h-[50px] my-3 bg-sky-300 rounded flex flex row'
            >

              <div className='bg-yellow-500 p-2 rounded w-[35px]'>
                <SearchIcon className='text-white' />
              </div>

              <div className='flex flex-col ml-3'>
                <span className='text-white text-[10px] font-bold ml-1'>งวดที่</span>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>

              <div className='flex flex-col ml-3'>
                <span className='text-white text-[10px] font-bold ml-1'>เลือกประเภทหวย</span>
                <select value={lottotype} onInput={(e) => setLottotype(e.target.value)}>
                  <option value="th_gov">รัฐบาลไทย</option>
                  <option value="laos_gov">ลาว</option>
                  <option value="jackpot">แจ็คพอต</option>
                  <option value="Yeekee_5">ยี่กี่ 5 นาที</option>
                  <option value="Yeekee_10">ยี่กี่ 10 นาที</option>
                  <option value="Yeekee_15">ยี่กี่ 15 นาที</option>
                </select>
              </div>
            </div>
            <div className='p-1 mb-1 bg-purple-800 h-[2em] w-[10%] text-center text-white rounded border-solid hover:bg-sky-800 '>
              <FormatListNumberedIcon className='ml-1 text-[8px] md:text-[16px]' />
              <span className='ml-1 text-[8px] md:text-[16px]' onClick={handleClickOpen}>เพิ่มเลขอั้น</span>
            </div>
            <TableContainer component={Paper}>
              <MyTable data={limitnum} columns={columns} />
            </TableContainer>
            {open &&
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div className="App">
                    <form onSubmit={handleSubmit}>

                      <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
                        {"เพิ่มเลขอั้น"}
                      </DialogTitle>
                      <DialogContent className='p-1'>
                        <div className='register'>
                          <div className="flex flex-col mb-4">
                            <label className="mb-2 font-bold text-lg text-gray-900" htmlFor="number">เลขที่อั้น</label>
                            <input
                              className="border py-2 px-3 text-grey-800"
                              type="text"
                              id="number"
                              placeholder="เลขอั้น"
                              onChange={(e) => setNumber(e.target.value)}
                            />
                          </div>

                          <div className="flex flex-col mb-4">
                            <label className="mb-2 font-bold text-lg text-gray-900" htmlFor="limitpayrate">รางวัลเลขที่อั้น</label>
                            <input
                              className="border py-2 px-3 text-grey-800"
                              type="number"
                              id="limitpayrate"
                              placeholder="รางวัล"
                              onChange={(e) => setLimitpayrate(e.target.value)}
                            />
                          </div>

                          <div className="flex flex-col mb-4">
                            <label className="mb-2 font-bold text-lg text-gray-900" htmlFor="numbertype">ประเภทเลข</label>
                            <select
                              className="border py-2 px-3 text-grey-800"
                              id="numbertype"
                              onChange={(e) => setNumbertype(e.target.value)}
                            >
                              <option value="first">หกตัวตรง</option>
                              <option value="four_top">สี่ตัวบน</option>
                              <option value="four_tod">สี่ตัวโต๊ด</option>
                              <option value="three_top">สามตัวบน</option>
                              <option value="three_front">สามตัวหน้า</option>
                              <option value="three_bot">สองตัวล่าง</option>
                              <option value="three_tod">สามตัวโต๊ด</option>
                              <option value="two_top">สองตัวบน</option>
                              <option value="two_bot">สองตัวล่าง</option>
                              <option value="run_top">วิ่งบน</option>
                              <option value="run_bot">วิ่งล่าง</option>
                            </select>
                          </div>
                          <div className="flex flex-col mb-4">
                            <label className="mb-2 font-bold text-lg text-gray-900" htmlFor="lottotype">ประเภทหวย</label>
                            <select
                              className="border py-2 px-3 text-grey-800"
                              id="numbertype"
                              value={lottotype} onInput={(e) => setLottotype(e.target.value)}
                            >
                              <option value="th_gov">รัฐบาลไทย</option>
                              <option value="laos_gov">ลาว</option>
                              <option value="jackpot">หวยฮานอย</option>
                              <option value="Yeekee_5">ยี่กี่ 5 นาที</option>
                              <option value="Yeekee_10">ยี่กี่ 10 นาที</option>
                              <option value="Yeekee_15">ยี่กี่ 15 นาที</option>
                            </select>
                          </div>
                          <div className="flex flex-col mb-4">
                            <label className="mb-2 font-bold text-lg text-gray-900" htmlFor="startDate">งวด</label>
                            <DatePicker className="border py-2 px-3 text-grey-800" selected={startDate} onChange={(date) => setStartDate(date)} />
                          </div>
                          <span>รอบที่ออกของงวด</span>
                          <select
                            className="border py-2 px-3 text-grey-800"
                            onChange={(e) => setRoundofday(e.target.value)}>
                            {lottotype === "Yeekee_5" && Yeekee5}
                            {lottotype === "Yeekee_10" && Yeekee10}
                            {lottotype === "Yeekee_15" && Yeekee15}
                            {lottotype === "hanoy_PhuQua" && jackpot}
                            {lottotype === "th_gov" && <option value="1">1</option>}
                            {lottotype === "laos_gov" && <option value="1">1</option>}
                          </select>


                        </div>
                      </DialogContent>
                      <DialogActions>
                        <button type='submit' className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                          <SaveAltIcon /> บันทึก
                        </button>
                        <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          ยกเลิก
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </Dialog>
              </div>}
            {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            {openedit &&
              <div>
                <Dialog
                  open={openedit}
                  onClose={handleCloseedit}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div className="App">
                    <form onSubmit={updatelimit}>
                      <DialogTitle id="alert-dialog-title">
                        {"แก้ไขเรทเลขอั้น"}
                      </DialogTitle>
                      <DialogContent>
                        <div className='register'>
                          <span>เลขที่อั้น </span>
                          <h2>{number}</h2>

                          <span>รางวัลเลขที่อั้น</span>
                          <input
                            type="number"
                            placeholder="รางวัล"
                            value={limitpayrate}
                            onChange={(e) => setLimitpayrate(e.target.value)}
                          />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button type="submit">UPDATE</Button>
                        <Button onClick={handleCloseedit}>Close</Button>
                      </DialogActions>
                    </form>
                  </div>
                </Dialog>
              </div>}

          </div>
        </div>

      </div>
    </div>
  )
}

export default Limitnumber