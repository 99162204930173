import React, { useEffect, useState } from "react";
import TableContainer from '@mui/material/TableContainer';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './Membertable.css'
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'react-toastify';
import MyTable from './Table'
import { useLocation } from 'react-router-dom';
import PasswordIcon from '@mui/icons-material/Password';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from "moment/moment";


export default function CustomizedTables(props) {

  const [member, setMember] = useState([]);
  const [memberfilter, setMemberfilter] = useState([])
  const [userid, setUserid] = useState([]);
  const [reload, setReload] = useState(0);
  const user = localStorage.getItem('user');
  const positions = localStorage.getItem('position');
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const levelurl = searchParams.get('level');

  const subuserid = localStorage.getItem('subuserid')
  const [permission,setPermission] = useState({'agent_credit_update' : 1,'edit_agent' : 1,'add_agent' : 1})
  const permissionGet = () => {
    const jsonid = {
      id: subuserid,
    };

    fetch(`${process.env.REACT_APP_API_KEY}/getpermissionid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonid),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(positions === 'sub_account'){
            setPermission(result[0])
          }
        }
      )

  };

  const positionget = {
    username: user,
    position: positions
  }


  const MemberGet = async () => {
    await fetch(`${process.env.REACT_APP_API_KEY}/admindetail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then(res => res.json())
      .then((result) => {
        setMember(result)
        if (levelurl === 'all') {
          setMemberfilter(result)
        } else {
          const filteredArray = result.filter(element => element.position === levelurl);
          setMemberfilter(filteredArray)
        }
      })
  }
  useEffect(() => {
    MemberGet()
    permissionGet()
  }, [reload])

  useEffect(() => {
    if (levelurl === 'all') {
      console.log(levelurl)
      setMemberfilter(member)
    } else {
      const filteredArray = member.filter(element => element.position === levelurl);
      setMemberfilter(filteredArray)
    }
  }, [levelurl])
  //////////////////////// redirect to edit agent page ////////////////////


  const handleDeleteagent = (agent) => {


    if
    (permission.edit_agent === 1)
    {
          // Show confirmation dialog
    Swal.fire({
      title: 'ลบสมาชิก?',
      text: `คุณต้องการลบ ${agent} รายนี้หรือไม่ ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the agent
        const jsonid = {
          username: agent
        };
        fetch(`${process.env.REACT_APP_API_KEY}/deleteagent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonid)
        })
          .then(res => res.json())
          .then(() => {
            toast.success('ลบสมาชิกเรียบร้อยแล้ว');
            window.location.reload();
          })
          .catch((error) => {
            toast.error('เกิดข้อผิดพลาดบางอย่าง!');
          });
      }
    });
    } else 
    {
      alert('No permission')
    }

  };

  const handlecredit = async (memberuser, rowid, value) => {
    let inputValue = value
    let update = await parseInt(inputValue);
    let useradmin = localStorage.getItem('user');
    const jsonid = {
      adminusername: useradmin,
      updateamout: update,
      username: memberuser,
    };

    if
    (permission.agent_credit_update === 1)
    {
          // Check if input field is empty
    if (!inputValue || isNaN(update)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'กรุณากรอกจำนวนเครดิตที่ต้องการเติม!',
      });
      return;
    }

    Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: `คุณต้องการเติมเครดิตจำนวน ${update} ให้กับ ${memberuser} ใช่หรือไม่?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_KEY}/updateagentcredit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res === 'insufficient_Credit') {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'เครดิตไม่เพียงพอ!',
              });
            } else {
              Swal.fire({
                icon: 'success',
                title: 'สำเร็จ!',
                text: 'เติมเครดิตเรียบร้อยแล้ว!',
              }).then(() => {
                setReload(reload + 1);
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'เกิดข้อผิดพลาด!',
            });
          });
      }
    });


    } else 
    {
      alert('No permission')
    }

  };

  const [transactiom,setTransaction] = useState([]);
  const [opentransaction, setOpentransaction] = React.useState(false);

  const handleClosetransaction = () => {
    setOpentransaction(false)
  }
  const gettransaction = (agent) => {
    const positionget = {
      user: agent
    }
    fetch(`${process.env.REACT_APP_API_KEY}/gettransaction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(positionget),
      })
        .then(res => res.json())
        .then((result) => {
          setTransaction(result)
          setOpentransaction(true)
        })
  
  }



  // #region password edit

  const [password, setPassword] = useState();
  const [openeditpassword, setOpeneditpassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickOpenpassword = (username) => {
    if
    (permission.edit_agent === 1)
    {
      setUserid(username)
      setOpeneditpassword(true)
    } else 
    {
      alert('No permission')
    }
  };

  const handleClosepassword = () => {
    setOpeneditpassword(false);
    setUserid()
  };

  const handleChangepassword = (event) => {
    setPassword(event.target.value)
  };
  const handleUpdatepassword = (password) => {
    const jsonid = {
      Username: userid,
      Password: password,
    };

    Swal.fire({
      title: 'เปลี่ยนรหัสให้กับสมาชิก?',
      text: 'กดยืนยันเพื่อแก้ไขรหัสให้กับสมาชิก?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action, proceed with the fetch
        fetch(`${process.env.REACT_APP_API_KEY}/passwordchange`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((response) => {
            if (response.ok) {
              // Password update was successful, show a success notification
              toast.success('เปลี่ยนรหัสให้กับสมาชิก สำเร็จ', {
                position: 'top-right',
                autoClose: 3000, // You can adjust the duration
              });
            } else {
              // Password update failed, show an error notification
              toast.error('Password update failed', {
                position: 'top-right',
                autoClose: 3000, // You can adjust the duration
              });
            }
          })
          .catch((error) => {
            console.error('Error updating password:', error);
            // Show an error notification for any fetch error
            toast.error('An error occurred while updating the password', {
              position: 'top-right',
              autoClose: 3000, // You can adjust the duration
            });
          });
      }
    });

    handleClosepassword(); // Close the password dialog regardless of the user's choice
  };


  // #endregion

  // #region downline show

  const [opendownline, setOpendownline] = React.useState(false);
  const [downline, setDownline] = useState([]);
  const [downlineof, setDownlineof] = useState();
  const handleClickOpendownline = (username, value) => {
    setDownlineof(username)

    if
    (permission.edit_agent === 1)
    {
      const postdata = {
        user: username
      }
  
      if (value > 0) {
        fetch(`${process.env.REACT_APP_API_KEY}/getdownline`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postdata)
        })
          .then(res => res.json())
          .then(result => {
            setDownline(result)
          }
          )
          .then(setOpendownline(true))
  
      } else {
        toast.error("ไม่มีดาวน์ไลน์");
      }
    } else 
    {
      alert('No permission')
    }

    

  };

  const handleClosedownline = () => {
    setOpendownline(false);
    setUserid()
  };

  // #endregion
  const Downlinecolumns = [
    {
      Header: 'Agent Username',
      widthClass: 'w-1/6',
      accessor: 'username',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'position',
      accessor: 'position',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        let bgcolor = 'bg-slate-700';
        if (value === 'agent') {
          bgcolor = "bg-sky-500"
        }
        if (value === 'senior') {
          bgcolor = "bg-green-600"
        }
        if (value === 'master') {
          bgcolor = "bg-yellow-500"
        }
        if (value === 'company') {
          bgcolor = "bg-purple-600"
        }
        return (
          <div className={`p-1 w-auto drop-shadow-xl  rounded-lg text-center ${bgcolor} border-[1px] border-slate-400`}>
            <span
              className={`font-bold text-[15px] text-white`}>{value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'upline username',
      accessor: 'upline_username',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'total downline',
      accessor: 'downline_count',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-sky-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <span className={`font-bold text-[15px] ${textColorClass}`}>
            {floatValue}
          </span>
        );
      },
    },
    {
      Header: 'total member',
      accessor: 'member_count',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-sky-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <span className={`font-bold text-[15px] ${textColorClass}`}>
            {floatValue}
          </span>
        );
      },
    },
    {
      Header: 'credit',
      accessor: 'credit',
      align: 'right',
      Cell: ({ value }) => {
        const floatValue = parseFloat(value).toFixed(2);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-green-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <>
          <span className={`font-bold text-[15px] ${textColorClass}`}>
            {floatValue.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
          </span>
          <div>ประวัติ</div>
          </>
        );
      },
    },
    {
      Header: 'เพิ่มลบเครดิต',
      align: 'right',
      Cell: ({ value, row }) => (
        <div className="flex flex-row px-2 h-[50%]">
          <input
            type="number"
            min={0}
            step={100}
            className="border border-gray-400 rounded-l-lg p-1 text-gray-700 leading-5 text-xs focus:outline-none focus:border-blue-500"
            id={row.id}
          />
          <div
            onClick={() => handlecredit(row.original.username, row.original.id, document.getElementById(row.id).value)}
            className="bg-green-500 p-1 w-full text-center text-white justify-items-center items-center border-solid hover:bg-green-800 cursor-pointer"
          >
            <span className="text-xl font-bold md:text-[12px]">
              +
            </span>
          </div>
          <div
              onClick = {() => handlecredit(row.original.username, row.original.id, (document.getElementById(row.id).value) * -1)}
              className="bg-red-500 p-1 w-full text-center rounded-r-lg text-white border-solid hover:bg-red-800 cursor-pointer"
            >
              <span className="text-xl font-bold md:text-[12px]">
                -
              </span>
            </div>
        </div>
      ),
    },
    {
      Header: 'แก้ไขข้อมูล',
      align: 'center',
      Cell: ({ row }) => (
        <div className="flex flex-row space-x-1">
          <button
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <a href={`/agentedit?username=${row.original.username}&position=${row.original.position}&uplineusername=${row.original.upline_username}`} className="text-white no-underline">
              <SettingsIcon />
            </a>
          </button>
          <button onClick={() => handleClickOpenpassword(row.original.Username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs">
            <PasswordIcon />
          </button>
          <button
            onClick={() => handleDeleteagent(row.original.username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <DeleteForeverIcon className="text-xs md:text-base" />
          </button>
        </div>
      ),
    },
  ];
  const Agentcolumns = [
    {
      Header: 'Agent Username',
      accessor: 'username',
      widthClass: 'w-1/6',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'position',
      accessor: 'position',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        let bgcolor = 'bg-slate-700';
        if (value === 'agent') {
          bgcolor = "bg-sky-500"
        }
        if (value === 'senior') {
          bgcolor = "bg-green-600"
        }
        if (value === 'master') {
          bgcolor = "bg-yellow-500"
        }
        if (value === 'company') {
          bgcolor = "bg-purple-600"
        }
        return (
          <div className={`p-1 w-auto drop-shadow-xl  rounded-lg text-center ${bgcolor}`}>
            <span
              className={`font-bold text-[8px] md:text-[15px] text-white`}>{value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'upline username',
      accessor: 'upline_username',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'total downline',
      accessor: 'downline_count',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value, row }) => {
        const intValue = parseInt(value, 10);
        let textColorClass = 'text-slate-400'; // Default to black
        let bgColorClass = 'bg-amber-200';
        let bgColorClassHover = 'bg-amber-400'

        if (intValue > 0) {
          textColorClass = 'text-slate-700'; // Change to green for positive values
          bgColorClass = 'bg-amber-300';
          bgColorClassHover = 'bg-amber-400'
        } else if (intValue <= 0) {
          textColorClass = 'text-red-500'; // Change to red for negative values
          bgColorClass = 'bg-slate-300';
          bgColorClassHover = 'bg-slate-600'
        }

        return (
          <div className="flex space-x-2 flex-row">
            <div
              className={`font-bold text-[8px] md:text-[10px] ${bgColorClass} ${textColorClass} rounded-lg border-1 border-black px-2 pt-1`} // Add rounded-md for a rounded border
            >
              {value} คน
            </div>
            <button onClick={() => handleClickOpendownline(row.original.username, intValue)} className="text-slate-600 no-underline hover:text-slate-800">
              <SearchIcon />
            </button>

          </div>
        );
      },
    },
    {
      Header: 'total member',
      accessor: 'member_count',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-sky-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <span className={`font-bold text-[15px] ${textColorClass}`}>
            {floatValue}
          </span>
        );
      },
    },
    {
      Header: 'credit',
      accessor: 'credit',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ row,value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-green-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <>
          <span className={`font-bold text-[15px] ${textColorClass}`}>
            {floatValue.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
          </span>
            <button onClick={() => gettransaction(row.original.username)} className="text-slate-600 no-underline hover:text-slate-800">
              <SearchIcon />
            </button>
          </>
        );
      },
    },
    {
      Header: 'เพิ่มลบเครดิต',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value, row }) => (
        <div className="flex flex-row px-2 h-[50%]">
          <input
            type="number"
            min={0}
            step={100}
            className="border border-gray-400 rounded-l-lg p-1 text-gray-700 leading-5 text-xs focus:outline-none focus:border-blue-500"
            id={row.id}
          />
          <div
            onClick={() => handlecredit(row.original.username, row.original.id, document.getElementById(row.id).value)}
            className="bg-green-500 p-1 w-full text-center text-white justify-items-center items-center border-solid hover:bg-green-800 cursor-pointer"
          >
            <span className="text-xl font-bold md:text-[12px]">
              +
            </span>
          </div>
          <div
              onClick = {() => handlecredit(row.original.username, row.original.id, (document.getElementById(row.id).value) * -1)}
              className="bg-red-500 p-1 w-full text-center rounded-r-lg text-white border-solid hover:bg-red-800 cursor-pointer"
            >
              <span className="text-xl font-bold md:text-[12px]">
                -
              </span>
            </div>
        </div>
      ),
    },
    {
      Header: 'แก้ไขข้อมูล',
      align: 'center',
      Cell: ({ row }) => (
        <div className="flex flex-row space-x-1">
          <button
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <a href={`/agentedit?username=${row.original.username}&position=${row.original.position}&uplineusername=${row.original.upline_username}`} className="text-white no-underline">
              <SettingsIcon />
            </a>
          </button>
          <button onClick={() => handleClickOpenpassword(row.original.Username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs">
            <PasswordIcon />
          </button>
          <button
            onClick={() => handleDeleteagent(row.original.username)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <DeleteForeverIcon className="text-xs md:text-base" />
          </button>
        </div>
      ),
    },
  ];
  const transactioncolumns = [
    {
      Header: 'ส่ง',
      accessor: 'Sender',
      widthClass: 'w-1/6',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
    },
    {
      Header: 'รับ',
      accessor: 'Reciever',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value }) => {
        let bgcolor = 'bg-slate-700';
        if (value === 'agent') {
          bgcolor = "bg-sky-500"
        }
        if (value === 'senior') {
          bgcolor = "bg-green-600"
        }
        if (value === 'master') {
          bgcolor = "bg-yellow-500"
        }
        if (value === 'company') {
          bgcolor = "bg-purple-600"
        }
        return (
          <div className={`p-1 w-auto drop-shadow-xl  rounded-lg text-center ${bgcolor}`}>
            <span
              className={`font-bold text-[8px] md:text-[15px] text-white`}>{value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'จำนวน',
      accessor: 'Amout',
      align: 'center',
      Cell: ({ value }) => {
        const floatValue = parseFloat(value);
        let textColorClass = 'text-slate-400'; // Default to black

        if (floatValue > 0) {
          textColorClass = 'text-green-600'; // Change to green for positive values
        } else if (floatValue < 0) {
          textColorClass = 'text-red-600'; // Change to red for negative values
        }

        return (
          <span className={`font-bold ${textColorClass}`}>
            {floatValue.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
          </span>
        );
      },
    },
{
      Header: 'วันที่',
      accessor: 'date_time',
      align: 'right',
      widthClass: 'w-1/6',
      Cell: ({ value, row }) => {
        let textColorClass = 'text-slate-400'; // Default to black
        let bgColorClass = 'bg-amber-200';
        
        return (
          <div className="flex space-x-2 flex-row">
            <div
              className={`font-bold text-[8px] md:text-[15px] ${bgColorClass} ${textColorClass} rounded-lg border-1 border-black px-2 pt-1`} // Add rounded-md for a rounded border
            >
              {moment(value).format('DD/MM/YYYY HH:MM')}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <MyTable columns={Agentcolumns} data={memberfilter} search={"AgentData"} />
      </TableContainer>
      {/* edit agent */}
      {/* edit password */}
      {openeditpassword &&
        <div>
          <Dialog
            open={openeditpassword}
            onClose={handleClosepassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              แก้ไขรหัสผ่าน Username : {userid}
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <span>ใส่รหัสผ่านใหม่</span>

                  <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      onChange={(e) => handleChangepassword(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <button onClick={() => handleUpdatepassword(password)} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                <SaveAltIcon /> บันทึก
              </button>
              <button onClick={handleClosepassword} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}
      {/* downline show */}
      {opendownline &&
        <div>
          <Dialog
            open={opendownline}
            onClose={handleClosedownline}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              <div className="p-1 flex justify-items-center items-center flex-row space-x-2">
                <span className="font-bold">รายชื่อ Downline</span>
                <div className="p-1 border-1 bg-yellow-400 rounded-lg drop-shadow-xl">
                <span className="font-bold text-slate-700 text-[15px]">{downlineof}</span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <MyTable columns={Downlinecolumns} data={downline} />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button onClick={handleClosedownline} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}

        
        {opentransaction &&
        <div>
          <Dialog
            open={opentransaction}
            onClose={handleClosetransaction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              <div className="p-1 flex justify-items-center items-center flex-row space-x-2">
                <span className="font-bold">ประวัติเครดิต</span>
                <div className="p-1 border-1 bg-yellow-400 rounded-lg drop-shadow-xl">
                <span className="font-bold text-slate-700 text-[15px]">{downlineof}</span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <MyTable columns={transactioncolumns} data={transactiom} />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button onClick={handleClosetransaction} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}


    </div>
  );
}
