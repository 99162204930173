import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import Swal from 'sweetalert2';
import Header from './header'
import { toast } from 'react-toastify';
import 'moment/locale/th';
import { useLocation } from 'react-router-dom';




function Limitnumber() {
  const [reload, setReload] = useState(0);
  const [currentComponent, setCurrentComponent] = useState(1);
  const [username, Setusername] = useState("Loading");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const usernameurl = searchParams.get('username');
  const positionUrl = searchParams.get('position');
  const uplineusernameUrl = searchParams.get('uplineusername');
  const isDownline = searchParams.get('downline');
  const downlineof = searchParams.get('downlineof')
  const [isThaiTableVisible, setThaiTableVisible] = useState(true);
  // console.log(isDownline)

  // #region

  const [Thaicommission, setThaicommission] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoicommission, setHanoicommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoscommission, setLaoscommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeecommission, setYeekeecommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipcommission, setYeekeevipcommission] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipcommission, setYeekeevvipcommission] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaicommissionupdate, setThaicommissionupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoicommissionupdate, setHanoicommissionupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoscommissionupdate, setLaoscommissionupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeecommissionupdate, setYeekeecommissionupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipcommissionupdate, setYeekeevipcommissionupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate] = useState([0, 0, 0, 0, 0, 0]);


  const [Thaipayrate, setThaipayrate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipayrate, setHanoipayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospayrate, setLaospayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepayrate, setYeekeepayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippayrate, setYeekeevippayrate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippayrate, setYeekeevvippayrate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaipayrateupdate, setThaipayrateupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipayrateupdate, setHanoipayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospayrateupdate, setLaospayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepayrateupdate, setYeekeepayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippayrateupdate, setYeekeevippayrateupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippayrateupdate, setYeekeevvippayrateupdate] = useState([0, 0, 0, 0, 0, 0]);


  const [Thaimaxfight, setThaimaxfight] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoimaxfight, setHanoimaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosmaxfight, setLaosmaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeemaxfight, setYeekeemaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipmaxfight, setYeekeevipmaxfight] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipmaxfight, setYeekeevvipmaxfight] = useState([0, 0, 0, 0, 0, 0]);


  const [Thaimaxfightupdate, setThaimaxfightupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoimaxfightupdate, setHanoimaxfightupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosmaxfightupdate, setLaosmaxfightupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeemaxfightupdate, setYeekeemaxfightupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate] = useState([0, 0, 0, 0, 0, 0]);


  const [Thaimaxfightlimit, setThaimaxfightlimit] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoimaxfightlimit, setHanoimaxfightlimit] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosmaxfightlimit, setLaosmaxfightlimit] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeemaxfightlimit, setYeekeemaxfightlimit] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipmaxfightlimit, setYeekeevipmaxfightlimit] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipmaxfightlimit, setYeekeevvipmaxfightlimit] = useState([0, 0, 0, 0, 0, 0]);


  const [Thaimaxfightlimitupdate, setThaimaxfightlimitupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate] = useState([0, 0, 0, 0, 0, 0]);



  const [Thailimitbet, setThailimitbet] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoilimitbet, setHanoilimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoslimitbet, setLaoslimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeelimitbet, setYeekeelimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeviplimitbet, setYeekeeviplimitbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevviplimitbet, setYeekeevviplimitbet] = useState([0, 0, 0, 0, 0, 0]);

  const [Thailimitbetupdate, setThailimitbetupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoilimitbetupdate, setHanoilimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laoslimitbetupdate, setLaoslimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeelimitbetupdate, setYeekeelimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaiminbet, setThaiminbet] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoiminbet, setHanoiminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosminbet, setLaosminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeminbet, setYeekeeminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipminbet, setYeekeevipminbet] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipminbet, setYeekeevvipminbet] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaiminbetupdate, setThaiminbetupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoiminbetupdate, setHanoiminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laosminbetupdate, setLaosminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeeminbetupdate, setYeekeeminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevipminbetupdate, setYeekeevipminbetupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvipminbetupdate, setYeekeevvipminbetupdate] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaipermatch, setThaipermatch] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipermatch, setHanoipermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospermatch, setLaospermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepermatch, setYeekeepermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippermatch, setYeekeevippermatch] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippermatch, setYeekeevvippermatch] = useState([0, 0, 0, 0, 0, 0]);

  const [Thaipermatchupdate, setThaipermatchupdate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Hanoipermatchupdate, setHanoipermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Laospermatchupdate, setLaospermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeepermatchupdate, setYeekeepermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevippermatchupdate, setYeekeevippermatchupdate] = useState([0, 0, 0, 0, 0, 0]);
  const [Yeekeevvippermatchupdate, setYeekeevvippermatchupdate] = useState([0, 0, 0, 0, 0, 0]);




  // #endregion

  const handleNext = () => {
    // Logic for going to the next component
    if (currentComponent === 1) setCurrentComponent(2);
    else if (currentComponent === 2) setCurrentComponent(3);
    else if (currentComponent === 3) setCurrentComponent(4);
    // Add more logic for handling additional components
    else if (currentComponent === 4) setCurrentComponent(5);
  };

  const handlePrevious = () => {
    // Logic for going to the previous component
    if (currentComponent === 5) setCurrentComponent(4);
    else if (currentComponent === 4) setCurrentComponent(3);
    else if (currentComponent === 3) setCurrentComponent(2);
    // Add more logic for handling additional components
    else if (currentComponent === 2) setCurrentComponent(1);
  };

  const handleChange = (event, array, setfunction) => {
    let maxvalue = event.target.value
    if (maxvalue > 100) {
      maxvalue = 100
    }
    if (event.target.name === 'all') {
      setfunction([
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue),
        Number(maxvalue)
      ])
    }
    else {
      const updatevalue = Array.from(array)
      updatevalue[Number(event.target.name)] = Number(maxvalue)
      setfunction(updatevalue)
    }
  };

  const handleChangepayrate = (event, payrate, setfunction) => {
    const updatevalue = Array.from(payrate)
    // console.log("test", updatevalue)
    updatevalue[Number(event.target.name)] = Number(event.target.value)
    setfunction(updatevalue)
  };

  const maxbetget = async () => {
    const positionget = {
      agentuser: usernameurl
    }
    await fetch(`${process.env.REACT_APP_API_KEY}/Getmaxbet`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then(res => res.json())
      .then((result) => {

        const thaimaxbet = result.filter(obj => {
          return obj.lotto_type === 'th_gov';
        });
        setThailimitbet(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])

        setThailimitbetupdate(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])


        const laosmaxbet = result.filter(obj => {
          return obj.lotto_type === 'laos_gov';
        });
        setLaoslimitbet(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])
        setLaoslimitbetupdate(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])

        const hanoimaxbet = result.filter(obj => {
          return obj.lotto_type === 'hanoy_PhuQua';
        });
        setHanoilimitbet(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])

        setHanoilimitbetupdate(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])


        const yekeemaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_5';
        });
        setYeekeelimitbet(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])

        setYeekeelimitbetupdate(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])


        const yekeevipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_10';
        });
        setYeekeeviplimitbet(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])

        setYeekeeviplimitbetupdate(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])


        const yekeevvipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_15';
        });
        setYeekeevviplimitbet(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])

        setYeekeevviplimitbetupdate(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])


      })
  }

  const minbetget = async () => {
    const positionget = {
      agentuser: usernameurl
    }
    await fetch(`${process.env.REACT_APP_API_KEY}/Getminbet`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then(res => res.json())
      .then((result) => {

        const thaimaxbet = result.filter(obj => {
          return obj.lotto_type === 'th_gov';
        });
        setThaiminbet(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])

        setThaiminbetupdate(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])


        const laosmaxbet = result.filter(obj => {
          return obj.lotto_type === 'laos_gov';
        });
        setLaosminbet(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])
        setLaosminbetupdate(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])

        const hanoimaxbet = result.filter(obj => {
          return obj.lotto_type === 'hanoy_PhuQua';
        });
        setHanoiminbet(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])

        setHanoiminbetupdate(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])


        const yekeemaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_5';
        });
        setYeekeeminbet(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])

        setYeekeeminbetupdate(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])


        const yekeevipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_10';
        });
        setYeekeevipminbet(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])

        setYeekeevipminbetupdate(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])


        const yekeevvipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_15';
        });
        setYeekeevvipminbet(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])

        setYeekeevvipminbetupdate(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])


      })
  }

  const maxpermatchtget = async () => {
    const positionget = {
      agentuser: usernameurl
    }
    await fetch(`${process.env.REACT_APP_API_KEY}/Getmaxbetpermatch`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then(res => res.json())
      .then((result) => {

        const thaimaxbet = result.filter(obj => {
          return obj.lotto_type === 'th_gov';
        });
        setThaipermatch(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])

        setThaipermatchupdate(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])


        const laosmaxbet = result.filter(obj => {
          return obj.lotto_type === 'laos_gov';
        });
        setLaospermatch(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])
        setLaospermatchupdate(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])

        const hanoimaxbet = result.filter(obj => {
          return obj.lotto_type === 'hanoy_PhuQua';
        });
        setHanoipermatch(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])

        setHanoipermatchupdate(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])


        const yekeemaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_5';
        });
        setYeekeepermatch(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])

        setYeekeepermatchupdate(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])


        const yekeevipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_10';
        });
        setYeekeevippermatch(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])

        setYeekeevippermatchupdate(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])


        const yekeevvipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'Yeekee_15';
        });
        setYeekeevvippermatch(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])

        setYeekeevvippermatchupdate(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])


      })
  }


  const fightlimitget = async () => {

    const positionget = {
      agent: usernameurl,
    };

    await fetch(`${process.env.REACT_APP_API_KEY}/getfightlimit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then((res) => res.json())
      .then((result) => {

        const thaimaxbet = result.filter(obj => {
          return obj.lotto_type === 'th_gov';
        });
        setThaimaxfightlimit(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])

        setThaimaxfightlimitupdate(
          [thaimaxbet[0].first,
          thaimaxbet[0].four_top,
          thaimaxbet[0].four_tod,
          thaimaxbet[0].three_top,
          thaimaxbet[0].three_front,
          thaimaxbet[0].three_bot,
          thaimaxbet[0].three_tod,
          thaimaxbet[0].two_top,
          thaimaxbet[0].two_bot,
          thaimaxbet[0].run_top,
          thaimaxbet[0].run_bot])


        const laosmaxbet = result.filter(obj => {
          return obj.lotto_type === 'laos_gov';
        });
        setLaosmaxfightlimit(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])
        setLaosmaxfightlimitupdate(
          [
            laosmaxbet[0].three_top,
            laosmaxbet[0].three_tod,
            laosmaxbet[0].two_top,
            laosmaxbet[0].two_bot,
            laosmaxbet[0].run_top,
            laosmaxbet[0].run_bot])

        const hanoimaxbet = result.filter(obj => {
          return obj.lotto_type === 'hanoy_PhuQua';
        });
        setHanoimaxfightlimit(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])

        setHanoimaxfightlimitupdate(
          [
            hanoimaxbet[0].three_top,
            hanoimaxbet[0].three_tod,
            hanoimaxbet[0].two_top,
            hanoimaxbet[0].two_bot,
            hanoimaxbet[0].run_top,
            hanoimaxbet[0].run_bot])


        const yekeemaxbet = result.filter(obj => {
          return obj.lotto_type === 'yeekee_5';
        });
        setYeekeemaxfightlimit(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])

        setYeekeemaxfightlimitupdate(
          [
            yekeemaxbet[0].three_top,
            yekeemaxbet[0].three_tod,
            yekeemaxbet[0].two_top,
            yekeemaxbet[0].two_bot,
            yekeemaxbet[0].run_top,
            yekeemaxbet[0].run_bot])


        const yekeevipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'yeekee_10';
        });
        setYeekeevipmaxfightlimit(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])

        setYeekeevipmaxfightlimitupdate(
          [
            yekeevipmaxbet[0].three_top,
            yekeevipmaxbet[0].three_tod,
            yekeevipmaxbet[0].two_top,
            yekeevipmaxbet[0].two_bot,
            yekeevipmaxbet[0].run_top,
            yekeevipmaxbet[0].run_bot])


        const yekeevvipmaxbet = result.filter(obj => {
          return obj.lotto_type === 'yeekee_15';
        });
        setYeekeevvipmaxfightlimit(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])

        setYeekeevvipmaxfightlimitupdate(
          [
            yekeevvipmaxbet[0].three_top,
            yekeevvipmaxbet[0].three_tod,
            yekeevvipmaxbet[0].two_top,
            yekeevvipmaxbet[0].two_bot,
            yekeevvipmaxbet[0].run_top,
            yekeevvipmaxbet[0].run_bot])


      })
  };

  const fightpercentandcommissionget = async () => {

    const jsonid = {
      agent: usernameurl
    }
    fetch(`${process.env.REACT_APP_API_KEY}/getfightpercentandcommission`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonid)
    })
      .then(res => res.json())
      .then(result => {
        const commissionArray = result.filter(obj => "commission" in obj)
        const fightpercentArray = result.filter(obj => "fightpercent" in obj)
        const payrateArray = result.filter(obj => "payrate" in obj)
        return {commissionArray,fightpercentArray,payrateArray}
      })
      .then(({commissionArray,fightpercentArray,payrateArray}) => {
                // #region

                const thaimaxfight = fightpercentArray[0].fightpercent.filter(obj => {
                  return obj.lotto_type === 'th_gov';
                });
        
                setThaimaxfight(
                  [thaimaxfight[0].first,
                  thaimaxfight[0].four_top,
                  thaimaxfight[0].four_tod,
                  thaimaxfight[0].three_top,
                  thaimaxfight[0].three_front,
                  thaimaxfight[0].three_bot,
                  thaimaxfight[0].three_tod,
                  thaimaxfight[0].two_top,
                  thaimaxfight[0].two_bot,
                  thaimaxfight[0].run_top,
                  thaimaxfight[0].run_bot])
        
                setThaimaxfightupdate(
                  [thaimaxfight[0].first,
                  thaimaxfight[0].four_top,
                  thaimaxfight[0].four_tod,
                  thaimaxfight[0].three_top,
                  thaimaxfight[0].three_front,
                  thaimaxfight[0].three_bot,
                  thaimaxfight[0].three_tod,
                  thaimaxfight[0].two_top,
                  thaimaxfight[0].two_bot,
                  thaimaxfight[0].run_top,
                  thaimaxfight[0].run_bot])
        
        
                const thaipayrate = payrateArray[0].payrate.filter(obj => {
                  return obj.lotto_type === 'th_gov';
                });
                setThaipayrate(
                  [thaipayrate[0].first,
                  thaipayrate[0].four_top,
                  thaipayrate[0].four_tod,
                  thaipayrate[0].three_top,
                  thaipayrate[0].three_front,
                  thaipayrate[0].three_bot,
                  thaipayrate[0].three_tod,
                  thaipayrate[0].two_top,
                  thaipayrate[0].two_bot,
                  thaipayrate[0].run_top,
                  thaipayrate[0].run_bot])
                setThaipayrateupdate(
                  [thaipayrate[0].first,
                  thaipayrate[0].four_top,
                  thaipayrate[0].four_tod,
                  thaipayrate[0].three_top,
                  thaipayrate[0].three_front,
                  thaipayrate[0].three_bot,
                  thaipayrate[0].three_tod,
                  thaipayrate[0].two_top,
                  thaipayrate[0].two_bot,
                  thaipayrate[0].run_top,
                  thaipayrate[0].run_bot])
        
                const laosmaxfight = fightpercentArray[0].fightpercent.filter(obj => {
                  return obj.lotto_type === 'laos_gov';
                });
                setLaosmaxfight(
                  [laosmaxfight[0].three_top,
                  laosmaxfight[0].three_tod,
                  laosmaxfight[0].two_top,
                  laosmaxfight[0].two_bot,
                  laosmaxfight[0].run_top,
                  laosmaxfight[0].run_bot])
                setLaosmaxfightupdate(
                  [laosmaxfight[0].three_top,
                  laosmaxfight[0].three_tod,
                  laosmaxfight[0].two_top,
                  laosmaxfight[0].two_bot,
                  laosmaxfight[0].run_top,
                  laosmaxfight[0].run_bot])
        
        
                const Laospayrate = payrateArray[0].payrate.filter(obj => {
                  return obj.lotto_type === 'laos_gov';
                });
                setLaospayrate(
                  [
                    Laospayrate[0].three_top,
                    Laospayrate[0].three_tod,
                    Laospayrate[0].two_top,
                    Laospayrate[0].two_bot,
                    Laospayrate[0].run_top,
                    Laospayrate[0].run_bot])
                setLaospayrateupdate(
                  [
                    Laospayrate[0].three_top,
                    Laospayrate[0].three_tod,
                    Laospayrate[0].two_top,
                    Laospayrate[0].two_bot,
                    Laospayrate[0].run_top,
                    Laospayrate[0].run_bot])
        
                const hanoimaxfight = fightpercentArray[0].fightpercent.filter(obj => {
                  return obj.lotto_type === 'hanoy_PhuQua';
                });
                setHanoimaxfight(
                  [hanoimaxfight[0].three_top,
                  hanoimaxfight[0].three_tod,
                  hanoimaxfight[0].two_top,
                  hanoimaxfight[0].two_bot,
                  hanoimaxfight[0].run_top,
                  hanoimaxfight[0].run_bot])
                setHanoimaxfightupdate(
                  [hanoimaxfight[0].three_top,
                  hanoimaxfight[0].three_tod,
                  hanoimaxfight[0].two_top,
                  hanoimaxfight[0].two_bot,
                  hanoimaxfight[0].run_top,
                  hanoimaxfight[0].run_bot])
        
        
                const hanoipayrate = payrateArray[0].payrate.filter(obj => {
                  return obj.lotto_type === 'hanoy_PhuQua';
                });
                setHanoipayrate(
                  [
                    hanoipayrate[0].three_top,
                    hanoipayrate[0].three_tod,
                    hanoipayrate[0].two_top,
                    hanoipayrate[0].two_bot,
                    hanoipayrate[0].run_top,
                    hanoipayrate[0].run_bot])
                setHanoipayrateupdate(
                  [
                    hanoipayrate[0].three_top,
                    hanoipayrate[0].three_tod,
                    hanoipayrate[0].two_top,
                    hanoipayrate[0].two_bot,
                    hanoipayrate[0].run_top,
                    hanoipayrate[0].run_bot])
        
                const yeekeemaxfight = fightpercentArray[0].fightpercent.filter(obj => {
                  return obj.lotto_type === 'yeekee_5';
                });
                setYeekeemaxfight(
                  [
                    yeekeemaxfight[0].three_top,
                  yeekeemaxfight[0].three_tod,
                  yeekeemaxfight[0].two_top,
                  yeekeemaxfight[0].two_bot,
                  yeekeemaxfight[0].run_top,
                  yeekeemaxfight[0].run_bot])
                setYeekeemaxfightupdate(
                  [
                  yeekeemaxfight[0].three_top,
                  yeekeemaxfight[0].three_tod,
                  yeekeemaxfight[0].two_top,
                  yeekeemaxfight[0].two_bot,
                  yeekeemaxfight[0].run_top,
                  yeekeemaxfight[0].run_bot])
        
        
                const yeekeepayrate = payrateArray[0].payrate.filter(obj => {
                  return obj.lotto_type === 'Yeekee_5';
                });
                setYeekeepayrate(
                  [
                    yeekeepayrate[0].three_top,
                    yeekeepayrate[0].three_tod,
                    yeekeepayrate[0].two_top,
                    yeekeepayrate[0].two_bot,
                    yeekeepayrate[0].run_top,
                    yeekeepayrate[0].run_bot])
                setYeekeepayrateupdate(
                  [
                    yeekeepayrate[0].three_top,
                    yeekeepayrate[0].three_tod,
                    yeekeepayrate[0].two_top,
                    yeekeepayrate[0].two_bot,
                    yeekeepayrate[0].run_top,
                    yeekeepayrate[0].run_bot])
        
                const yeekeevipmaxfight = fightpercentArray[0].fightpercent.filter(obj => {
                  return obj.lotto_type === 'yeekee_10';
                });
                setYeekeevipmaxfight(
                  [yeekeevipmaxfight[0].three_top,
                  yeekeevipmaxfight[0].three_tod,
                  yeekeevipmaxfight[0].two_top,
                  yeekeevipmaxfight[0].two_bot,
                  yeekeevipmaxfight[0].run_top,
                  yeekeevipmaxfight[0].run_bot])
                setYeekeevipmaxfightupdate(
                  [yeekeevipmaxfight[0].three_top,
                  yeekeevipmaxfight[0].three_tod,
                  yeekeevipmaxfight[0].two_top,
                  yeekeevipmaxfight[0].two_bot,
                  yeekeevipmaxfight[0].run_top,
                  yeekeevipmaxfight[0].run_bot])
        
        
                const yeekeevippayrate = payrateArray[0].payrate.filter(obj => {
                  return obj.lotto_type === 'Yeekee_10';
                });
                setYeekeevippayrate(
                  [
                    yeekeevippayrate[0].three_top,
                    yeekeevippayrate[0].three_tod,
                    yeekeevippayrate[0].two_top,
                    yeekeevippayrate[0].two_bot,
                    yeekeevippayrate[0].run_top,
                    yeekeevippayrate[0].run_bot])
                setYeekeevippayrateupdate(
                  [
                    yeekeevippayrate[0].three_top,
                    yeekeevippayrate[0].three_tod,
                    yeekeevippayrate[0].two_top,
                    yeekeevippayrate[0].two_bot,
                    yeekeevippayrate[0].run_top,
                    yeekeevippayrate[0].run_bot])
        
                const yeekeevvipmaxfight = fightpercentArray[0].fightpercent.filter(obj => {
                  return obj.lotto_type === 'yeekee_15';
                });
                setYeekeevvipmaxfight(
                  [yeekeevvipmaxfight[0].three_top,
                  yeekeevvipmaxfight[0].three_tod,
                  yeekeevvipmaxfight[0].two_top,
                  yeekeevvipmaxfight[0].two_bot,
                  yeekeevvipmaxfight[0].run_top,
                  yeekeevvipmaxfight[0].run_bot])
                setYeekeevvipmaxfightupdate(
                  [yeekeevvipmaxfight[0].three_top,
                  yeekeevvipmaxfight[0].three_tod,
                  yeekeevvipmaxfight[0].two_top,
                  yeekeevvipmaxfight[0].two_bot,
                  yeekeevvipmaxfight[0].run_top,
                  yeekeevvipmaxfight[0].run_bot])
        
        
                const yeekeevvippayrate = payrateArray[0].payrate.filter(obj => {
                  return obj.lotto_type === 'Yeekee_15';
                });
                setYeekeevvippayrate(
                  [
                    yeekeevvippayrate[0].three_top,
                    yeekeevvippayrate[0].three_tod,
                    yeekeevvippayrate[0].two_top,
                    yeekeevvippayrate[0].two_bot,
                    yeekeevvippayrate[0].run_top,
                    yeekeevvippayrate[0].run_bot])
                setYeekeevvippayrateupdate(
                  [
                    yeekeevvippayrate[0].three_top,
                    yeekeevvippayrate[0].three_tod,
                    yeekeevvippayrate[0].two_top,
                    yeekeevvippayrate[0].two_bot,
                    yeekeevvippayrate[0].run_top,
                    yeekeevvippayrate[0].run_bot])
        
                const thaicommission = commissionArray[0].commission.filter(obj => {
                  return obj.lotto_type === 'th_gov';
                });
                setThaicommission(
                  [thaicommission[0].first_mcms,
                  thaicommission[0].four_top_mcms,
                  thaicommission[0].four_tod_mcms,
                  thaicommission[0].three_top_mcms,
                  thaicommission[0].three_front_mcms,
                  thaicommission[0].three_bot_mcms,
                  thaicommission[0].three_tod_mcms,
                  thaicommission[0].two_top_mcms,
                  thaicommission[0].two_bot_mcms,
                  thaicommission[0].run_top_mcms,
                  thaicommission[0].run_bot_mcms])
                setThaicommissionupdate(
                  [thaicommission[0].first_mcms,
                  thaicommission[0].four_top_mcms,
                  thaicommission[0].four_tod_mcms,
                  thaicommission[0].three_top_mcms,
                  thaicommission[0].three_front_mcms,
                  thaicommission[0].three_bot_mcms,
                  thaicommission[0].three_tod_mcms,
                  thaicommission[0].two_top_mcms,
                  thaicommission[0].two_bot_mcms,
                  thaicommission[0].run_top_mcms,
                  thaicommission[0].run_bot_mcms])
        
        
                const laoscommission = commissionArray[0].commission.filter(obj => {
                  return obj.lotto_type === 'laos_gov';
                });
                setLaoscommission(
                  [laoscommission[0].three_top_mcms,
                  laoscommission[0].three_tod_mcms,
                  laoscommission[0].two_top_mcms,
                  laoscommission[0].two_bot_mcms,
                  laoscommission[0].run_top_mcms,
                  laoscommission[0].run_bot_mcms])
                setLaoscommissionupdate(
                  [laoscommission[0].three_top_mcms,
                  laoscommission[0].three_tod_mcms,
                  laoscommission[0].two_top_mcms,
                  laoscommission[0].two_bot_mcms,
                  laoscommission[0].run_top_mcms,
                  laoscommission[0].run_bot_mcms])
        
        
                const hanoicommission = commissionArray[0].commission.filter(obj => {
                  return obj.lotto_type === 'hanoy_PhuQua';
                });
                setHanoicommission(
                  [hanoicommission[0].three_top_mcms,
                  hanoicommission[0].three_tod_mcms,
                  hanoicommission[0].two_top_mcms,
                  hanoicommission[0].two_bot_mcms,
                  hanoicommission[0].run_top_mcms,
                  hanoicommission[0].run_bot_mcms])
                setHanoicommissionupdate(
                  [hanoicommission[0].three_top_mcms,
                  hanoicommission[0].three_tod_mcms,
                  hanoicommission[0].two_top_mcms,
                  hanoicommission[0].two_bot_mcms,
                  hanoicommission[0].run_top_mcms,
                  hanoicommission[0].run_bot_mcms])
        
        
                const yeekeecommission = commissionArray[0].commission.filter(obj => {
                  return obj.lotto_type === 'Yeekee_5';
                });
                setYeekeecommission(
                  [yeekeecommission[0].three_top_mcms,
                  yeekeecommission[0].three_tod_mcms,
                  yeekeecommission[0].two_top_mcms,
                  yeekeecommission[0].two_bot_mcms,
                  yeekeecommission[0].run_top_mcms,
                  yeekeecommission[0].run_bot_mcms])
                setYeekeecommissionupdate(
                  [yeekeecommission[0].three_top_mcms,
                  yeekeecommission[0].three_tod_mcms,
                  yeekeecommission[0].two_top_mcms,
                  yeekeecommission[0].two_bot_mcms,
                  yeekeecommission[0].run_top_mcms,
                  yeekeecommission[0].run_bot_mcms])
        
        
                const yeekeevipcommission = commissionArray[0].commission.filter(obj => {
                  return obj.lotto_type === 'Yeekee_10';
                });
                setYeekeevipcommission(
                  [yeekeevipcommission[0].three_top_mcms,
                  yeekeevipcommission[0].three_tod_mcms,
                  yeekeevipcommission[0].two_top_mcms,
                  yeekeevipcommission[0].two_bot_mcms,
                  yeekeevipcommission[0].run_top_mcms,
                  yeekeevipcommission[0].run_bot_mcms])
                setYeekeevipcommissionupdate(
                  [yeekeevipcommission[0].three_top_mcms,
                  yeekeevipcommission[0].three_tod_mcms,
                  yeekeevipcommission[0].two_top_mcms,
                  yeekeevipcommission[0].two_bot_mcms,
                  yeekeevipcommission[0].run_top_mcms,
                  yeekeevipcommission[0].run_bot_mcms])
        
        
                const yeekeevvipcommission = commissionArray[0].commission.filter(obj => {
                  return obj.lotto_type === 'Yeekee_15';
                });
                setYeekeevvipcommission(
                  [yeekeevvipcommission[0].three_top_mcms,
                  yeekeevvipcommission[0].three_tod_mcms,
                  yeekeevvipcommission[0].two_top_mcms,
                  yeekeevvipcommission[0].two_bot_mcms,
                  yeekeevvipcommission[0].run_top_mcms,
                  yeekeevvipcommission[0].run_bot_mcms])
        
                setYeekeevvipcommissionupdate(
                  [yeekeevvipcommission[0].three_top_mcms,
                  yeekeevvipcommission[0].three_tod_mcms,
                  yeekeevvipcommission[0].two_top_mcms,
                  yeekeevvipcommission[0].two_bot_mcms,
                  yeekeevvipcommission[0].run_top_mcms,
                  yeekeevvipcommission[0].run_bot_mcms])
        
                // #endregion
        
      })

  };



  useEffect(() => {
    Setusername(usernameurl)
    const jsonid = {
      agent: usernameurl
    }
    // const maxbetget = {
    //   agentuser: usernameurl
    // }
    fightpercentandcommissionget()
    maxbetget()
    fightlimitget()
    minbetget()
    maxpermatchtget()

  }, [reload]);

  const handleBack = () => {
    // Go back one page in the browser's history
    window.history.back();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleUpdate = () => {
    const shouldUpdate = Swal.fire({
      title: 'แน่ใจหรือไม่?',
      text: `คุณจะบันทึกข้อมูลของสมาชิก ใช่หรือไม่?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    });

    shouldUpdate.then((result) => {
      if (result.isConfirmed) {
        if (
          checkrewardandcommission(Thaipayrateupdate[0], Thaicommissionupdate[0], 1000000, 'หวยรัฐบาลไทย รางวัลที่หนึ่ง') &&
          checkrewardandcommission(Thaipayrateupdate[1], Thaicommissionupdate[1], 10000, 'หวยรัฐบาลไทย สี่ตัวบน') &&
          checkrewardandcommission(Thaipayrateupdate[2], Thaicommissionupdate[2], 10000, 'หวยรัฐบาลไทย สี่ตัวโต๊ด') &&
          checkrewardandcommission(Thaipayrateupdate[3], Thaicommissionupdate[3], 1000, 'หวยรัฐบาลไทย สามตัวบน') &&
          checkrewardandcommission(Thaipayrateupdate[4], Thaicommissionupdate[4], 500, 'หวยรัฐบาลไทย สามตัวหน้า') &&
          checkrewardandcommission(Thaipayrateupdate[5], Thaicommissionupdate[5], 500, 'หวยรัฐบาลไทย สามตัวหลัง') &&
          checkrewardandcommission(Thaipayrateupdate[6], Thaicommissionupdate[6], 166, 'หวยรัฐบาลไทย สามตัวโต๊ด') &&
          checkrewardandcommission(Thaipayrateupdate[7], Thaicommissionupdate[7], 100, 'หวยรัฐบาลไทย สองตัวบน') &&
          checkrewardandcommission(Thaipayrateupdate[8], Thaicommissionupdate[8], 100, 'หวยรัฐบาลไทย สองตัวล่าง') &&
          checkrewardandcommission(Thaipayrateupdate[9], Thaicommissionupdate[9], 10, 'หวยรัฐบาลไทย วิ่งบน') &&
          checkrewardandcommission(Thaipayrateupdate[10], Thaicommissionupdate[10], 10, 'หวยรัฐบาลไทย วิ่งล่าง') &&

          checkrewardandcommission(Hanoipayrateupdate[0], Hanoicommissionupdate[0], 1000, 'หวยฮานอย สามตัวบน') &&
          checkrewardandcommission(Hanoipayrateupdate[1], Hanoicommissionupdate[1], 166, 'หวยฮานอย สามตัวโต๊ด') &&
          checkrewardandcommission(Hanoipayrateupdate[2], Hanoicommissionupdate[2], 100, 'หวยฮานอย สองตัวบน') &&
          checkrewardandcommission(Hanoipayrateupdate[3], Hanoicommissionupdate[3], 100, 'หวยฮานอย สองตัวล่าง') &&
          checkrewardandcommission(Hanoipayrateupdate[4], Hanoicommissionupdate[4], 10, 'หวยฮานอย วิ่งบน') &&
          checkrewardandcommission(Hanoipayrateupdate[5], Hanoicommissionupdate[5], 10, 'หวยฮานอย วิ่งล่าง') &&

          checkrewardandcommission(Laospayrateupdate[0], Laoscommissionupdate[0], 1000, 'หวยลาว สามตัวบน') &&
          checkrewardandcommission(Laospayrateupdate[1], Laoscommissionupdate[1], 166, 'หวยลาว สามตัวโต๊ด') &&
          checkrewardandcommission(Laospayrateupdate[2], Laoscommissionupdate[2], 100, 'หวยลาว สองตัวบน') &&
          checkrewardandcommission(Laospayrateupdate[3], Laoscommissionupdate[3], 100, 'หวยลาว สองตัวล่าง') &&
          checkrewardandcommission(Laospayrateupdate[4], Laoscommissionupdate[4], 10, 'หวยลาว วิ่งบน') &&
          checkrewardandcommission(Laospayrateupdate[5], Laoscommissionupdate[5], 10, 'หวยลาว วิ่งล่าง') &&

          checkrewardandcommission(Yeekeepayrateupdate[0], Yeekeecommissionupdate[0], 1000, 'ยี่กี่ สามตัวบน') &&
          checkrewardandcommission(Yeekeepayrateupdate[1], Yeekeecommissionupdate[1], 166, 'ยี่กี่ สามตัวโต๊ด') &&
          checkrewardandcommission(Yeekeepayrateupdate[2], Yeekeecommissionupdate[2], 100, 'ยี่กี่ สองตัวบน') &&
          checkrewardandcommission(Yeekeepayrateupdate[3], Yeekeecommissionupdate[3], 100, 'ยี่กี่ สองตัวล่าง') &&
          checkrewardandcommission(Yeekeepayrateupdate[4], Yeekeecommissionupdate[4], 10, 'ยี่กี่ วิ่งบน') &&
          checkrewardandcommission(Yeekeepayrateupdate[5], Yeekeecommissionupdate[5], 10, 'ยี่กี่ วิ่งล่าง') &&

          checkrewardandcommission(Yeekeevippayrateupdate[0], Yeekeevipcommissionupdate[0], 1000, 'ยี่กี่ vip สามตัวบน') &&
          checkrewardandcommission(Yeekeevippayrateupdate[1], Yeekeevipcommissionupdate[1], 166, 'ยี่กี่ vip สามตัวโต๊ด') &&
          checkrewardandcommission(Yeekeevippayrateupdate[2], Yeekeevipcommissionupdate[2], 100, 'ยี่กี่ vip สองตัวบน') &&
          checkrewardandcommission(Yeekeevippayrateupdate[3], Yeekeevipcommissionupdate[3], 100, 'ยี่กี่ vip สองตัวล่าง') &&
          checkrewardandcommission(Yeekeevippayrateupdate[4], Yeekeevipcommissionupdate[4], 10, 'ยี่กี่ vip วิ่งบน') &&
          checkrewardandcommission(Yeekeevippayrateupdate[5], Yeekeevipcommissionupdate[5], 10, 'ยี่กี่ vip วิ่งล่าง') &&

          checkrewardandcommission(Yeekeevvippayrateupdate[0], Yeekeevvipcommissionupdate[0], 1000, 'ยี่กี่ vvip สามตัวบน') &&
          checkrewardandcommission(Yeekeevvippayrateupdate[1], Yeekeevvipcommissionupdate[1], 166, 'ยี่กี่ vvip สามตัวโต๊ด') &&
          checkrewardandcommission(Yeekeevvippayrateupdate[2], Yeekeevvipcommissionupdate[2], 100, 'ยี่กี่ vvip สองตัวบน') &&
          checkrewardandcommission(Yeekeevvippayrateupdate[3], Yeekeevvipcommissionupdate[3], 100, 'ยี่กี่ vvip สองตัวล่าง') &&
          checkrewardandcommission(Yeekeevvippayrateupdate[4], Yeekeevvipcommissionupdate[4], 10, 'ยี่กี่ vvip วิ่งบน') &&
          checkrewardandcommission(Yeekeevvippayrateupdate[5], Yeekeevvipcommissionupdate[5], 10, 'ยี่กี่ vvip วิ่งล่าง')

        )
        //#endregion

        {
          const jsonid = {
            username: username,

            th_commission: Thaicommissionupdate,
            hanoi_commission: Hanoicommissionupdate,
            laos_commission: Laoscommissionupdate,
            yeekee5_commission: Yeekeecommissionupdate,
            yeekee10_commission: Yeekeevipcommissionupdate,
            yeekee15_commission: Yeekeevvipcommissionupdate,

            th_maxpercent: Thaimaxfightupdate,
            hanoi_maxpercent: Hanoimaxfightupdate,
            laos_maxpercent: Laosmaxfightupdate,
            yeekee5_maxpercent: Yeekeemaxfightupdate,
            yeekee10_maxpercent: Yeekeevipmaxfightupdate,
            yeekee15_maxpercent: Yeekeevvipmaxfightupdate,

            th_payrate: Thaipayrateupdate,
            hanoi_payrate: Hanoipayrateupdate,
            laos_payrate: Laospayrateupdate,
            yeekee5_payrate: Yeekeepayrateupdate,
            yeekee10_payrate: Yeekeevippayrateupdate,
            yeekee15_payrate: Yeekeevvippayrateupdate,

            th_maxbet: Thailimitbetupdate,
            hanoi_maxbet: Hanoilimitbetupdate,
            laos_maxbet: Laoslimitbetupdate,
            yeekee5_maxbet: Yeekeelimitbetupdate,
            yeekee10_maxbet: Yeekeeviplimitbetupdate,
            yeekee15_maxbet: Yeekeevviplimitbetupdate,

            th_minbet: Thaiminbetupdate,
            hanoi_minbet: Hanoiminbetupdate,
            laos_minbet: Laosminbetupdate,
            yeekee5_minbet: Yeekeeminbetupdate,
            yeekee10_minbet: Yeekeevipminbetupdate,
            yeekee15_minbet: Yeekeevvipminbetupdate,

            th_maxbetpermatch: Thaipermatchupdate,
            hanoi_maxbetpermatch: Hanoipermatchupdate,
            laos_maxbetpermatch: Laospermatchupdate,
            yeekee5_maxbetpermatch: Yeekeepermatchupdate,
            yeekee10_maxbetpermatch: Yeekeevippermatchupdate,
            yeekee15_maxbetpermatch: Yeekeevvippermatchupdate,


            th_fightlimit: Thaimaxfightlimitupdate,
            hanoi_fightlimit: Hanoimaxfightlimitupdate,
            laos_fightlimit: Laosmaxfightlimitupdate,
            yeekee5_fightlimit: Yeekeemaxfightlimitupdate,
            yeekee10_fightlimit: Yeekeevipmaxfightlimitupdate,
            yeekee15_fightlimit: Yeekeevvipmaxfightlimitupdate,

          }
          fetch(`${process.env.REACT_APP_API_KEY}/updateagent`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonid),
          })
            .then((res) => res.json())
            .then(() => {
              toast.success('บันทึกข้อมูลสำเร็จ', { autoClose: 2000 });
              setReload(reload + 1);
            })
            .catch((error) => {
              toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล', { autoClose: 2000 });
              console.error(error);
            });
        }
      } else {
        console.log('ยกเลิกการบันทึก');
      }
    });
  };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const checkrewardandcommission = (reward, commission, maxreward, type) => {
    if (reward + ((commission / 100) * maxreward) <= maxreward) {
      // console.log("type:",type,reward ,"+",((commission/100)*maxreward),"percent", reward + ((commission/100)*maxreward))
      return true
    } else {
      Swal.fire({
        title: 'ตรวจสอบข้อมูลให้ถูกต้อง',
        text: `รางวัลรวมกับค่าคอมมิชชั่นต้องน้อยกว่า 100 | หวย ${type}`,
        icon: 'error',
      })
      console.log("type:", type, "commission", commission, reward, "+", (commission / 100), "*", maxreward, "percent", reward + ((commission / 100) * maxreward))

      return false
    }

  }

  const pos = localStorage.getItem('position')

  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos} />
        <div className="inner">
          <div className="content">
            <Header title={`${isDownline === 'true' ? `Downline Edit => ${username} (downline of ${downlineof})` : `Agent => Agent Edit => ${username}`}`} />
            <div className='flex flex-row space-x-2 mb-1'>
              {currentComponent < 4 && <button onClick={handleNext} className="w-[100%] h-[2rem] bg-purple-600 mr-1 hover:bg-purple-700 text-white text-[10px] font-bold py-1 px-2 mr-1 rounded">
                next
              </button>}
              {currentComponent !== 1 && <button onClick={handlePrevious} className="w-[100%] h-[2rem] bg-purple-600 mr-1 hover:bg-purple-700 text-white text-[10px] font-bold py-1 px-2 mr-1 rounded">
                prevous
              </button>}
              <button onClick={handleUpdate} className="w-[100%] h-[2rem] bg-green-600 mr-1 hover:bg-green-700 text-white text-[10px] font-bold py-1 px-2 mr-1 rounded">
                บันทึก
              </button>
              <button onClick={handleBack} className="w-[100%] h-[2rem] bg-slate-400 hover:bg-slate-500 text-white text-[10px] font-bold p py-1 px-2 rounded">
                ยกเลิก
              </button>
            </div>


            <div className='md:w-auto bg-amber-100 p-3 h-auto flex flex-row border-[1px] p-1 border-slate-300 rounded-lg'>
              <div className='flex flex-row space-x-5'>
                <div className='flex flex-col'>
                  <span className='text-[15px] font-bold'>username</span>
                  <input
                    type="text"
                    value={username}
                    disabled
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                  />
                </div>
                <div className='flex flex-col'>
                  <span className='text-[15px] font-bold'>Position</span>
                  <input
                    type="text"
                    value={positionUrl}
                    disabled
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                  />
                </div>
                <div className='flex flex-col'>
                  <span className='text-[15px] font-bold'>Upline user</span>
                  <input
                    value={uplineusernameUrl}
                    type="text"
                    disabled
                    className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                  />
                </div>

              </div>
            </div>


            {currentComponent === 1 &&
              <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                  <div>
                    <span className='text-white'>ค่าคอมมิชชั่น และ รางวัล หวยไทย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[0]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[1]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[2]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[3]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[4]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[5]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[6]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={6} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[7]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={7} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[8]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={8} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[9]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={9} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipayrate[10]}
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={10} onChange={(e) => handleChangepayrate(e, Thaipayrateupdate, setThaipayrateupdate)}
                                  value={Thaipayrateupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[0]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[1]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[2]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[3]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[4]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[5]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[6]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={6} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[7]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={7} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[8]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={8} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[9]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={9} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaicommission[10]}%
                                </span>
                                <input className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={10} onChange={(e) => handleChange(e, Thaicommissionupdate, setThaicommissionupdate)}
                                  value={Thaicommissionupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                  <div>
                    <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล หวยฮานอย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipayrate[0]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                  value={Hanoipayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipayrate[1]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                  value={Hanoipayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipayrate[2]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                  value={Hanoipayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipayrate[3]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                  value={Hanoipayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipayrate[4]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                  value={Hanoipayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipayrate[5]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Hanoipayrateupdate, setHanoipayrateupdate)}
                                  value={Hanoipayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoicommission[0]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Hanoicommissionupdate, setHanoicommissionupdate)}
                                  value={Hanoicommissionupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoicommission[1]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Hanoicommissionupdate, setHanoicommissionupdate)}
                                  value={Hanoicommissionupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoicommission[2]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Hanoicommissionupdate, setHanoicommissionupdate)}
                                  value={Hanoicommissionupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoicommission[3]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Hanoicommissionupdate, setHanoicommissionupdate)}
                                  value={Hanoicommissionupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoicommission[4]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Hanoicommissionupdate, setHanoicommissionupdate)}
                                  value={Hanoicommissionupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoicommission[5]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Hanoicommissionupdate, setHanoicommissionupdate)}
                                  value={Hanoicommissionupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                  <div>
                    <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล หวยลาว</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospayrate[0]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                  value={Laospayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospayrate[1]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                  value={Laospayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospayrate[2]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                  value={Laospayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospayrate[3]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                  value={Laospayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospayrate[4]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                  value={Laospayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospayrate[5]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Laospayrateupdate, setLaospayrateupdate)}
                                  value={Laospayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoscommission[0]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Laoscommissionupdate, setLaoscommissionupdate)}
                                  value={Laoscommissionupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoscommission[1]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Laoscommissionupdate, setLaoscommissionupdate)}
                                  value={Laoscommissionupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoscommission[2]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Laoscommissionupdate, setLaoscommissionupdate)}
                                  value={Laoscommissionupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoscommission[3]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Laoscommissionupdate, setLaoscommissionupdate)}
                                  value={Laoscommissionupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoscommission[4]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Laoscommissionupdate, setLaoscommissionupdate)}
                                  value={Laoscommissionupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoscommission[5]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Laoscommissionupdate, setLaoscommissionupdate)}
                                  value={Laoscommissionupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                  <div>
                    <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล จับยี่กี</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepayrate[0]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                  value={Yeekeepayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepayrate[1]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                  value={Yeekeepayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepayrate[2]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                  value={Yeekeepayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepayrate[3]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                  value={Yeekeepayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepayrate[4]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                  value={Yeekeepayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepayrate[5]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Yeekeepayrateupdate, setYeekeepayrateupdate)}
                                  value={Yeekeepayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeecommission[0]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Yeekeecommissionupdate, setYeekeecommissionupdate)}
                                  value={Yeekeecommissionupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeecommission[1]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Yeekeecommissionupdate, setYeekeecommissionupdate)}
                                  value={Yeekeecommissionupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeecommission[2]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Yeekeecommissionupdate, setYeekeecommissionupdate)}
                                  value={Yeekeecommissionupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeecommission[3]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Yeekeecommissionupdate, setYeekeecommissionupdate)}
                                  value={Yeekeecommissionupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeecommission[4]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Yeekeecommissionupdate, setYeekeecommissionupdate)}
                                  value={Yeekeecommissionupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeecommission[5]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Yeekeecommissionupdate, setYeekeecommissionupdate)}
                                  value={Yeekeecommissionupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                  <div>
                    <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล จับยี่กีVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippayrate[0]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                  value={Yeekeevippayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippayrate[1]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                  value={Yeekeevippayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippayrate[2]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                  value={Yeekeevippayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippayrate[3]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                  value={Yeekeevippayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippayrate[4]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                  value={Yeekeevippayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippayrate[5]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Yeekeevippayrateupdate, setYeekeevippayrateupdate)}
                                  value={Yeekeevippayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipcommission[0]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Yeekeevipcommissionupdate, setYeekeevipcommissionupdate)}
                                  value={Yeekeevipcommissionupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipcommission[1]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Yeekeevipcommissionupdate, setYeekeevipcommissionupdate)}
                                  value={Yeekeevipcommissionupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipcommission[2]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Yeekeevipcommissionupdate, setYeekeevipcommissionupdate)}
                                  value={Yeekeevipcommissionupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipcommission[3]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Yeekeevipcommissionupdate, setYeekeevipcommissionupdate)}
                                  value={Yeekeevipcommissionupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipcommission[4]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Yeekeevipcommissionupdate, setYeekeevipcommissionupdate)}
                                  value={Yeekeevipcommissionupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipcommission[5]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Yeekeevipcommissionupdate, setYeekeevipcommissionupdate)}
                                  value={Yeekeevipcommissionupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-800 hover:bg-orange-90'>
                  <div>
                    <span className='text-white font-bold'>ค่าคอมมิชชั่น และ รางวัล จับยี่กี VVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Rewards</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippayrate[0]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                  value={Yeekeevvippayrateupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippayrate[1]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                  value={Yeekeevvippayrateupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippayrate[2]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                  value={Yeekeevvippayrateupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippayrate[3]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                  value={Yeekeevvippayrateupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippayrate[4]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                  value={Yeekeevvippayrateupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippayrate[5]}
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChangepayrate(e, Yeekeevvippayrateupdate, setYeekeevvippayrateupdate)}
                                  value={Yeekeevvippayrateupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}></input>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipcommission[0]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={0} onChange={(e) => handleChange(e, Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate)}
                                  value={Yeekeevvipcommissionupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipcommission[1]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={1} onChange={(e) => handleChange(e, Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate)}
                                  value={Yeekeevvipcommissionupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipcommission[2]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={2} onChange={(e) => handleChange(e, Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate)}
                                  value={Yeekeevvipcommissionupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipcommission[3]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={3} onChange={(e) => handleChange(e, Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate)}
                                  value={Yeekeevvipcommissionupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipcommission[4]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={4} onChange={(e) => handleChange(e, Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate)}
                                  value={Yeekeevvipcommissionupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipcommission[5]}%
                                </span>
                                <input type="number" min="0" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center' name={5} onChange={(e) => handleChange(e, Yeekeevvipcommissionupdate, setYeekeevvipcommissionupdate)}
                                  value={Yeekeevvipcommissionupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>}
            {currentComponent === 2 &&
              <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                  <div>
                    <span className='text-white font-bold'>Bet Limit หวยไทย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          {/* maxbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[6]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={6} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[7]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={7} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[8]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={8} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[9]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={9} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thailimitbet[10]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={10} onChange={(e) => handleChangepayrate(e, Thailimitbetupdate, setThailimitbetupdate)}
                                  value={Thailimitbetupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* minbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[6]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={6} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[7]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={7} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[8]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={8} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[9]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={9} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaiminbet[10]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={10} onChange={(e) => handleChangepayrate(e, Thaiminbetupdate, setThaiminbetupdate)}
                                  value={Thaiminbetupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* maxpermatch */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[6]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={6} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[6]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[7]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={7} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[7]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[8]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={8} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[8]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[9]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={9} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[9]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaipermatch[10]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={10} onChange={(e) => handleChangepayrate(e, Thaipermatchupdate, setThaipermatchupdate)}
                                  value={Thaipermatchupdate[10]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                  <div>
                    <span className='text-white font-bold'>Bet Limit หวยฮานอย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          {/* maxbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoilimitbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                  value={Hanoilimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoilimitbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                  value={Hanoilimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoilimitbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                  value={Hanoilimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoilimitbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                  value={Hanoilimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoilimitbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                  value={Hanoilimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoilimitbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Hanoilimitbetupdate, setHanoilimitbetupdate)}
                                  value={Hanoilimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* minbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoiminbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                  value={Hanoiminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoiminbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                  value={Hanoiminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoiminbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                  value={Hanoiminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoiminbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                  value={Hanoiminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoiminbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                  value={Hanoiminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoiminbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Hanoiminbetupdate, setHanoiminbetupdate)}
                                  value={Hanoiminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* maxpermatch */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipermatch[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                  value={Hanoipermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipermatch[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                  value={Hanoipermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipermatch[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                  value={Hanoipermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipermatch[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                  value={Hanoipermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipermatch[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                  value={Hanoipermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoipermatch[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Hanoipermatchupdate, setHanoipermatchupdate)}
                                  value={Hanoipermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                  <div>
                    <span className='text-white font-bold'>Bet Limit หวยลาว</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoslimitbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                  value={Laoslimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoslimitbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                  value={Laoslimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoslimitbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                  value={Laoslimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoslimitbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                  value={Laoslimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoslimitbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                  value={Laoslimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laoslimitbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Laoslimitbetupdate, setLaoslimitbetupdate)}
                                  value={Laoslimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* minbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosminbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                  value={Laosminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosminbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                  value={Laosminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosminbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                  value={Laosminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosminbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                  value={Laosminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosminbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                  value={Laosminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosminbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Laosminbetupdate, setLaosminbetupdate)}
                                  value={Laosminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* maxpermatch */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospermatch[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                  value={Laospermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospermatch[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                  value={Laospermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospermatch[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                  value={Laospermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospermatch[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                  value={Laospermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospermatch[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                  value={Laospermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laospermatch[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Laospermatchupdate, setLaospermatchupdate)}
                                  value={Laospermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                  <div>
                    <span className='text-white font-bold'>Bet Limit จับยี่กี</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                            <div className='flex px-3 py-1 flex-col'>
                              <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                {Yeekeelimitbet[0]}
                              </span>
                              <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                name={0} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                value={Yeekeelimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                              </input>
                            </div>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeelimitbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                  value={Yeekeelimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeelimitbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                  value={Yeekeelimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeelimitbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                  value={Yeekeelimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeelimitbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                  value={Yeekeelimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeelimitbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeelimitbetupdate, setYeekeelimitbetupdate)}
                                  value={Yeekeelimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* minbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeminbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                  value={Yeekeeminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeminbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                  value={Yeekeeminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeminbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                  value={Yeekeeminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeminbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                  value={Yeekeeminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeminbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                  value={Yeekeeminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeminbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeeminbetupdate, setYeekeeminbetupdate)}
                                  value={Yeekeeminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* maxpermatch */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepermatch[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                  value={Yeekeepermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepermatch[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                  value={Yeekeepermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepermatch[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                  value={Yeekeepermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepermatch[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                  value={Yeekeepermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepermatch[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                  value={Yeekeepermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeepermatch[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeepermatchupdate, setYeekeepermatchupdate)}
                                  value={Yeekeepermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                  <div>
                    <span className='text-white font-bold'>Bet Limit จับยี่กีVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeviplimitbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                  value={Yeekeeviplimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeviplimitbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                  value={Yeekeeviplimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeviplimitbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                  value={Yeekeeviplimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeviplimitbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                  value={Yeekeeviplimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeviplimitbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                  value={Yeekeeviplimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeeviplimitbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeeviplimitbetupdate, setYeekeeviplimitbetupdate)}
                                  value={Yeekeeviplimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* minbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipminbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                  value={Yeekeevipminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipminbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                  value={Yeekeevipminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipminbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                  value={Yeekeevipminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipminbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                  value={Yeekeevipminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipminbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                  value={Yeekeevipminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipminbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeevipminbetupdate, setYeekeevipminbetupdate)}
                                  value={Yeekeevipminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* maxpermatch */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippermatch[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                  value={Yeekeevippermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippermatch[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                  value={Yeekeevippermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippermatch[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                  value={Yeekeevippermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippermatch[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                  value={Yeekeevippermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippermatch[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                  value={Yeekeevippermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevippermatch[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeevippermatchupdate, setYeekeevippermatchupdate)}
                                  value={Yeekeevippermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-800 hover:bg-orange-90'>
                  <div>
                    <span className='text-white font-bold'>Bet Limit จับยี่กี VVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevviplimitbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                  value={Yeekeevviplimitbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevviplimitbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                  value={Yeekeevviplimitbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevviplimitbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                  value={Yeekeevviplimitbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevviplimitbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                  value={Yeekeevviplimitbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevviplimitbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                  value={Yeekeevviplimitbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevviplimitbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeevviplimitbetupdate, setYeekeevviplimitbetupdate)}
                                  value={Yeekeevviplimitbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* minbet */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Min bet</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipminbet[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                  value={Yeekeevvipminbetupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipminbet[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                  value={Yeekeevvipminbetupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipminbet[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                  value={Yeekeevvipminbetupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipminbet[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                  value={Yeekeevvipminbetupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipminbet[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                  value={Yeekeevvipminbetupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipminbet[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeevvipminbetupdate, setYeekeevvipminbetupdate)}
                                  value={Yeekeevvipminbetupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                          {/* maxpermatch */}
                          <tr className='bg-sky-100'>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Max bet/match</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippermatch[0]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={0} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                  value={Yeekeevvippermatchupdate[0]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippermatch[1]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={1} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                  value={Yeekeevvippermatchupdate[1]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippermatch[2]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={2} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                  value={Yeekeevvippermatchupdate[2]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippermatch[3]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={3} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                  value={Yeekeevvippermatchupdate[3]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippermatch[4]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={4} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                  value={Yeekeevvippermatchupdate[4]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvippermatch[5]}
                                </span>
                                <input type="number" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  name={5} onChange={(e) => handleChangepayrate(e, Yeekeevvippermatchupdate, setYeekeevvippermatchupdate)}
                                  value={Yeekeevvippermatchupdate[5]} placeholder="ใส่รางวัล" onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>}
            {currentComponent === 3 &&
              <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                  <div>
                    <span className='text-white'>เปอร์เซ็นสู้สูงสุด หวยไทย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[0]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[1]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[2]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[3]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[4]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[5]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[6]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[6]}
                                  name={6}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[7]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[7]}
                                  name={7}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[8]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[8]}
                                  name={8}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[9]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[9]}
                                  name={9}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfight[10]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightupdate[10]}
                                  name={10}
                                  onChange={(e) => handleChange(e, Thaimaxfightupdate, setThaimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                  <div>
                    <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด หวยฮานอย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfight[0]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChange(e, Hanoimaxfightupdate, setHanoimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfight[1]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChange(e, Hanoimaxfightupdate, setHanoimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfight[2]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChange(e, Hanoimaxfightupdate, setHanoimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfight[3]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChange(e, Hanoimaxfightupdate, setHanoimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfight[4]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChange(e, Hanoimaxfightupdate, setHanoimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfight[5]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChange(e, Hanoimaxfightupdate, setHanoimaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                  <div>
                    <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด หวยลาว</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfight[0]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChange(e, Laosmaxfightupdate, setLaosmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfight[1]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChange(e, Laosmaxfightupdate, setLaosmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfight[2]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChange(e, Laosmaxfightupdate, setLaosmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfight[3]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChange(e, Laosmaxfightupdate, setLaosmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfight[4]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChange(e, Laosmaxfightupdate, setLaosmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfight[5]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChange(e, Laosmaxfightupdate, setLaosmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                  <div>
                    <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด จับยี่กี</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfight[0]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChange(e, Yeekeemaxfightupdate, setYeekeemaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfight[1]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChange(e, Yeekeemaxfightupdate, setYeekeemaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfight[2]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChange(e, Yeekeemaxfightupdate, setYeekeemaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfight[3]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChange(e, Yeekeemaxfightupdate, setYeekeemaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfight[4]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChange(e, Yeekeemaxfightupdate, setYeekeemaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfight[5]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChange(e, Yeekeemaxfightupdate, setYeekeemaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                  <div>
                    <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด จับยี่กีVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Comm</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfight[0]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChange(e, Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfight[1]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChange(e, Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfight[2]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChange(e, Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfight[3]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChange(e, Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfight[4]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChange(e, Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfight[5]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChange(e, Yeekeevipmaxfightupdate, setYeekeevipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-800 hover:bg-orange-90'>
                  <div>
                    <span className='text-white font-bold'>เปอร์เซ็นสู้สูงสุด จับยี่กี VVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">เปอร์เซ็นสู้สูงสุด</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfight[0]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChange(e, Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfight[1]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChange(e, Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfight[2]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChange(e, Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfight[3]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChange(e, Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfight[4]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChange(e, Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfight[5]}%
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChange(e, Yeekeevvipmaxfightupdate, setYeekeevvipmaxfightupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>}

            {currentComponent === 4 &&
              <div className='md:w-full h-auto flex p-2 flex-col space-y-2 bg-slate-200 rounded-lg'>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-400 hover:bg-blue-800'>
                  <div>
                    <span className='text-white'>Fight Limit หวยไทย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '9%' }} className="bg-amber-200 text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">รางวัลที่ 1</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">4 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหน้า</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวหลัง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[0]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[1]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[2]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[3]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[4]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[5]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[6]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[6]}
                                  name={6}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[7]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[7]}
                                  name={7}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[8]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[8]}
                                  name={8}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[9]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[9]}
                                  name={9}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Thaimaxfightlimit[10]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Thaimaxfightlimitupdate[10]}
                                  name={10}
                                  onChange={(e) => handleChangepayrate(e, Thaimaxfightlimitupdate, setThaimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-red-500 hover:bg-red-700'>
                  <div>
                    <span className='text-white font-bold'>Fight Limit หวยฮานอย</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfightlimit[0]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightlimitupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChangepayrate(e, Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfightlimit[1]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightlimitupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChangepayrate(e, Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfightlimit[2]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightlimitupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChangepayrate(e, Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfightlimit[3]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightlimitupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChangepayrate(e, Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfightlimit[4]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightlimitupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChangepayrate(e, Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Hanoimaxfightlimit[5]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Hanoimaxfightlimitupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChangepayrate(e, Hanoimaxfightlimitupdate, setHanoimaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-blue-700 hover:bg-blue-800'>
                  <div>
                    <span className='text-white font-bold'>Fight Limit หวยลาว</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfightlimit[0]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightlimitupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChangepayrate(e, Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfightlimit[1]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightlimitupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChangepayrate(e, Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfightlimit[2]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightlimitupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChangepayrate(e, Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfightlimit[3]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightlimitupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChangepayrate(e, Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfightlimit[4]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightlimitupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChangepayrate(e, Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Laosmaxfightlimit[5]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Laosmaxfightlimitupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChangepayrate(e, Laosmaxfightlimitupdate, setLaosmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-300 hover:bg-orange-500'>
                  <div>
                    <span className='text-white font-bold'>Fight Limit จับยี่กี</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfightlimit[0]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightlimitupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChangepayrate(e, Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfightlimit[1]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightlimitupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChangepayrate(e, Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfightlimit[2]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightlimitupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChangepayrate(e, Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfightlimit[3]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightlimitupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChangepayrate(e, Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfightlimit[4]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightlimitupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChangepayrate(e, Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeemaxfightlimit[5]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeemaxfightlimitupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChangepayrate(e, Yeekeemaxfightlimitupdate, setYeekeemaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-700 hover:bg-orange-800'>
                  <div>
                    <span className='text-white font-bold'>Fight Limit จับยี่กีVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfightlimit[0]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightlimitupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfightlimit[1]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightlimitupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfightlimit[2]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightlimitupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfightlimit[3]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightlimitupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfightlimit[4]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightlimitupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevipmaxfightlimit[5]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevipmaxfightlimitupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevipmaxfightlimitupdate, setYeekeevipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className='w-full text-center justify p-1 rounded-sm bg-orange-900 hover:bg-orange-900'>
                  <div>
                    <span className='text-white font-bold'>Fight Limit จับยี่กีVVIP</span></div>
                  {isThaiTableVisible && (
                    <div className='rounded overflow-hidden mt-1'>
                      <table className="w-full" style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr className=''>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center"></th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">3 ตัวโต๊ด</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">2 ตัวล่าง</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งบน</th>
                            <th style={{ width: '9%' }} className="bg-amber-200 font-bold text-center">วิ่งล่าง</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>

                          <tr>
                            <td style={{ width: '9%' }} className="text-center font-bold text-[13px]">Fight Limit</td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfightlimit[0]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightlimitupdate[0]}
                                  name={0}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfightlimit[1]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightlimitupdate[1]}
                                  name={1}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfightlimit[2]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightlimitupdate[2]}
                                  name={2}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfightlimit[3]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightlimitupdate[3]}
                                  name={3}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfightlimit[4]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightlimitupdate[4]}
                                  name={4}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                            <td style={{ width: '9%' }} className="text-center">
                              <div className='flex px-3 py-1 flex-col'>
                                <span className='font-bold text-amber-500 justify-items-center items-center text-[12px]'>
                                  {Yeekeevvipmaxfightlimit[5]}
                                </span>
                                <input type="number" min="1" max="100" className='font-bold text-[15px] border-[1px] border-slate-300 rounded-lg text-center'
                                  value={Yeekeevvipmaxfightlimitupdate[5]}
                                  name={5}
                                  onChange={(e) => handleChangepayrate(e, Yeekeevvipmaxfightlimitupdate, setYeekeevvipmaxfightlimitupdate)}
                                  onClick={(e) => e.target.select()}>
                                </input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

              </div>}

          </div>
        </div>

      </div>
    </div>
  )
}

export default Limitnumber