import * as React from 'react';
import LoginIcon from '@mui/icons-material/Login';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import './login.css'



export default function SignIn() {

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const json = {
      Username: data.get('username'),
      Password: data.get('password'),
    }
    fetch(`${process.env.REACT_APP_API_KEY}/adminlogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.status === 'OK') {
          localStorage.setItem('token', data.token)
          // localStorage.setItem('user', json.Username)
          Swal.fire({
            icon: 'success',
            title: 'การตรวจสอบ',
            text: 'Username และรหัสผ่านถูกต้อง ',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ตกลง'
          }).then(() => {
            window.location = '/'
          })
        } 
        else if(data.status === 'suspend') {
          Swal.fire({
            icon: 'warning',
            title: 'บัญชีถูกระงับ',
            text: ' ติดต่อผู้ใช้บัญชีหลัก',
            confirmButtonColor: '#FFDE1',
            confirmButtonText: 'ตกลง'
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'เข้าสู่ระบบผิดพลาด',
            text: ' Username หรือ รหัสผ่านผิด ',
            confirmButtonColor: '#d33',
            confirmButtonText: 'ตกลง'
          })
        }
        // console.log('Success:', data);
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  };

  return (
    <div className='loginbg w-[100%] h-[100vh] flex bg-purple-900 flex place-content-center justify-center'>
      <div className='w-[90%] mt-[50%] md:w-[40%] h-[380px] grid grid-cols-1 gap-4 bg-black mt-[50%] md:mt-[10%] rounded-lg border-solid border-2 border-purple-600 p-5'>
        <div className=''>
          <LoginIcon className='ml-2 font-bold text-white' />
          <span className='ml-2 font-bold text-white text-xl'>Login</span>
          <div className='w-full md:w-full h-[0.5px] bg-purple-900 border-solid mt-1'></div>
        </div>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <span className='ml-2 font-bold text-white text-m'>Username</span>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            name="username"
            autoFocus
            inputProps={{
              style: { color: "yellow" }
            }}
            style={{
              backgroundColor: "black",
              border: "2px solid purple"
            }}
            className="px-4 py-2 mb-4 rounded-md border-gray-300 font-bold focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-white"
          />
          <span className='ml-2 mt-3 font-bold text-white text-m'>Password</span>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            inputProps={{
              style: { color: "yellow" }
            }}
            style={{
              backgroundColor: "black",
              border: "2px solid purple"
            }}
            autoComplete="current-password"
            className="px-4 py-2 rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-white"
          />

          <button type="submit" className="bg-purple-600 mr-1 mt-3 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
            <LoginIcon className='ml-2 text-white mr-2' />
            เข้าสู่ระบบ
          </button>
          <Grid container>
          </Grid>
        </Box>
      </div>

    </div>
  );
}