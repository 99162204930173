import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';

const Commissiontable = ({ columns, data}) => {
  // console.log(data)
  const [currentRow, setCurrentRow] = useState(0);
  const [selectedSortOption, setSelectedSortOption] = useState('');
  const [filteredTableData, setFilteredTableData] = useState(data);
  const itemsPerPage = 1; // Display one row at a time

  //ช่องค้นหาตารางรายละเอียดรายได้ 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filteredTableData, // Use filteredTableData here
      initialState: {
        sortBy: selectedSortOption
          ? [{ id: selectedSortOption, desc: true }]
          : [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const RowCount = Math.ceil(rows.length / itemsPerPage);
  const displayRows = rows.slice(0, (currentRow + 1) * itemsPerPage);
  useEffect(() => {
    // Initialize the table with all data when the component mounts
    setFilteredTableData(data);
  }, [data]);

  useEffect(() => {
    if (currentRow < RowCount - 1) {
      const timer = setTimeout(() => {
        setCurrentRow(currentRow + 1);
      }, 40); // Delay in milliseconds between rendering each row

      return () => clearTimeout(timer);
    }
  }, [currentRow, RowCount]);



  return (
    <div >
      <div className="rounded overflow-hidden mt-1">
        <Table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-1 px-2 text-sm ${column.widthClass}`}
                    align={column.align}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {displayRows.map((row, index) => {
              prepareRow(row);
              const rowClassName = index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200';
              return (
                <tr
                  {...row.getRowProps()}
                  className={`transition-colors ${rowClassName} hover:bg-gray-300`}
                >
                  {row.cells.map((cell, columnIndex) => (
                    <td
                      {...cell.getCellProps()}
                      className={`p-2 ${cell.column.widthClass}`}
                      align={cell.column.align}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Commissiontable;
