import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Header from './header'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import MyTable from './Table';


function Winlose() {

  const [tablecontrol, setTablecontrol] = useState('downline'); 

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [order, setOrder] = useState([]);
  const [showorder, setShowrder] = useState([]);
  // console.log(showorder)
  const user = localStorage.getItem('user')

  const [startdate, setStartdate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endtdate, setEndtdate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [lottoselect, setLottoselect] = useState(["th_gov", "laos_gov", "Yeekee_5", "Yeekee_10", "Yeekee_15"]);
  const [agent, setAgent] = useState();

  const OrderGet = () => {
    const json = {
      user: user,
      startdate:startdate,
      enddate:endtdate,
      lottotype:lottoselect
    }
    fetch(`${process.env.REACT_APP_API_KEY}/sumorder`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
      .then(res => res.json())
      .then(
        (result) => {
          setOrder(result)
          setShowrder(result)
          setTablecontrol("downline")
          //alert(startdate)
        }
      )
  }

  const OrderGetdetail = (agent,condition) => {
    const json = {
      user: agent,
      startdate:startdate,
      enddate:endtdate,
      lottotype:lottoselect
    }
    if(condition === 'no_dl'){
      setTablecontrol('downlinedetail')
      fetch(`${process.env.REACT_APP_API_KEY}/sumorder_moredetail_user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      })
        .then(res => res.json())
        .then(
          (result) => {
            setOrder(result)
            setShowrder(result)
          }
        )

    } else
    {
      fetch(`${process.env.REACT_APP_API_KEY}/sumorder_moredetail`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      })
        .then(res => res.json())
        .then(
          (result) => {
            setOrder(result)
            setShowrder(result)
            setTablecontrol("downline")

          }
        )

    }
  }


  const lottoTypeMappings = {
    th_gov: 'หวยรัฐบาลไทย',
    laos_gov: 'หวยรัฐบาลลาว',
    hanoy_PhuQua: 'หวยฮานอย',
    Yeekee_5: 'หวยยี่กี่',
    Yeekee_10: 'หวยยี่กี่ VIP',
    Yeekee_15: 'หวยยี่กี่ VVIP',
  };
  const betTypeMappings = {
    first: 'รางวัลที่ 1',
    four_top: 'สี่ตัวตรง',
    four_tod: 'สี่ตัวโต๊ด',
    three_top: 'สามตัวบน',
    three_front: 'สามตัวหน้า',
    three_bot: 'สามตัวหลัง',
    three_tod: 'สามตัวโต๊ด',
    two_top: 'สองตัวบน',
    two_bot: 'สองตัวล่าง',
    run_top: 'วิ่งบน',
    run_bot: 'วิ่งล่าง',
  };
  
  

  useEffect(() => {
    OrderGet()
  }, [])
  const pos = localStorage.getItem('position')
  
  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos}/>
        <div className="inner">
          <div className="content">
            <Header title='Win & Lose' />

            <TableContainer component={Paper}>
              <TableContainer component={Paper}>
                {
                  tablecontrol === 'downline' &&
                  <MyTable
                  startdate={startdate}
                  setstartdate={setStartdate}

                  enddate={endtdate}
                  setenddate={setEndtdate}

                  fetch={OrderGet}
                  setLottoselect={setLottoselect}
                  lottoselect={lottoselect}

                  search={"Winlose"}
                  showTotal={"Winlose"}
                  columns={[
                    {
                      Header: 'Username Downline', accessor: 'Agent', align: 'right', widthClass: 'w-1/6',
                      Cell: ({ row,value }) => 
                      <div className='bg-amber-600 w-[100%] text-center text-white rounded border-solid hover:bg-amber-800 ' onClick={() => OrderGetdetail(value,row.original.dl)}>
                      <span className="font-bold">{value}</span>
                      </div>,
                    },
                    {
                      Header: 'เดิมพัน', accessor: 'sum(bet)', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'คอมมิชชั่น', accessor: 'commission', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'จ่ายออก', accessor: 'win_lose', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'ดาวน์ไลน์ได้', accessor: 'agent_take', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'อัพไลน์ได้', accessor: 'pay_out', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },


                  ]}
                  data={showorder}
                  components={{
                    TableCell: StyledTableCell,
                    TableRow: StyledTableRow,
                  }}
                />
                }
                                {
                  tablecontrol === 'downlinedetail' &&
                  <MyTable
                  startdate={startdate}
                  setstartdate={setStartdate}

                  enddate={endtdate}
                  setenddate={setEndtdate}

                  fetch={OrderGet}
                  setLottoselect={setLottoselect}
                  lottoselect={lottoselect}

                  search={"Winlose"}
                  columns={[
                    {
                      Header: 'Username Downline', accessor: 'Agent', align: 'right', widthClass: 'w-1/6',
                      Cell: ({ row,value }) => 
                      <div className='bg-amber-600 w-[100%] text-center text-white rounded border-solid hover:bg-amber-800 '>
                      <span className="font-bold">{value}</span>
                      </div>,
                    },
                    {
                      Header: 'วันที่ซื้อ', accessor: 'buydate', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">
                        {moment(value).format("DD-MM-YYYY HH:MM:SS")}
                        </span>,
                    },

                    {
                      Header: 'ประเภทหวย', accessor: 'lotto_type', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">
                         {lottoTypeMappings[value] || value}
                      </span>,
                    },
                    {
                      Header: 'ประเภทเดิมพัน', accessor: 'bet_type', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">
                          {betTypeMappings[value] || value}
                        </span>,
                    },
                    {
                      Header: 'เลข', accessor: 'number', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">
                        {value}
                        </span>,
                    },

                    {
                      Header: 'เดิมพัน', accessor: 'sum(bet)', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'คอมมิชชั่น', accessor: 'commission', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'จ่ายออก', accessor: 'win_lose', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'ดาวน์ไลน์ได้', accessor: 'agent_take', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'อัพไลน์ได้', accessor: 'pay_out', align: 'right',
                      widthClass: 'w-1/8',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },



                  ]}
                  data={showorder}
                  components={{
                    TableCell: StyledTableCell,
                    TableRow: StyledTableRow,
                  }}
                />
                }

              </TableContainer>
            </TableContainer>


          </div>
        </div>

      </div>
    </div>
  )
}

export default Winlose
