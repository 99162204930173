import React, { useEffect, useState } from "react";
import TableContainer from '@mui/material/TableContainer';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MyTable from './Table'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordIcon from '@mui/icons-material/Password';
import moment from "moment";
import { Button } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';


export default function CustomizedTables() {

  const [member, setMember] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = useState();
  const [userid, setUserid] = useState();


  const [reload, setReload] = useState(0);
  const [status, setStatus] = useState(1);
  const [permission,setPermission] = useState([1,1,1,1,1,1,1,1,1,1,1,1])


  const pos = localStorage.getItem('position')
  const user = localStorage.getItem('user')


  const MemberGet = async () => {
    const json = {
      user: user
    }
    await fetch(`${process.env.REACT_APP_API_KEY}/getsubaccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
      .then(res => res.json())
      .then((result) => { setMember(result) })
  }

  useEffect(() => {
    MemberGet()
  }, [reload])

  const handleClickOpen = (id,username) => {
    setOpen(true);
    setUsername(username);
    setUserid(id)
    const jsonid = {
      id: id,
    };

    fetch(`${process.env.REACT_APP_API_KEY}/getpermissionid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonid),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setPermission([
            result[0].add_member,
            result[0].add_agent,
            result[0].agent_credit_update,
            result[0].member_credit_update,
            result[0].edit_agent,
            result[0].edit_member,
            result[0].edit_lotto_status,
            result[0].limit_lotto,
            result[0].edit_maxfight,
            result[0].winlose_view,
            result[0].result_view,
            result[0].waitresult_view
          ])
          setStatus(result[0].status)
        }
      )

  };

  const handleUpdate = () => {
    setOpen(false)
    Swal.fire({
      title: 'ต้องการแก้ไขรายละเอียด?',
      text: `กดยืนยันเพื่อบันทึกข้อมูล : ${username}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonid = {
          id: userid,
          status:status,
          permission:permission
        };
        fetch(`${process.env.REACT_APP_API_KEY}/updatepermissionid`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((res) => res.json())
          .then(() => {
            toast.success('User updated successfully!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setReload(reload + 1);
            handleClose();
          })
          .catch((error) => {
            toast.error('An error occurred while updating the user.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setUsername();
    setUserid()
  };

  // #region password edit

  const [openeditpassword, setOpeneditpassword] = React.useState(false);
  const [password, setPassword] = useState();
  const [id, setId] = useState();


  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickOpenpassword = (username,id) => {
    setUsername(username)
    setId(id)
    setOpeneditpassword(true)
  };

  const handleClosepassword = () => {
    setOpeneditpassword(false);
    setUsername()
    setId()
  };

  const handleChangepassword = (event) => {
    setPassword(event.target.value)
  };

  const handleUpdatepassword = (password) => {
    const jsonid = {
      id: id,
      Password: password,
    };

    Swal.fire({
      title: 'เปลี่ยนรหัสให้กับสมาชิก?',
      text: 'กดยืนยันเพื่อแก้ไขรหัสให้กับสมาชิก?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action, proceed with the fetch
        fetch(`${process.env.REACT_APP_API_KEY}/subpasswordchange`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((response) => {
            if (response.ok) {
              // Password update was successful, show a success notification
              toast.success('เปลี่ยนรหัสให้กับสมาชิก สำเร็จ', {
                position: 'top-right',
                autoClose: 3000, // You can adjust the duration
              });
            } else {
              // Password update failed, show an error notification
              toast.error('Password update failed', {
                position: 'top-right',
                autoClose: 3000, // You can adjust the duration
              });
            }
          })
          .catch((error) => {
            console.error('Error updating password:', error);
            // Show an error notification for any fetch error
            toast.error('An error occurred while updating the password', {
              position: 'top-right',
              autoClose: 3000, // You can adjust the duration
            });
          });
      }
    });

    handleClosepassword(); // Close the password dialog regardless of the user's choice
  };

  const handlepermission = (index) =>{
    let temppermission = [...permission]
    if(temppermission[index] === 0)
    {
      temppermission[index] = 1
    } else {
      temppermission[index] = 0
    }

    setPermission(temppermission)
  }


  const handleDeleteagent = (agent,id) => {
    // Show confirmation dialog
    Swal.fire({
      title: 'ลบสมาชิก?',
      text: `คุณต้องการลบ ${agent} รายนี้หรือไม่ ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the agent
        const jsonid = {
          id: id
        };
        fetch(`${process.env.REACT_APP_API_KEY}/deletesubaccount`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonid)
        })
          .then(res => res.json())
          .then(() => {
            toast.success('ลบสมาชิกเรียบร้อยแล้ว');
            window.location.reload();
          })
          .catch((error) => {
            toast.error('เกิดข้อผิดพลาดบางอย่าง!');
          });
      }
    });
  };



  // #endregion

  const memberColumns = [
    {
      Header: 'Username',
      accessor: 'username',
      align: 'center',
      Cell: ({ value }) => <span className="font-bold">{value}</span>,
      widthClass: 'w-1/6', // Adjust the width class as needed
    },
    {
      Header: 'เวลาเปิดล่าสุด',
      accessor: 'login_date',
      align: 'right',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/6',
    },
    {
      Header: 'IP เปิดล่าสุด',
      accessor: 'ip',
      align: 'right',
      Cell: ({ value }) => <span className="text-xs">{value}</span>,
      widthClass: 'w-1/6',
    },
    {
      Header: 'ฟังชั่น',
      accessor: 'id',
      align: 'center', 
      Cell: ({ value,row }) => 
      <div className="flex justify-center flex-row space-x-1">
        <button 
        className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
        onClick={e => handleClickOpen(value,row.original.username)}>
        <SettingsIcon/>
        </button>
        <button 
        className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
        onClick={e => handleClickOpenpassword(row.original.username,row.original.id)}>
        <PasswordIcon/>
        </button>
        <button
            onClick={() => handleDeleteagent(row.original.username,row.original.id)}
            className="bg-slate-400 text-white mt-2 hover:bg-slate-600 hover:text-black font-bold py-1 px-3 rounded text-xs"
          >
            <DeleteForeverIcon className="text-xs md:text-base" />
          </button>
      </div>,
      widthClass: 'w-1/6',
    },
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <MyTable columns={memberColumns} data={member} search={""} />
      </TableContainer>
      {openeditpassword &&
        <div>
          <Dialog
            open={openeditpassword}
            onClose={handleClosepassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              แก้ไขรหัสผ่าน สำหรับ Username : {username}
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col p-2 space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <span>กรอกรหัสผ่านใหม่</span>

                  <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      onChange={(e) => handleChangepassword(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <button onClick={() => handleUpdatepassword(password)} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                <SaveAltIcon /> บันทึก
              </button>
              <button onClick={handleClosepassword} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}

        {open &&
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="xs"
                >
                  <div className="App">
                    <form onSubmit={handleUpdate}>

                      <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
                        แก้ไขบัญชีผู้ใช้งานร่วม {username}
                      </DialogTitle>
                      <DialogContent>
                        <div className="grid grid-cols-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">

                          <div>
                            <label className="block mb-1 font-bold">
                              สถานะ
                            </label>
                            <select    
                            value={status}                             
                            onChange={(e) => setStatus(Number(e.target.value))} name="status" id="status">
                              <option value={1} >ทำงาน</option>
                              <option value={0} >หยุดทำงาน</option>
                            </select>
                          </div>
                        </div>

                        <div className="grid grid-rows p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="mb-1 font-bold">
                              สิทธิรายการ
                            </label>

                            <div className="grid grid-rows-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              บัญชี
                            </label>

                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[0] === 1} value={permission[0]} onChange={(e) => handlepermission(0)}/> 
                              เพิ่มสมาชิก 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[1] === 1} value={permission[1]} onChange={(e) => handlepermission(1)}/> 
                              เพิ่มเอเจน 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[2] === 1} value={permission[2]} onChange={(e) => handlepermission(2)}/> 
                              จัดการเครดิตเอเจน 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[3] === 1} value={permission[3]} onChange={(e) => handlepermission(3)}/> 
                              จัดดารเครดิตสมาชิก 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[4] === 1} value={permission[4]} onChange={(e) => handlepermission(4)}/> 
                              จัดการเอเจน 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[5] === 1} value={permission[5]} onChange={(e) => handlepermission(5)}/> 
                              จัดการสมาชิก 
                            </label>
                            </div>

                            <div className="grid grid-rows-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              ตั้งค่าจำกัด
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[6] === 1} value={permission[6]} onChange={(e) => handlepermission(6)}/> 
                              จัดการสถานะหวย 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[7] === 1} value={permission[7]} onChange={(e) => handlepermission(7)}/> 
                              จัดการซื้อสูงสุด 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[8] === 1} value={permission[8]} onChange={(e) => handlepermission(8)}/> 
                              จัดการสู้สูงสุด 
                            </label>
                            </div>

                            <div className="grid grid-rows-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              รายงาน
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[9] === 1} value={permission[9]} onChange={(e) => handlepermission(9)}/> 
                              แพ้ชนะรวม 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[10] === 1} value={permission[10]} onChange={(e) => handlepermission(10)}/> 
                              ผลหวย 
                            </label>
                            <label>
                              <input type="checkbox" class="mr-2 accent-blue-500" checked={permission[11] === 1} value={permission[11]} onChange={(e) => handlepermission(11)}/> 
                              ยอดคงค้าง 
                            </label>
                            </div>

                        </div>
                      </DialogContent>
                      <DialogActions>
                        <button type="submit" className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                          <AddBoxIcon /> บันทึก
                        </button>
                        <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          ยกเลิก
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </Dialog>
              </div>
            }

    </div>
  );
}
