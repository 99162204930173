import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Member.css'
import Navbar from './Navbar'
import Ordertable from './Ordertable'
import Header from './header'

function Member() {
    return (
        <div>
        <Navbar />
         <div className="contain w-[800px] md:w-full md:flex">
             <Sidebar />

                          <div className='content'>
                          <Header title='Agent' />
                        <div className='content'>
                            <Ordertable/>
                        </div>
                    {/* </div> */}
                </div>
                
            </div>
        </div>
    )
}

export default Member