import React, { useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Member.css'
import Navbar from './Navbar'
import Membertable from './Membertable'
import AddBoxIcon from '@mui/icons-material/AddBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './header'

function Member() {

  const [open, setOpen] = React.useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bank, setBank] = useState("");
  const [banknumber, setBanknumber] = useState("");
  const [permission, setPermission] = useState();

  const [vusername, setvUsername] = useState(false);
  const [vpassword, setvPassword] = useState(false);
  const [vfname, setvFname] = useState(false);
  const [vlname, setvLname] = useState(false);
  const [vmobileNumber, setvMobileNumber] = useState(false);
  const [vbank, setvBank] = useState(false);
  const [vbanknumber, setvBanknumber] = useState(false);


  const user = localStorage.getItem("user")
  const id = localStorage.getItem('subuserid')
  const pos = localStorage.getItem('position')
  

  const json = {
    id : id
    }
 const getpermission = () => {
 fetch(`${process.env.REACT_APP_API_KEY}/getpermissionid`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
      .then(data => data.json())
      .then(data => {
          setPermission(data[0])
      })
    }

  const handleClose = () => {
    setOpen(false);
    setUsername('')
    setPassword('')
    setFname('')
    setLname('')
    setMobileNumber('')
    setBank('')
    setBanknumber('')

    setvUsername(false)
    setvPassword(false)
    setvFname(false)
    setvLname(false)
    setvMobileNumber(false)
    setvBank(false)
    setvBanknumber(false)
  };


  const handleClickOpen = () => {
    if(pos === 'sub_account')
    {
      getpermission()
      if(permission.add_member === 1)
        {
          setOpen(true)
        }
        else
        {
          alert('No permission')
        }
    } else
    {
      setOpen(true);
    }
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password || !fname || !lname || !mobileNumber || !bank || !banknumber || !user) {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน !', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (!username) {
        setvUsername(true)
      } else (
        setvUsername(false)
      )

      if (!password) {
        setvPassword(true)
      } else (
        setvPassword(false)
      )

      if (!fname) {
        setvFname(true)
      } else (
        setvFname(false)
      )

      if (!lname) {
        setvLname(true)
      } else (
        setvLname(false)
      )

      if (!mobileNumber) {
        setvMobileNumber(true)
      } else (
        setvMobileNumber(false)
      )

      if (!bank) {
        setvBank(true)
      } else (
        setvBank(false)
      )

      if (!banknumber) {
        setvBanknumber(true)
      } else (
        setvBanknumber(false)
      )
      return;
    }

    const json = {
      Username: username,
      Password: password,
      fname: fname,
      lname: lname,
      phone: mobileNumber,
      bank: bank,
      accout: banknumber,
      agent: user,
    };

    fetch(`${process.env.REACT_APP_API_KEY}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result)
        if (result === 'aleady has this user') {
          console.log(result)
          toast.error('มีสมาชิกชื่อนี้ในระบบแล้ว', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log(result)
          setUsername('');
          setPassword('');
          setFname('');
          setLname('');
          setMobileNumber('');
          setBank('');
          setBanknumber('');
          toast.success('เพิ่มสมาชิกใหม่เรียบร้อย!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          setOpen(false)
          .then(window.location.reload(false))
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        toast.error('An error occurred while registering the user.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };



  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos}/>
        <div className="inner flex-1">
          <div className='content'>
            <Header title='Member' />
            <div className='p-1 mb-1 bg-purple-800 h-[2em] w-[7%] text-center text-white rounded border-solid hover:bg-sky-800 '>
              <GroupAddIcon className='ml-1 text-[5px] md:text-[8px]' />
              <span className='ml-1 text-[5px] md:text-[8px]' onClick={handleClickOpen}>เพิ่มสมาชิก</span>
            </div>
            <Membertable />
            {open &&
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div className="App">
                    <form onSubmit={handleSubmit}>

                      <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
                        {"เพิ่มสมาชิกใหม่"}
                      </DialogTitle>
                      <DialogContent>
                        <div className="grid grid-cols-2 p-2 mt-2 border-[1px] bg-amber-100 border-slate-400 rounded-lg gap-4">
                          <div>
                            <label htmlFor="username" className="block mb-1 font-bold">
                              Username
                            </label>
                            <input
                              type="text"
                              id="username"
                              value={username}
                              placeholder="Username"
                              onChange={(e) => setUsername(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vusername ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="password" className="block mb-1 font-bold">
                              Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              placeholder="Password"
                              onChange={(e) => setPassword(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vpassword ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="fname" className="block mb-1 font-bold">
                              ชื่อ
                            </label>
                            <input
                              type="text"
                              id="fname"
                              value={fname}
                              placeholder="Firstname"
                              onChange={(e) => setFname(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vfname ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="lname" className="block mb-1 font-bold">
                              นามสกุล
                            </label>
                            <input
                              type="text"
                              id="lname"
                              value={lname}
                              placeholder="Lastname"
                              onChange={(e) => setLname(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vlname ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="mobileNumber" className="block mb-1 font-bold">
                              เบอร์มือถือ
                            </label>
                            <input
                              type="text"
                              id="mobileNumber"
                              value={mobileNumber}
                              placeholder="Mobile Number"
                              onChange={(e) => setMobileNumber(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vmobileNumber ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="bank" className="block mb-1 font-bold">
                              ธนาคาร
                            </label>
                            <input
                              type="text"
                              id="bank"
                              value={bank}
                              placeholder="Bank"
                              onChange={(e) => setBank(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vbank ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                          <div>
                            <label htmlFor="banknumber" className="block mb-1 font-bold">
                              เลขบัญชีธนาคาร
                            </label>
                            <input
                              type="text"
                              id="banknumber"
                              value={banknumber}
                              placeholder="BankAccoutNumber"
                              onChange={(e) => setBanknumber(e.target.value)}
                              className='w-auto text-[15px] px-3 py-2 text-gray-700 bg-slate-150 border-b-[2px] rounded-lg focus:outline-none focus:border-blue-500'
                            />
                            {vbanknumber ? <p className='pl-1 text-red-500'>จำเป็น</p> : ''}
                          </div>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <button type="submit" className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                          <AddBoxIcon /> เพิ่มสมาชิก
                        </button>
                        <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          ยกเลิก
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </Dialog>
              </div>}
          </div>
          {/* </div> */}
        </div>

      </div>
    </div>
  )
}

export default Member