import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import MyTable from './Table'
import Header from './header'
import { Button } from '@mui/material';
import './Member.css'
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SaveAltIcon from '@mui/icons-material/SaveAlt';


function Payrate() {

  const [payrate, setPayrate] = useState([]);
  const user = localStorage.getItem('user');
  const [reload, setReload] = useState(0);

  const positionget = {
    agentuser: user
  }

  const PayrateGet = async () => {
    await fetch(`${process.env.REACT_APP_API_KEY}/Getpayrate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then(res => res.json())
      .then((result) => {
        setPayrate(result)
      })
  }
  useEffect(() => {
    PayrateGet()
  }, [reload])


  const [checked, setChecked] = React.useState();

  const handleChange = (event, type) => {
    const checked = !!event.target.checked;
    setChecked(checked);
    const jsonid = {
      agent: user,
      type: type,
      status: checked,
    };
    console.log(jsonid)
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: 'คุณกำลังทำการอัพเดตสถานะ!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, อัพเดต!',
      cancelButtonText: 'ยกเลิก',
      buttonsStyling: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_KEY}/Setopenclosepayrate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonid),
        })
          .then((res) => res.json())
          .then((result) => {
            setReload(reload + 1);
            toast.success('อัพเดตสถานะสำเร็จแล้ว!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
          })
          .catch((error) => {
            console.log('Error:', error);
            toast.error('เกิดข้อผิดพลาดในการอัพเดตสถานะ', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
          });
      }
    });
  };

  const handleClosetime = (id, time) => {
    const confirmationMessage = `คุณจะตั้งค่าเวลาปิดขายเป็นเวลา ${time}`;
  
    Swal.fire({
      title: 'แน่ใจหรือไม่?',
      text: confirmationMessage,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonid = {
          id: id,
          time: time,
        };
  
        fetch(`${process.env.REACT_APP_API_KEY}/setclosetime`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(jsonid),
        })
          .then((res) => res.json())
          .then(() => {
            toast.success('ตั้งค่าเวลาปิดขายสำเร็จ', { autoClose: 2000 });
          })
          .catch((error) => {
            toast.error('เกิดข้อผิดพลาดในการตั้งค่าเวลาปิดขาย', { autoClose: 2000 });
            console.error(error);
          });
      } else {
        console.log('ยกเลิกการตั้งค่าเวลาปิดขาย');
      }
    });
  };
  

  const statusStyles = {
    "0": "bg-red-500 text-white", // Customize the background color and text color for each status
    "1": "bg-green-500 text-white",
  };

  const renderStatusCell = (row) => {
    return (
      <div className={`py-1 px-3 rounded ${statusStyles[row.status] || 'bg-gray-500 text-white'}`}>
        {row.status === 0 ? 'ปิดการขาย' : 'เปิดขาย'}
        {row.status === 1 && <span className="ml-2" />}
      </div>
    );
  };

  const columns = [
    {
      Header: 'สถานะการขาย',
      accessor: 'status',
      Cell: ({ row }) => (
        <div>
          {renderStatusCell(row.original)}
          <Switch
            checked={row.original.status === 1}
            onChange={(event) => handleChange(event, row.original.lotto_type)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
      ),
    },
    {
      Header: 'ประเภทหวย',
      accessor: 'lotto_type',
      Cell: ({ value }) => (
        <>
          {value === 'th_gov' && 'รัฐบาลไทย'}
          {value === 'laos_gov' && 'หวยลาว'}
          {value === 'hanoy_PhuQua' && 'หวยฮานอย'}
          {value === 'Yeekee_5' && 'ยี่กี่ 5 นาที'}
          {value === 'Yeekee_10' && 'ยี่กี่ 10 นาที'}
          {value === 'Yeekee_15' && 'ยี่กี่ 15 นาที'}
        </>
      ),
    },
    {
      Header: 'เวลาปิดขาย',
      accessor: 'close_time',
      Cell: ({ row, value }) => (
        <>
          <div className='flex flex-row space-x-2'>
          {/* {row.original.lotto_type === 'th_gov' && */}
          <>
                    <input
            type="time"
            className='border-[1px] border-slate-500 rounded-lg font-bold p-1'
            value={row.original.close_time}
            onChange={(e) => {
              // Update the close_time value in the current row
              const updatedData = payrate.map((data) =>
                data.id === row.original.id ? { ...data, close_time: e.target.value } : data
              );
              // Update the payrate data with the new close_time value
              setPayrate(updatedData);
            }}
          />
          <button onClick={() => { handleClosetime(row.original.id, row.original.close_time) }} className="text-slate-600 no-underline hover:text-slate-800">
            <SaveAltIcon />
          </button>

          </> 
          {/* {row.original.lotto_type === 'laos_gov' &&
          <>
                    <input
            type="time"
            className='border-[1px] border-slate-500 rounded-lg font-bold p-1'
            value={row.original.close_time}
            onChange={(e) => {
              // Update the close_time value in the current row
              const updatedData = payrate.map((data) =>
                data.id === row.original.id ? { ...data, close_time: e.target.value } : data
              );
              // Update the payrate data with the new close_time value
              setPayrate(updatedData);
            }}
          />
          <button onClick={() => { handleClosetime(row.original.id, row.original.close_time) }} className="text-slate-600 no-underline hover:text-slate-800">
            <SaveAltIcon />
          </button>

          </> } */}

          </div>
        </>
        
        
      ),
    }

  ];
  const pos = localStorage.getItem('position')


  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos} />
        <div className="inner">
          <div className="content">
            <Header title='Limit Pay' />
            <TableContainer component={Paper}>
              <MyTable data={payrate} columns={columns} serach={"off"} />
            </TableContainer>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Payrate