import React from 'react'

function HeaderBar(props) {
  return (
    <div className='w-full h-auto mb-2 flex flex-row'>
      <div className='md:w-[15%] bg-purple-900 rounded-bl-lg p-[3px]'>
        <p className='text-l font-bold text-yellow-200 text-center sm:text-xs'>
          {props.title}
        </p>
      </div>
      <div className='bg-gradient-to-r from-slate-500 w-full h-full'></div>
    </div>
  )
}

export default HeaderBar
