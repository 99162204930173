import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import Header from './header'
import './Commission.css'
import { Button } from '@mui/material'
import Commissiontable from './CommissionTable'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function Maxbetpermatch() {
  const pos = localStorage.getItem('position')
  const username = localStorage.getItem('user')
  const json = {
    agent:username
  }

  const [visible,setvisible] = useState({
    th:true,
    hn:false,
    la:false,
    yk5:false,
    yk10:false,
    yk15:false
  })

  const handlevisible = (lottotype) =>{

    setvisible((prevObj) => ({
      ...prevObj,
      [lottotype]: !prevObj[lottotype],
    }));
  }
  
  const [THmaxbet, setTHmaxbet] = useState([]);
  const [HNmaxbet, setHNmaxbet] = useState([]);
  const [LAmaxbet, setLAmaxbet] = useState([]);
  const [YK5maxbet, setYK5maxbet] = useState([]);
  const [YK10maxbet, setYK10maxbet] = useState([]);
  const [YK15maxbet, setYK15maxbet] = useState([]);

  const MaxbetGet = async () => {
    await fetch(`${process.env.REACT_APP_API_KEY}/GetDownlinemaxbetpermatch`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then((result) => {
        const thCMS = result.filter(element => element.lotto_type === 'th_gov');
        setTHmaxbet(thCMS)

        const hnCMS = result.filter(element => element.lotto_type === 'hanoy_PhuQua');
        setHNmaxbet(hnCMS)

        const laCMS = result.filter(element => element.lotto_type === 'laos_gov');
        setLAmaxbet(laCMS)

        const ykCMS = result.filter(element => element.lotto_type === 'Yeekee_5');
        setYK5maxbet(ykCMS)

        const yk10CMS = result.filter(element => element.lotto_type === 'Yeekee_10');
        setYK10maxbet(yk10CMS)

        const yk15CMS = result.filter(element => element.lotto_type === 'Yeekee_15');
        setYK15maxbet(yk15CMS)


      })
  }

  useEffect(() => {
    MaxbetGet()
  }, [])

    // state for commission

  const [editvisible, setEditvisible] = useState(false)
  const [newmaxbet, setNewmaxbet] = useState();
  const [agent, setAgent] = useState();
  const [idtoupdate, setIdtoupdate] = useState();
  const [edittype, setEdittype] = useState();

  // handle value change
  const handlechangecommission = async (e,type) => {
    let newvalue = Number(e.target.value)
    setNewmaxbet((prevObj) => ({
      ...prevObj,
      [type]: newvalue,
    }));

  }

  // handle edit commission

  const handleEdit = async (id,agent,type) => {
    setEdittype(type)
    setAgent(agent)
    setIdtoupdate(id)
    const json = {
      id: id
    }

    await fetch(`${process.env.REACT_APP_API_KEY}/getIDmaxbetpermatch`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then((result) => {
        setNewmaxbet(result[0])
        setEditvisible(true)
      })

  }

  const handleClose = () => {
    setEditvisible(false);
    setEdittype()
    setNewmaxbet()
    setAgent()
    setIdtoupdate()
  };

  // handle save update commission

  const handleSave = async (type) => {
    const json = {
      id:idtoupdate,
      value:newmaxbet
    }
    // console.log(type)
    // console.log(payrate.first,newcommission.first_mcms)

      await fetch(`${process.env.REACT_APP_API_KEY}/SetIDmaxbetpermatch`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json),
      })
        .then(res => res.json())
        .then((result) => {
          MaxbetGet()
          handleClose()    
        })
   }

  // column sector
  const THcommissionCol = [
    {
      Header: 'Username',
      accessor: 'agent',
      align: 'center',
      Cell: ({ value }) => <span className="font-bold font-bold">{value}</span>,
      widthClass: 'w-1/12', // Adjust the width class as needed
      lookup:
      {
        1:"John",
        2:"Jessica"
      }       
   
    },
    {
      Header: '',
      accessor: 'id',
      align: 'center',
      Cell: ({value,row}) => <Button onClick={() => handleEdit(value,row.original.agent,row.original.lotto_type)} className="font-bold font-bold">Edit</Button>,
      widthClass: 'w-1/12', // Adjust the width class as needed
    },

    {
      Header: 'รางวัลที่หนึ่ง',
      accessor: 'first',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12', // Adjust the width class as needed
    },
    {
      Header: '4 ตัวบน',
      accessor: 'four_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '4 ตัวโต๊ด',
      accessor: 'four_tod',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '3 ตัวบน',
      accessor: 'three_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },    {
      Header: '3 ตัวหน้า',
      accessor: 'three_front',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '3 ตัวหลัง',
      accessor: 'three_bot',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '3 ตัวโต๊ด',
      accessor: 'three_tod',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '2 ตัวบน',
      accessor: 'two_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '2 ตัวล่าง',
      accessor: 'two_bot',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: 'วิ่งบน',
      accessor: 'run_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: 'วิ่งล่าง',
      accessor: 'run_bot',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
  ];

  const OthercommissionCol = [
    {
      Header: 'Username',
      accessor: 'agent',
      align: 'center',
      Cell: ({ value }) => <span className="font-bold font-bold">{value}</span>,
      widthClass: 'w-1/12', // Adjust the width class as needed
      lookup:
      {
        1:"John",
        2:"Jessica"
      }       
   
    },
    {
      Header: '',
      accessor: 'id',
      align: 'center',
      Cell: ({value,row}) => <Button onClick={() => handleEdit(value,row.original.agent,row.original.lotto_type)} className="font-bold font-bold">Edit</Button>,
      widthClass: 'w-1/12', // Adjust the width class as needed
    },
    {
      Header: '3 ตัวบน',
      accessor: 'three_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '3 ตัวโต๊ด',
      accessor: 'three_tod',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '2 ตัวบน',
      accessor: 'two_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: '2 ตัวล่าง',
      accessor: 'two_bot',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: 'วิ่งบน',
      accessor: 'run_top',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
    {
      Header: 'วิ่งล่าง',
      accessor: 'run_bot',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
      widthClass: 'w-1/12',
    },
  ];


  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos} />
        <div className="inner">
          <div className="content">
            <Header title='Max Bet Per Match' />
            <div className='flex pl-2 gap-2'>
              <Link to="/commission">
              <Button>ค่าคอมมิชชั่น</Button>
              </Link>
              <Link to="/reward">
              <Button>รางวัล</Button>
              </Link>
              <Link to="/maxbet">
              <Button>แทงสูงสุด</Button>
              </Link>
              <Link to="/minbet">
              <Button>แทงต่ำสุด</Button>
              </Link>
              <Link to="/maxbetpermatch">
              <Button>แทงสูงสุดต่อเลข</Button>
              </Link>

            </div>
                <button type="button" className="collapsible bg-[#0ea5e9] hover:bg-[#7dd3fc]" onClick={() => handlevisible('th')}>หวยไทย</button>
                <div className={visible.th ? "visible" : "collapse"}>
                  <Commissiontable columns={THcommissionCol} data={THmaxbet}/>
                </div>

                <button type="button" className="collapsible bg-[#1d4ed8] hover:bg-[#3b82f6]" onClick={() => handlevisible('la')}>หวยลาว</button>
                <div className={visible.la ? "visible" : "collapse"}>
                  <Commissiontable columns={OthercommissionCol} data={LAmaxbet}/>
                </div>

                <button type="button" className="collapsible bg-[#e11d48] hover:bg-[#fb7185]" onClick={() => handlevisible('hn')}>หวยฮานอย</button>
                <div className={visible.hn ? "visible" : "collapse"}>
                  <Commissiontable columns={OthercommissionCol} data={HNmaxbet}/>
                </div>

                <button type="button" className="collapsible bg-[#f59e0b] hover:bg-[#fcd34d]" onClick={() => handlevisible('yk5')}>หวยยี่กี่</button>
                <div className={visible.yk5 ? "visible" : "collapse"}>
                  <Commissiontable columns={OthercommissionCol} data={YK5maxbet}/>
                </div>

                <button type="button" className="collapsible bg-[#f97316] hover:bg-[#fdba74]" onClick={() => handlevisible('yk10')}>หวยยี่กี่ VIP</button>
                <div className={visible.yk10 ? "visible" : "collapse"}>
                  <Commissiontable columns={OthercommissionCol} data={YK10maxbet}/>
                </div>

                <button type="button" className="collapsible bg-[#ef4444] hover:bg-[#fca5a5]" onClick={() => handlevisible('yk15')}>หวยยี่กี่ VVIP</button>
                <div className={visible.yk15 ? "visible" : "collapse"}>
                  <Commissiontable columns={OthercommissionCol} data={YK15maxbet}/>
                </div>
          </div>
        </div>
      </div>

      {editvisible &&
        <div>
          <Dialog
            open={editvisible}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { width: '60%', maxWidth: '100%', margin: 0 }
            }}
          >
            <DialogTitle className="text-white bg-purple-800" id="alert-dialog-title">
              <div className="p-1 flex justify-items-center items-center flex-row space-x-2">
                <span className="font-bold">ปรับแทงสูงสุด {agent}</span>
                <div className="p-1 border-1 bg-yellow-400 rounded-lg drop-shadow-xl">
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex w-full flex-col space-y-2">
                <div className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                  {/* Content */}
                  <table className='flex w-[100%] flex-col space-y-2 p-2 rounded-xl border border-[1px]'>
                    <thead>
                  <tr className={`transition-colors hover:bg-gray-300`}>
                  {
                    edittype === 'th_gov' &&
                    <>
                    <th className={`p-2 w-1/12`}>รางวัลที่หนึ่ง</th>
                    <th className={`p-2 w-1/12`}>4 ตัวตรง</th>
                    <th className={`p-2 w-1/12`}>4 ตัวโต๊ด</th>
                    <th className={`p-2 w-1/12`}>3 ตัวหน้า</th>
                    <th className={`p-2 w-1/12`}>3 ตัวหลัง</th>
                    </>
                  }
                    <th className={`p-2 w-1/12`}>3 ตัวบน</th>
                    <th className={`p-2 w-1/12`}>3 ตัวโต๊ด</th>
                    <th className={`p-2 w-1/12`}>2 ตัวบน</th>
                    <th className={`p-2 w-1/12`}>2 ตัวล่าง</th>
                    <th className={`p-2 w-1/12`}>วิ่งบน</th>
                    <th className={`p-2 w-1/12`}>วิ่งล่าง</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr className={`transition-colors hover:bg-gray-300`}>
                  {
                    edittype === 'th_gov' &&
                    <>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'first')} onClick={(e) => e.target.select()} value={newmaxbet.first} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'four_top')} onClick={(e) => e.target.select()} value={newmaxbet.four_top} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'four_tod')} onClick={(e) => e.target.select()} value={newmaxbet.four_tod} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'three_front')} onClick={(e) => e.target.select()} value={newmaxbet.three_front} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'three_bot')} onClick={(e) => e.target.select()} value={newmaxbet.three_bot} type="number" /></td>
                    </>
                  }
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'three_top')} onClick={(e) => e.target.select()} value={newmaxbet.three_top} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'three_tod')} onClick={(e) => e.target.select()} value={newmaxbet.three_tod} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'two_top')} onClick={(e) => e.target.select()} value={newmaxbet.two_top} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'two_bot')} onClick={(e) => e.target.select()} value={newmaxbet.two_bot} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'run_top')} onClick={(e) => e.target.select()} value={newmaxbet.run_top} type="number" /></td>
                    <td className={`p-2 w-1/12`}><input className='w-[100%]' onChange={(e) => handlechangecommission(e,'run_bot')} onClick={(e) => e.target.select()} value={newmaxbet.run_bot} type="number" /></td>
                  </tr>
                  </tbody>
                  </table>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
            <button onClick={() => handleSave(edittype)} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                บันทึก
              </button>

              <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </DialogActions>
          </Dialog>
        </div>}

    </div>
  )
}

export default Maxbetpermatch