import './App.css';
import Dashboard from './Component/Dashboard';
import { useState,useEffect } from 'react'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Member from './Component/Innercontent/Member';
import Login from './Component/login'
import Order from './Component/Innercontent/Order'
import Agent from './Component/Innercontent/Agent';
import Limitnumber from './Component/Innercontent/Limitnumber';
import Winlose from './Component/Innercontent/Winlose';
import Lottoresult from './Component/Innercontent/Lottoresult';
import Payrate from './Component/Innercontent/Payrate';
import AgentEdit from './Component/Innercontent/AgentEdit';
import AgentAdd from './Component/Innercontent/AgentAdd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fightlimitmax from './Component/Innercontent/Fightlimitmax';
import Loginslog from './Component/Innercontent/Loginlogs';
import Waitresult from './Component/Innercontent/Waitresult';
import Commission from './Component/Innercontent/commission';
import Reward from './Component/Innercontent/cReward';
import Maxbet from './Component/Innercontent/cMaxbet';
import Subaccount from './Component/Innercontent/Subaccout';
import Maxbetpermatch from './Component/Innercontent/cMaxbetpermatch';
import Minbet from './Component/Innercontent/cMinbet';
import { Button } from '@mui/material';

function App() {
  const token = localStorage.getItem('token');
  const [Auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState(
    {
      edit_lotto_status:0,
      limit_lotto:0,
      edit_maxfight:0,
      winlose_view:0,
      result_view:0,
      waitresult_view:0,
      edit_agent:0,
      edit_member:0,
      add_agent:0
    }
  );
  const pos = localStorage.getItem('position')

  fetch(`${process.env.REACT_APP_API_KEY}/authen`, {
    method: 'POST',
    headers: { "Authorization": `Bearer ${token}` }
  })
    .then(data => data.json())
    .then(data => {
      if (data.status === 'OK') {
        localStorage.setItem('user', data.decoded.username)
        localStorage.setItem('position', data.decoded.position)
        localStorage.setItem('subuser', data.decoded.sub_username)
        localStorage.setItem('subuserid', data.decoded.sub_id)
        setAuth(true)
        // setLoading(false)
        // console.log(data.decoded)
      } else {
        localStorage.removeItem('user')
        localStorage.removeItem('position')
        localStorage.removeItem('token')
        localStorage.removeItem('subuser')
        localStorage.removeItem('subuserid')
        // setLoading(false)
        setAuth(false)
      }
      // console.log('Success:', token);
    })

    const id = localStorage.getItem('subuserid')


        const json = {
        id : id
        }
     const getpermission = () => {
     fetch(`${process.env.REACT_APP_API_KEY}/getpermissionid`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(json)
        })
          .then(data => data.json())
          .then(data => {
            if(data.length >= 1)
              {
                setPermission(data[0])
                setLoading(false)
              }
              else
              {
                setPermission(
                  {
                    edit_lotto_status:1,
                    limit_lotto:1,
                    edit_maxfight:1,
                    winlose_view:1,
                    result_view:1,
                    waitresult_view:1,
                    edit_agent:1,
                    edit_member:1,
                    add_agent:1
                  }
                )
                setLoading(false)
              }
          })
        }

    useEffect(() => {
      getpermission()
    }, [Auth])


  if (loading) {
      return <div>Loading...</div>;
  } else {
    if (!Auth) {
      return <Login />
    }
    else {
      return (
        <div className="App">
          <BrowserRouter>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* Same as */}
            <ToastContainer />
            <Routes>
              <Route path='/*' element={
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">404 Page Not Found</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>
              <Route path='/' element={<Dashboard />}></Route>
              <Route path='/member' element={<Member />}></Route>
              <Route path='/order' element={<Order />}></Route>
              <Route path='/loginlogs' element={<Loginslog />}></Route>
              <Route path='/agent' element={<Agent />}></Route>

              {pos === 'sub_account' ? <Route path='/subaccount' element=
              {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }
              >
              </Route> : <Route path='/subaccount' element={<Subaccount/>}></Route>}


              {permission.edit_lotto_status === 1 ?  <Route path='/payrate' element={<Payrate/>}></Route>: <Route path='/payrate' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.limit_lotto === 1 ?  <Route path='/limitnumber' element={<Limitnumber />}></Route>: <Route path='/limitnumber' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_maxfight === 1 ?  <Route path='/fightlimitmax' element={<Fightlimitmax />}></Route>: <Route path='/fightlimitmax' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.winlose_view === 1 ?  <Route path='/winlose' element={<Winlose />}></Route>: <Route path='/winlose' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.result_view === 1 ? <Route path='/lottoresult' element={<Lottoresult />}></Route> : <Route path='/lottoresult' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_agent === 1 ? <Route path='/agentedit' element={<AgentEdit />}></Route>: <Route path='/agentedit' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.add_agent === 1 ? <Route path='/agentadd' element={<AgentAdd />}></Route>: <Route path='/agentadd' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.waitresult_view === 1 ? <Route path='/lottowaitresult' element={<Waitresult />}></Route>: <Route path='/lottowaitresult' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_agent === 1 ? <Route path='/commission' element={<Commission />}></Route>: <Route path='/commission' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_agent === 1 ? <Route path='/reward' element={<Reward />}></Route>: <Route path='/commission' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_agent === 1 ? <Route path='/maxbet' element={<Maxbet/>}></Route>: <Route path='/commission' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_agent === 1 ? <Route path='/minbet' element={<Minbet/>}></Route>: <Route path='/minbet' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
              {permission.edit_agent === 1 ? <Route path='/maxbetpermatch' element={<Maxbetpermatch/>}></Route>: <Route path='/maxbetpermatch' element=      {
                <div class="text-center">
                  <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-6">No Permission</h1>
                  <Button href='/' class="px-4 py-2 text-sm md:text-base bg-purple-500 text-white rounded hover:bg-purple-600">
                    Go Home
                  </Button>
                </div>
              }></Route>}
  
            </Routes>
          </BrowserRouter>
        </div>
      );
    }
  }
  
}

export default App;
