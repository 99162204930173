import React, { useEffect, useState } from "react";
import Sidebar from '../Sidebar/Sidebar'
import './Member.css'
import Navbar from './Navbar'
import Header from './header'
import MyTable from './Table'
import moment from "moment";

function Loginslog() {

    const user = localStorage.getItem('user')
    const pos = localStorage.getItem('position')
    const subuser = localStorage.getItem('subuser')
    const [json, setJson] = useState();
    const [login, setLogin] = useState([]);

    const MemberGet = async (user) => {
      const json = {
        user:user
      }
        await fetch(`${process.env.REACT_APP_API_KEY}/getlogin_log`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(json)
        })
          .then(res => res.json())
          .then((result) => { setLogin(result) })
      }
      useEffect(() => {
        if(pos === 'sub_account')
          {
            MemberGet(subuser)
          } else
          {
            MemberGet(user)
          }
        
      }, [])
      
    const memberColumns = [
        {
          Header: 'Username',
          accessor: 'username',
          align: 'center',
          Cell: ({ value }) => <span className="font-bold">{value}</span>,
          widthClass: 'w-1/6', // Adjust the width class as needed
        },
        {
          Header: 'วันที่',
          accessor: 'login_date',
          align: 'right',
          Cell: ({ value }) => <span className="text-xs font-bold">{moment(value).format('วันที่ DD MMMM YYYY เวลา HH:MM')}</span>,
          widthClass: 'w-1/6',
        },
        {
          Header: 'IP Address',
          accessor: 'ip',
          align: 'right',
          Cell: ({ value }) => <span className="text-xs">{value}</span>,
          widthClass: 'w-1/6',
        },
        {
          Header: 'Device',
          accessor: 'device',
          align: 'right',
          Cell: ({ value }) => <span className="text-xs font-bold">{value}</span>,
          widthClass: 'w-1/6',
        },
      ];

    return (
        <div>
        <Navbar />
         <div className="contain w-[800px] md:w-full md:flex">
             <Sidebar pos={pos}/>

                          <div className='content'>
                          <Header title='Login Logs' />
                        <div className='p-1'>
                            <MyTable columns={memberColumns} data={login}  />
                        </div>
                    {/* </div> */}
                </div>
                
            </div>
        </div>
    )
}

export default Loginslog