import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from './Navbar';
import Header from './header';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { toast } from 'react-toastify';

function Fightlimit() {

  const [thfightlimit, setthFightlimit] = useState();
  const [hnfightlimit, sethnFightlimit] = useState();
  const [lafightlimit, setlaFightlimit] = useState();
  const [yk5fightlimit, setyk5Fightlimit] = useState();
  const [yk10fightlimit, setyk10Fightlimit] = useState();
  const [yk15fightlimit, setyk15Fightlimit] = useState();
  const [thupdate, setThupdate] = useState({ first: 0, four_top: 0, four_tod: 0, three_top: 0, three_front: 0, three_bot: 0, three_tod: 0, two_top: 0, two_bot: 0, run_top: 0, run_bot: 0 });
  const [hnupdate, setHnupdate] = useState({ first: 0, four_top: 0, four_tod: 0, three_top: 0, three_front: 0, three_bot: 0, three_tod: 0, two_top: 0, two_bot: 0, run_top: 0, run_bot: 0 });
  const [laupdate, setLaupdate] = useState({ first: 0, four_top: 0, four_tod: 0, three_top: 0, three_front: 0, three_bot: 0, three_tod: 0, two_top: 0, two_bot: 0, run_top: 0, run_bot: 0 });
  const [yk5update, setYk5update] = useState({ first: 0, four_top: 0, four_tod: 0, three_top: 0, three_front: 0, three_bot: 0, three_tod: 0, two_top: 0, two_bot: 0, run_top: 0, run_bot: 0 });
  const [yk10update, setYk10update] = useState({ first: 0, four_top: 0, four_tod: 0, three_top: 0, three_front: 0, three_bot: 0, three_tod: 0, two_top: 0, two_bot: 0, run_top: 0, run_bot: 0 });
  const [yk15update, setYk15update] = useState({ first: 0, four_top: 0, four_tod: 0, three_top: 0, three_front: 0, three_bot: 0, three_tod: 0, two_top: 0, two_bot: 0, run_top: 0, run_bot: 0 });

  const updateData = (propertyName, newValue, set, data, olddata) => {
    if (!newValue) {
      set({ ...data, [propertyName]: 0 });
    } else {
      set({ ...data, [propertyName]: Number(newValue) });
    }
  };

  const user = localStorage.getItem('user');

  const filter = (type, array) => {
    const fillter = array.filter(obj => {
      return obj.lotto_type === type;
    })
    return fillter[0]
  }
  const positionget = {
    agent: user,
  };

  const fightlimitget = async () => {
    await fetch(`${process.env.REACT_APP_API_KEY}/getfightlimit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(positionget),
    })
      .then((res) => res.json())
      .then((result) => {
        setthFightlimit(filter('th_gov', result));
        setlaFightlimit(filter('laos_gov', result));
        sethnFightlimit(filter('hanoy_PhuQua', result));
        setyk5Fightlimit(filter('yeekee_5', result));
        setyk10Fightlimit(filter('yeekee_10', result));
        setyk15Fightlimit(filter('yeekee_15', result));

        setThupdate(filter('th_gov', result));
        setLaupdate(filter('laos_gov', result));
        setHnupdate(filter('hanoy_PhuQua', result));
        setYk5update(filter('yeekee_5', result));
        setYk10update(filter('yeekee_10', result));
        setYk15update(filter('yeekee_15', result));

      })
  };

  useEffect(() => {
    fightlimitget();
  }, []);

  const handlesave = (type, fightlimit) => {
    const json = {
      agent: user,
      type: type,
      newfightlimit: fightlimit
    };
    fetch(`${process.env.REACT_APP_API_KEY}/setfightlimit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.ok) {
          toast.success('Fight limit successfully updated.');
        } else {
          toast.error('Failed to update fight limit.');
        }
      })
      .catch(error => {
        toast.error('An error occurred while updating fight limit.');
      });
  
    fightlimitget();
  };

  const handlechange = (event, index) => {
  };
  const pos = localStorage.getItem('position')

  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos}/>
        <div className="inner">
          <div className="content">
            <Header title="Fight Limit (Max)" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Thai box */}
              <div className="w-full bg-blue-400 p-2 rounded-sm">
                <div>
                  <span className="text-white">หวยไทย</span>
                </div>

                <div className="transition-all duration-500">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-200">ชนิดเกมส์</th>
                        <th className="bg-blue-200">จำนวน</th>
                        <th className="bg-blue-200">รับสู้สูงสุด</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">

                      <tr>
                        <td className="p-1">รางวัลที่ 1</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.first}
                            onChange={(e) => updateData('first', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.first : ''}</td>
                      </tr>

                      <tr>
                        <td className="p-1">4 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.four_top}
                            onChange={(e) => updateData('four_top', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.four_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">4 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.four_tod}
                            onChange={(e) => updateData('four_tod', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.four_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.three_top}
                            onChange={(e) => updateData('three_top', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.three_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวหน้า</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.three_front}
                            onChange={(e) => updateData('three_front', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.three_front : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวหลัง</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.three_bot}
                            onChange={(e) => updateData('three_bot', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.three_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.three_tod}
                            onChange={(e) => updateData('three_tod', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.three_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.two_top}
                            onChange={(e) => updateData('two_top', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.two_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.two_bot}
                            onChange={(e) => updateData('two_bot', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.two_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.run_top}
                            onChange={(e) => updateData('run_top', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.run_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={thupdate.run_bot}
                            onChange={(e) => updateData('run_bot', e.target.value, setThupdate, thupdate, thfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{thfightlimit ? thfightlimit.run_bot : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-1 p-1 mb-1 bg-purple-800 h-[50%] w-full text-center text-white rounded border-solid hover:bg-sky-800 '>
                    <button className='w-full' onClick={() => handlesave('th_gov', thupdate)}>
                      <SaveAsIcon className='ml-1 text-[8px] md:text-[1px]' />
                      <span className='ml-1 w-full text-[8px] md:text-[10px]'>บันทึกเข้าสู่ระบบ</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Lao box */}
              <div className="w-full h-[75vh] bg-blue-700 p-2 rounded-sm">
                <div>
                  <span className="text-white">หวยลาว</span>
                </div>

                <div className="transition-all duration-500">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-200">ชนิดเกมส์</th>
                        <th className="bg-blue-200">จำนวน</th>
                        <th className="bg-blue-200">รับสู้สูงสุด</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="p-1">3 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={laupdate.three_top}
                            onChange={(e) => updateData('three_top', e.target.value, setLaupdate, laupdate, lafightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{lafightlimit ? lafightlimit.three_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={laupdate.three_tod}
                            onChange={(e) => updateData('three_tod', e.target.value, setLaupdate, laupdate, lafightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{lafightlimit ? lafightlimit.three_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={laupdate.two_top}
                            onChange={(e) => updateData('two_top', e.target.value, setLaupdate, laupdate, lafightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{lafightlimit ? lafightlimit.two_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={laupdate.two_bot}
                            onChange={(e) => updateData('two_bot', e.target.value, setLaupdate, laupdate, lafightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{lafightlimit ? lafightlimit.two_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={laupdate.run_top}
                            onChange={(e) => updateData('run_top', e.target.value, setLaupdate, laupdate, lafightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{lafightlimit ? lafightlimit.run_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={laupdate.run_bot}
                            onChange={(e) => updateData('run_bot', e.target.value, setLaupdate, laupdate, lafightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{lafightlimit ? lafightlimit.run_bot : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-1 p-1 mb-1 bg-purple-800 h-[50%] w-full text-center text-white rounded border-solid hover:bg-sky-800 '>
                    <button className='w-full' onClick={() => handlesave('laos_gov', laupdate)}>
                      <SaveAsIcon className='ml-1 text-[8px] md:text-[1px]' />
                      <span className='ml-1 text-[8px] md:text-[10px]'>บันทึกเข้าสู่ระบบ</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Hanoi box */}
              <div className="w-full h-[73vh] bg-red-400 p-2 rounded-sm">
                <div>
                  <span className="text-white">หวยฮานอย</span>
                </div>

                <div className="transition-all duration-500">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-200">ชนิดเกมส์</th>
                        <th className="bg-blue-200">จำนวน</th>
                        <th className="bg-blue-200">รับสู้สูงสุด</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="p-1">3 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={hnupdate.three_top}
                            onChange={(e) => updateData('three_top', e.target.value, setHnupdate, hnupdate, hnfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{hnfightlimit ? hnfightlimit.three_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={hnupdate.three_tod}
                            onChange={(e) => updateData('three_tod', e.target.value, setHnupdate, hnupdate, hnfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{hnfightlimit ? hnfightlimit.three_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={hnupdate.two_top}
                            onChange={(e) => updateData('two_top', e.target.value, setHnupdate, hnupdate, hnfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{hnfightlimit ? hnfightlimit.two_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={hnupdate.two_bot}
                            onChange={(e) => updateData('two_bot', e.target.value, setHnupdate, hnupdate, hnfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{hnfightlimit ? hnfightlimit.two_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={hnupdate.run_top}
                            onChange={(e) => updateData('run_top', e.target.value, setHnupdate, hnupdate, hnfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{hnfightlimit ? hnfightlimit.run_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={hnupdate.run_bot}
                            onChange={(e) => updateData('run_bot', e.target.value, setHnupdate, hnupdate, hnfightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{hnfightlimit ? hnfightlimit.run_bot : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-1 p-1 mb-1 bg-purple-800 h-[50%] w-full text-center text-white rounded border-solid hover:bg-sky-800 '>
                    <button className='w-full' onClick={() => handlesave('hanoy_PhuQua', hnupdate)}>
                      <SaveAsIcon className='ml-1 text-[8px] md:text-[1px]' />
                      <span className='ml-1 text-[8px] md:text-[10px]'>บันทึกเข้าสู่ระบบ</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Yeekee box */}
              <div className="w-full bg-orange-300 p-2 rounded-sm">
                <div>
                  <span className="text-white">จับยี่กี</span>
                </div>

                <div className="transition-all duration-500">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-200">ชนิดเกมส์</th>
                        <th className="bg-blue-200">จำนวน</th>
                        <th className="bg-blue-200">รับสู้สูงสุด</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="p-1">3 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk5update.three_top}
                            onChange={(e) => updateData('three_top', e.target.value, setYk5update, yk5update, yk5fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk5fightlimit ? yk5fightlimit.three_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk5update.three_tod}
                            onChange={(e) => updateData('three_tod', e.target.value, setYk5update, yk5update, yk5fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk5fightlimit ? yk5fightlimit.three_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk5update.two_top}
                            onChange={(e) => updateData('two_top', e.target.value, setYk5update, yk5update, yk5fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk5fightlimit ? yk5fightlimit.two_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk5update.two_bot}
                            onChange={(e) => updateData('two_bot', e.target.value, setYk5update, yk5update, yk5fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk5fightlimit ? yk5fightlimit.two_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk5update.run_top}
                            onChange={(e) => updateData('run_top', e.target.value, setYk5update, yk5update, yk5fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk5fightlimit ? yk5fightlimit.run_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk5update.run_bot}
                            onChange={(e) => updateData('run_bot', e.target.value, setYk5update, yk5update, yk5fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk5fightlimit ? yk5fightlimit.run_bot : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-1 p-1 mb-1 bg-purple-800 h-[50%] w-full text-center text-white rounded border-solid hover:bg-sky-800 '>
                    <button className='w-full' onClick={() => handlesave('yeekee_5', yk5update)}>
                      <SaveAsIcon className='ml-1 text-[8px] md:text-[1px]' />
                      <span className='ml-1 text-[8px] md:text-[10px]'>บันทึกเข้าสู่ระบบ</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Yeekee VIP box */}
              <div className="w-full bg-orange-700 p-2 rounded-sm">
                <div>
                  <span className="text-white">จับยี่กีVIP</span>
                </div>

                <div className="transition-all duration-500">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-200">ชนิดเกมส์</th>
                        <th className="bg-blue-200">จำนวน</th>
                        <th className="bg-blue-200">รับสู้สูงสุด</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="p-1">3 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk10update.three_top}
                            onChange={(e) => updateData('three_top', e.target.value, setYk10update, yk10update, yk10fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk10fightlimit ? yk10fightlimit.three_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk10update.three_tod}
                            onChange={(e) => updateData('three_tod', e.target.value, setYk10update, yk10update, yk10fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk10fightlimit ? yk10fightlimit.three_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk10update.two_top}
                            onChange={(e) => updateData('two_top', e.target.value, setYk10update, yk10update, yk10fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk10fightlimit ? yk10fightlimit.two_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk10update.two_bot}
                            onChange={(e) => updateData('two_bot', e.target.value, setYk10update, yk10update, yk10fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk10fightlimit ? yk10fightlimit.two_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk10update.run_top}
                            onChange={(e) => updateData('run_top', e.target.value, setYk10update, yk10update, yk10fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk10fightlimit ? yk10fightlimit.run_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk10update.run_bot}
                            onChange={(e) => updateData('run_bot', e.target.value, setYk10update, yk10update, yk10fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk10fightlimit ? yk10fightlimit.run_bot : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-1 p-1 mb-1 bg-purple-800 h-[50%] w-full text-center text-white rounded border-solid hover:bg-sky-800 '>
                    <button className='w-full' onClick={() => handlesave('yeekee_10', yk10update)}>
                      <SaveAsIcon className='ml-1 text-[8px] md:text-[1px]' />
                      <span className='ml-1 text-[8px] md:text-[10px]'>บันทึกเข้าสู่ระบบ</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Yeekee VVIP box */}
              <div className="w-full bg-orange-500 p-2 rounded-sm">
                <div>
                  <span className="text-white">จับยี่กีVVIP</span>
                </div>

                <div className="transition-all duration-500">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-200">ชนิดเกมส์</th>
                        <th className="bg-blue-200">จำนวน</th>
                        <th className="bg-blue-200">รับสู้สูงสุด</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="p-1">3 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk15update.three_top}
                            onChange={(e) => updateData('three_top', e.target.value, setYk15update, yk15update, yk15fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk15fightlimit ? yk15fightlimit.three_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">3 ตัวโต๊ด</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk15update.three_tod}
                            onChange={(e) => updateData('three_tod', e.target.value, setYk15update, yk15update, yk15fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk15fightlimit ? yk15fightlimit.three_tod : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk15update.two_top}
                            onChange={(e) => updateData('two_top', e.target.value, setYk15update, yk15update, yk15fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk15fightlimit ? yk15fightlimit.two_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">2 ตัวล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk15update.two_bot}
                            onChange={(e) => updateData('two_bot', e.target.value, setYk15update, yk15update, yk15fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk15fightlimit ? yk15fightlimit.two_bot : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งบน</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk15update.run_top}
                            onChange={(e) => updateData('run_top', e.target.value, setYk15update, yk15update, yk15fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk15fightlimit ? yk15fightlimit.run_top : ''}</td>
                      </tr>
                      <tr>
                        <td className="p-1">วิ่งล่าง</td>
                        <td className="px-2 py-2">
                          <input
                            value={yk15update.run_bot}
                            onChange={(e) => updateData('run_bot', e.target.value, setYk15update, yk15update, yk15fightlimit)}
                            min={0}
                            type="number"
                            onClick={(e) => e.target.select()}
                            className='w-[100%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                          />
                        </td>
                        <td className="p-1">{yk15fightlimit ? yk15fightlimit.run_bot : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-1 p-1 mb-1 bg-purple-800 h-[50%] w-full text-center text-white rounded border-solid hover:bg-sky-800 '>
                    <button className='w-full' onClick={() => handlesave('yeekee_15', yk15update)}>
                      <SaveAsIcon className='ml-1 text-[8px] md:text-[1px]' />
                      <span className='ml-1 text-[8px] md:text-[10px]'>บันทึกเข้าสู่ระบบ</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fightlimit;
