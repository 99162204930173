import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Header from './header'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import MyTable from './Table';


function Waitresult() {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [order, setOrder] = useState([]);
  const [showorder, setShowrder] = useState([]);
  const user = localStorage.getItem('user')

  const [startdate, setStartdate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endtdate, setEndtdate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  const OrderGet = () => {
    const json = {
      user: user
    }
    fetch(`${process.env.REACT_APP_API_KEY}/getwait_result_bill`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    })
      .then(res => res.json())
      .then(
        (result) => {
          setOrder(result)
          setShowrder(result)
          //alert(startdate)
        }
      )
  }

  useEffect(() => {
    OrderGet()
  }, [])

  const pos = localStorage.getItem('position')

  return (
    <div>
      <Navbar />
      <div className="contain w-[800px] md:w-full md:flex">
        <Sidebar pos={pos}/>
        <div className="inner">
          <div className="content">
            <Header title='รอผล' />

            <TableContainer component={Paper}>
              <TableContainer component={Paper}>
                <MyTable
                  startdate={startdate}
                  setstartdate={setStartdate}

                  enddate={endtdate}
                  setenddate={setEndtdate}

                  fetch={OrderGet}

                  columns={[
                    {
                      Header: 'User', accessor: 'Agent', align: 'right', widthClass: 'w-1/6',
                      Cell: ({ row,value }) => 
                      <div className='bg-amber-600 w-[100%] text-center text-white rounded border-solid hover:bg-amber-800 '>
                      <span className="font-bold">{value}</span>
                      </div>,
                    },
                    {
                      Header: 'User', accessor: 'Customer', align: 'right',widthClass: 'w-1/12',
                      Cell: ({ row,value }) => 
                      <span className="font-bold">{value}</span>
                    },
                    {
                      Header: 'วันที่ซื้อ', accessor: 'buydate', align: 'right',
                      widthClass: 'w-1/12',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{moment(value).format('DD MMMM YYYY HH:MM')}</span>,
                    },
                    {
                      Header: 'ประเภทหวย', accessor: 'lotto_type', align: 'right',
                      widthClass: 'w-1/12',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">
                        {value === 'th_gov' && 'หวยรัฐบาลไทย'}
                        {value === 'laos_gov' && 'หวยรัฐบาลลาว'}
                        {value === 'hanoy_PhuQua' && 'หวยฮานอยฟูกั่ว'}
                        {value === 'Yeekee_5' && 'หวยยี่กี่'}
                        {value === 'Yeekee_10' && 'หวยยี่กี่ VIP'}
                        {value === 'Yeekee_15' && 'หวยยี่กี่ VVIP'}

                      </span>,
                    },
                    {
                      Header: 'ประเภทเลข', accessor: 'bet_type', align: 'right',
                      widthClass: 'w-1/12',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">
                        {value === 'first' && 'รางวัลที่หนึ่ง'}
                        {value === 'four_top' && 'สี่ตัวตรง'}
                        {value === 'four_tod' && 'สี่ตัวโต๊ด'}
                        {value === 'three_top' && 'สามตัวบน'}
                        {value === 'three_front' && 'สามตัวหน้า'}
                        {value === 'three_bot' && 'สามตัวหลัง'}
                        {value === 'three_tod' && 'สามตัวโต๊ด'}
                        {value === 'two_top' && 'สองตัวบน'}
                        {value === 'two_bot' && 'สองตัวล่าง'}
                        {value === 'run_top' && 'วิ่งบน'}
                        {value === 'run_bot' && 'วิ่งล่าง'}
                      </span>,
                    },
                    {
                      Header: 'เลข', accessor: 'number', align: 'right',
                      widthClass: 'w-1/12',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{value}</span>,
                    },

                    {
                      Header: 'ยอดเดิมพัน', accessor: 'bet_amout', align: 'right',
                      widthClass: 'w-1/12',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },
                    {
                      Header: 'คอมมิชชั่น', accessor: 'commission', align: 'right',
                      widthClass: 'w-1/12',
                      Cell: ({ value }) => <span className="font-bold text-slate-500">{parseFloat(value).toFixed(2) !== 'NaN' ? parseFloat(value).toFixed(2) : ''}</span>,
                    },


                  ]}
                  data={showorder}
                  components={{
                    TableCell: StyledTableCell,
                    TableRow: StyledTableRow,
                  }}
                />
              </TableContainer>
            </TableContainer>


          </div>
        </div>

      </div>
    </div>
  )
}

export default Waitresult
