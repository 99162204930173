import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from './Navbar'
import moment from 'moment'
import './Lottoresult.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from './header'


function Lottoresult() {


  const [result, setResult] = useState([]);
  const [lottotype, setLottotype] = useState('th_gov');
  const [startDate, setStartDate] = useState(new Date());
  const [round, setRound] = useState(1);


  const ResGetbydate = () => {
    const json = {
      date:moment(startDate).format('YYYY-MM-DD'),
      lottotype:lottotype,
      round:round
    }
    fetch(`${process.env.REACT_APP_API_KEY}/getresultbydate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setResult(result)
        }
      )
  }

  useEffect(() => {
    ResGetbydate()
  }, [startDate,lottotype,round])



  const ResGet = () => {
    const json = {
      lottotype:lottotype
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setResult(result)
        }
      )
  }
  useEffect(() => {
    ResGet()
    // console.log(result)
  }, [])

  const first = result.filter(item => item.type === 'first');
  const threetop = result.filter(item => item.type === 'three_top');
  const threefront = result.filter(item => item.type === 'three_front');
  const threetod = result.filter(item => item.type === 'three_tod');
  const threebot = result.filter(item => item.type === 'three_bot');
  const twobot = result.filter(item => item.type === 'two_bot');
  const twotop = result.filter(item => item.type === 'two_top');  
  const fourtop = result.filter(item => item.type === 'four_top');
  const fourtod = result.filter(item => item.type === 'four_tod');


  const createOptions = (listnum) => {
    const options = [];
    for (let i = 1; i <= listnum; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  }

  const Yeekee5 = createOptions(288)
  const Yeekee10 = createOptions(144)
  const Yeekee15 = createOptions(96)
  const jackpot = createOptions(6)
  const pos = localStorage.getItem('position')

  return (
    <div>
    <Navbar />
     <div className="contain w-[800px] md:w-full md:flex">
         <Sidebar pos={pos}/>
        <div className="inner">
            <div className="content flex justify-center">
            <Header title='Lotto Result' />
            <div className='md:flex flex-col p-2 w-[100%] h-[500%] bg-purple-200 rounded'>
            <div className='flex flex-row w-[100%]'>
            <h3>เลือกประเภทหวย :</h3>  
            <select className='ml-2' value={lottotype} onInput={(e) => setLottotype(e.target.value)}>
                        <option value="th_gov">รัฐบาลไทย</option>
                        <option value="laos_gov">หวยลาว</option>
                        <option value="hanoy_PhuQua">หวยฮานอย</option>
                        <option value="Yeekee_5">ยี่กี่ 5 นาที</option>
                        <option value="Yeekee_10">ยี่กี่ 10 นาที</option>
                        <option value="Yeekee_15">ยี่กี่ 15 นาที</option>
                      </select>
                       
            <span className='text-black ml-2'>วันที่ผลออก :</span>
            <DatePicker className='ml-3' selected={startDate} onChange={(date) => setStartDate(date)} />
                      <h3 className='ml-4'>เลือกรอบ :</h3>
                <select className='ml-2' value={round} onInput={(e) => setRound(e.target.value)}>
                  {lottotype === "Yeekee_5" && Yeekee5}
                  {lottotype === "Yeekee_10" && Yeekee10}
                  {lottotype === "Yeekee_15" && Yeekee15}
                  {lottotype === "hanoy_PhuQua" && jackpot}
                </select>
            </div>
            <div className='w-[1080px] md:w-full mt-2 h-1 bg-amber-400 border-solid'></div>
            </div>
            <table className='w-1/2 border-collapse border border-gray-300'>
            <tbody>
              <tr>
                <td colspan="2" class="border purple-gray-300 bg-purple-200 px-4 py-2 text-center">ผลรางวัล</td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>รางวัลที่หนึ่ง</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {first.map((row, index) => (
                <a key={index} style={{ paddingLeft: '1rem', paddingRight: '1rem', flex: '1 0 16.6667%' }}>{row.resultnumber}</a>
            ))}
                </td>
              </tr>

              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>4 ตัวบน</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {fourtop.map((row, index) => (
                <a key={index} style={{ paddingLeft: '1rem', paddingRight: '1rem', flex: '1 0 16.6667%' }}>{row.resultnumber}</a>
            ))}
                </td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center w-40'>4 ตัวโต๊ด</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                  <div class='flex flex-wrap justify-center'>
                    {fourtod.map((row, index) => (
                      <a key={index} style={{ paddingLeft: '1rem', paddingRight: '1rem', flex: '1 0 20%' }}>{row.resultnumber}</a>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>3 ตัวบน</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {threetop.map((row) => (
                <a style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                ))}
                </td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>3 ตัวหน้า</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {threefront.map((row) => (
                <a style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                ))}
                </td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>3 ตัวหลัง</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {threebot.map((row) => (
                <a style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                ))}
                </td>
              </tr>

              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>3 ตัวโต๊ด</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {threetod.map((row) => (
                <a style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                ))}
                </td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>2 ตัวบน</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {twotop.map((row) => (
                <h1>{row.resultnumber}</h1>
                ))}                  
                </td>
              </tr>
              <tr>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center '>2 ล่าง</td>
                <td className='border border-gray-300 bg-gray-200 px-4 py-2 text-center text-purple-600'>
                {twobot.map((row) => (
                <h1>{row.resultnumber}</h1>
                ))}                  
                </td>
              </tr>
            </tbody>
          </table>


          </div>
        </div>

      </div>
    </div>
  )
}

export default Lottoresult